import React from "react"
import Alert from "../Alert"

export type ErrorContainerProps = {
  id?: string
  title?: string
  message: React.ReactNode
  errorClass?: string
  open: boolean
  handleClose?: () => void
}

const ErrorContainer: React.FC<ErrorContainerProps> = ({ id, title, message, errorClass, open, handleClose }) => {
  if (open) {
    return (
      <div id={id} className={errorClass}>
        <Alert variant="danger" content={message} title={title} {...(handleClose ? { handleClose } : {})} />
      </div>
    )
  } else {
    return null
  }
}

export default React.memo(ErrorContainer)
