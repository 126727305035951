import { compare, CompareOperator } from "compare-versions"
import platform from "platform"
import { DevicePostureOsAndBrowserType, DevicePostureType } from "src/services/api/swrHooks/useDevicePostures"

const defaultPostureCheck = {
  anyVersion: false,
  id: "",
  isEnabled: false,
  name: "",
  operator: "",
  version: "",
}

export type CheckDevicePostureForBrowser = {
  devicePostureNotMatchingBrowser: DevicePostureOsAndBrowserType[]
  supportedVersions?: string
  detectedBrowser?: string
  alternateBrowser?: string[]
}

/**
 * Checks the device posture for the current browser against the provided service device posture.
 *
 * @param {DevicePostureType} serviceDevicePosture - The device posture configuration to check against.
 * @returns {CheckDevicePostureForBrowser} An object containing the results of the posture check.
 *
 * @property {Array} devicePostureNotMatchingBrowser - An array of posture checks that did not match the current browser.
 * @property {string} [detectedBrowser] - The name of the detected browser if it does not match the required posture.
 * @property {Array<string>} [alternateBrowser] - An array of alternate browsers that meet the posture requirements.
 * @property {string} [supportedVersions] - The supported versions of the detected browser if it does not match the required posture.
 */
export const checkDevicePostureForBrowser = (serviceDevicePosture: DevicePostureType): CheckDevicePostureForBrowser => {
  const browserInfo = { browser: platform.name, browserVersion: platform.version }
  const { browserCheck, isBrowserEnabled } = serviceDevicePosture

  if (!isBrowserEnabled)
    return {
      devicePostureNotMatchingBrowser: [],
    }

  const browserMatchingCurrentBrowser = browserCheck?.find((browser) => {
    return browserInfo?.browser?.includes(browser?.name)
  })

  const alternateBrowser = browserCheck?.filter((browser) => {
    return !browserInfo?.browser?.includes(browser?.name) && browser?.isEnabled
  })

  if (browserMatchingCurrentBrowser && !browserMatchingCurrentBrowser?.isEnabled && alternateBrowser?.length)
    return {
      devicePostureNotMatchingBrowser: [defaultPostureCheck],
      detectedBrowser: browserMatchingCurrentBrowser?.name,
      alternateBrowser: alternateBrowser?.map(
        (browser) =>
          ` ${browser?.name} ${browser?.version === "Any Version" ? "" : `${browser?.operator} ${browser?.version}`}`,
      ),
    }
  if (browserMatchingCurrentBrowser && !browserMatchingCurrentBrowser?.isEnabled)
    return {
      devicePostureNotMatchingBrowser: [defaultPostureCheck],
    }

  if (browserMatchingCurrentBrowser) {
    const operatorValue =
      browserMatchingCurrentBrowser?.operator === "==" ? "=" : browserMatchingCurrentBrowser?.operator
    const browserVersionArrayfied = browserInfo.browserVersion?.split(".")

    if (!browserMatchingCurrentBrowser?.anyVersion) {
      if (
        !compare(
          browserVersionArrayfied?.length <= 1
            ? browserInfo?.browserVersion || ""
            : browserVersionArrayfied?.slice(0, 1)?.join("."),
          browserMatchingCurrentBrowser?.version?.split(".")?.slice(0, 1)?.join(".") || "",
          operatorValue as CompareOperator,
        )
      ) {
        return {
          devicePostureNotMatchingBrowser: [browserMatchingCurrentBrowser],
          detectedBrowser: browserMatchingCurrentBrowser?.name,
          supportedVersions: `${
            browserMatchingCurrentBrowser?.operator === "==" ? "" : browserMatchingCurrentBrowser?.operator
          } ${browserMatchingCurrentBrowser?.version}`,
          alternateBrowser: alternateBrowser?.map(
            (browser) =>
              ` ${browser?.name} ${
                browser?.version === "Any Version" ? "" : `${browser?.operator} ${browser?.version}`
              }`,
          ),
        }
      }
    }
    return {
      devicePostureNotMatchingBrowser: [],
    }
  }
  return {
    devicePostureNotMatchingBrowser: [defaultPostureCheck],
    detectedBrowser: alternateBrowser?.length && browserInfo?.browser,
    alternateBrowser: alternateBrowser?.map(
      (browser) =>
        ` ${browser?.name} ${browser?.version === "Any Version" ? "" : `${browser?.operator} ${browser?.version}`}`,
    ),
  }
}
