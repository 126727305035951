import { POLICY_EVALUATION } from "src/constants"
import { putRequest } from "src/services"
import { ApplicationPolicyEvaluationFormType } from "./ApplicationPolicyEvaluationForm"

/**
 * Evaluates an application policy based on the provided data.
 *
 * @param {ApplicationPolicyEvaluationFormType} data - The data required for policy evaluation.
 * @param {Object} data.user - The user information.
 * @param {string} data.user.id - The ID of the user (required).
 * @param {string} data.user.email - The email of the user (required).
 * @param {string} data.accessMode - The access mode (required).
 * @param {string} data.application - The application service (required).
 * @param {string} [data.device] - The device information (optional).
 * @param {string} [data.timeZone] - The time zone (optional). Only send this field if user has input on this.
 * @param {Array<{ value: string }>} [data.location] - The location-based conditions (optional). Only send this field if user has input on this.
 * @param {Date} [data.startTime] - The start time (optional). Only send this field if user has input on this. Time format should be in UTC.
 * @param {Date} [data.endTime] - The end time (optional). Only send this field if user has input on this. Time format should be in UTC.
 * @returns {Promise<any>} The response from the policy evaluation request.
 */
export const applicationPolicyEvaluation = async (data: ApplicationPolicyEvaluationFormType) => {
  const url = POLICY_EVALUATION
  const body = {
    user_id: data?.user?.id, // required
    user_email: data?.user?.email, // required
    access_mode: data?.accessMode, // required
    service: data?.application, // required
    device: data?.device || undefined,
    time_zone: data?.timeZone || undefined, // Optional. Only send this field if user has input on this otherwise don't send in request body.
    location_based_condition:
      data?.location?.filter((val) => val?.value !== "any" && val.value)?.map((val) => val.value) || [], // Optional. Only send this field if user has input on this otherwise don't send in request body.
    start_time: data?.startTime ? data?.startTime?.toISOString() : undefined, // Optional. Only send this field if user has input on this otherwise don't send in request body. Timeformat should be in UTC
    end_time: data?.endTime ? data?.endTime?.toISOString() : undefined, // Optional. Only send this field if user has input on this otherwise don't send in request body. Timeformat should be in UTC
  }
  const resp = await putRequest(url, body)

  return resp
}
