import { Paper } from "@material-ui/core"
import { ICellRendererParams } from "ag-grid-community"
import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENMenu } from "en-react/dist/src/components/Menu"
import { ENMenuItem } from "en-react/dist/src/components/MenuItem"
import React, { useEffect, useRef, useState } from "react"
import { PopoverButtonType } from "src/components/DataGrid/DataGrid"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { useDataGridPopOverStyles } from "./DataGridPopOver.styles"

type PopOverListType<RowDataType extends any> = Omit<ICellRendererParams<RowDataType>, "setTooltip"> & {
  popOverList: PopoverButtonType[]
  isExpandedView?: boolean
  isDisabledPopOverList?: (data?: RowDataType) => boolean
  disabledPopOverTooltip?: (data?: RowDataType) => boolean
  isDataGridPopoverOpen?: (data?: RowDataType, isOpen?: boolean) => void
}

const DataGridPopover = ({
  popOverList,
  data,
  api,
  isExpandedView,
  node,
  isDisabledPopOverList,
  disabledPopOverTooltip,
  isDataGridPopoverOpen,
}: PopOverListType<any>) => {
  const popoverRef = useRef<HTMLInputElement>(null)
  const [popoverMenuPosition, setPopoverMenuPosition] = useState({ top: 500, left: 900 })

  const classes = useDataGridPopOverStyles({ top: popoverMenuPosition.top, left: popoverMenuPosition.left })

  const hasNoItems = popOverList?.every(
    (item) => (item.hide && !!item.hide(data)) || (item?.show && !item?.show(data)) || item.variant === "separator",
  )
  const calculatePopoverMenuPosition = () => {
    const rect = popoverRef?.current?.getBoundingClientRect()
    const top = rect?.top ? rect.top + window.scrollY : 0
    const left = rect?.left ? rect.left - 180 : 0
    setPopoverMenuPosition({ top, left })
  }
  useEffect(() => {
    if (isExpandedView) {
      calculatePopoverMenuPosition()
      if (popoverRef.current) {
        popoverRef.current.addEventListener("click", calculatePopoverMenuPosition)
      }
      window.addEventListener("resize", calculatePopoverMenuPosition)
      return () => {
        if (popoverRef.current) {
          popoverRef.current.removeEventListener("click", calculatePopoverMenuPosition)
        }
        window.removeEventListener("resize", calculatePopoverMenuPosition)
      }
    }
  }, [])

  const isDisabledPopover = isDisabledPopOverList?.(data) || false
  const disableTooltipMessage = disabledPopOverTooltip?.(data) || ""

  const PopOverChildComponent = (
    <React.Fragment key=".0">
      {popOverList?.map(
        (
          {
            name,
            title,
            callback,
            disabled,
            variant = "normal",
            show,
            tooltipText,
            hide,
            disabledTooltipText,
            disabledTooltipTextPlacement,
          },
          index,
        ) => {
          const disabledTooltipTitle = disabled?.(data)
            ? typeof disabledTooltipText === "function"
              ? disabledTooltipText(data)
              : disabledTooltipText
            : tooltipText || ""

          if (variant === "separator") {
            return <ENDivider key={`${index}`} />
          }

          return (
            <React.Fragment key={`${index}`}>
              {disabledTooltipTitle !== "" ? (
                <ZtnaTooltip
                  title={disabledTooltipTitle}
                  arrow
                  key={`PopoverTooltip-${name}`}
                  placement={disabledTooltipTextPlacement || "bottom"}
                  cssPosition="absolute"
                  enableTextWrap
                >
                  <ENMenuItem
                    key={name}
                    isDisabled={(disabled && disabled(data)) || false}
                    onClick={() => {
                      callback?.(name, data)
                    }}
                    data-testId={`grid-action-${name}`.toLowerCase()}
                    style={(hide && !!hide(data)) || (show && !show(data)) ? { display: "none" } : {}}
                  >
                    <div className={classes.popOverMenuItem}>
                      <span>{typeof title === "function" ? title(data) : title}</span>
                    </div>
                  </ENMenuItem>
                </ZtnaTooltip>
              ) : (
                <ENMenuItem
                  key={name}
                  isDisabled={(disabled && disabled(data)) || false}
                  onClick={() => {
                    callback?.(name, data)
                  }}
                  style={(hide && !!hide(data)) || (show && !show(data)) ? { display: "none" } : {}}
                >
                  <div className={classes.popOverMenuItem}>
                    <span className={variant === "remove" ? classes.removeButton : ""}>
                      {typeof title === "function" ? title(data) : title}
                    </span>
                  </div>
                </ENMenuItem>
              )}
            </React.Fragment>
          )
        },
      )}
    </React.Fragment>
  )

  const allShowUndefined = popOverList?.every((item: PopoverButtonType) => item.show === undefined)
  const menuItemsToShow = allShowUndefined ? popOverList : popOverList?.filter((item: any) => item?.show?.(data))

  return (
    <div className={classes.root} data-testId="grid-action-menu">
      <ENMenu
        position="left"
        height={menuItemsToShow?.length >= 5 ? 250 : undefined} // 250 is the max height of the popover if the list is more than 5 items. This to add scroll to the popover
        enableCloseOnSelection
        enableKeepMenuItemSelection={false}
        onOpen={(e: Event) => {
          isDataGridPopoverOpen?.(data, true)
        }}
        onClose={(e: Event) => {
          isDataGridPopoverOpen?.(data, false)
        }}
        style={
          { "--en-menu-panel-overflow-x": "visible", "--en-menu-panel-overflow-y": "visible" } as React.CSSProperties
        }
      >
        <React.Fragment key=".0">
          <div className={classes.actionButton} slot="trigger" ref={popoverRef} data-testId="grid-actionButton">
            {hasNoItems || disableTooltipMessage ? (
              <ZtnaTooltip
                title={
                  hasNoItems
                    ? "No action can be taken with the current state of the resource"
                    : disableTooltipMessage || ""
                }
                cssPosition="absolute"
                placement="left"
              >
                <ENButton variant="tertiary" isDisabled={!!isDisabledPopover}>
                  <React.Fragment key=".0">
                    <ZtnaIcon name="actionsDataGrid" />
                  </React.Fragment>
                </ENButton>
              </ZtnaTooltip>
            ) : (
              <ENButton variant="tertiary" isDisabled={!!isDisabledPopover}>
                <React.Fragment key=".0">
                  <ZtnaIcon name="actionsDataGrid" />
                </React.Fragment>
              </ENButton>
            )}
          </div>

          {!hasNoItems ? (
            <div
              className={clsx({
                [classes.listContainerExpanded]: isExpandedView,
                [classes.listContainer]: !isExpandedView,
              })}
            >
              {isExpandedView ? (
                <Paper className={classes.paperContainer}>{PopOverChildComponent}</Paper>
              ) : (
                <>{PopOverChildComponent}</>
              )}
            </div>
          ) : null}
        </React.Fragment>
      </ENMenu>
    </div>
  )
}

export default DataGridPopover
