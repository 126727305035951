import { SETUP_WORKSPACE_URL } from "src/constants"
import { patchRequest } from "src/services"

type SecureTunnelConfigurationType = {
  type: string
}

/**
 * Updates the secure tunnel configuration by sending a patch request to the setup workspace URL.
 *
 * @param {SecureTunnelConfigurationType} param - The configuration object for the secure tunnel.
 * @param {string} param.type - The type of the secure tunnel to be updated.
 * @returns {Promise<void>} A promise that resolves when the update is complete.
 */
export const updateSecureTunnel = async ({ type }: SecureTunnelConfigurationType): Promise<void> => {
  const data = {
    tunnel_type: type,
  }
  await patchRequest(SETUP_WORKSPACE_URL, data)
}
