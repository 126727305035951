import { LICENSE_VALIDATE } from "src/constants"
import useSWR from "swr"

type UseLicenseResponseType = {
  data?: LicenseType
  isLoading: boolean
  error: any
  isValidating: boolean
  getLicenseInfo: () => void
}

type LicenseType = {
  ownerId: number
  vhmId: string
  licenseBill: LicenseBillType
  licenseShortage: boolean
  errorMessage: string
  licenseLandingPageUrl: string
  noLicenseSKUsExists: boolean
}

type LicenseBillType = {
  ownerId: number
  vhmId: string
  licenseSkuDetails: Array<LicenseSkuDetailsType>
  licenseShortage: boolean
  noLicenseSKUsExists: true
}

type LicenseSkuDetailsType = {
  prodFeature: string
  requiredQty: number
  availableQty: number
  consumedQty: number
  consumedQtyPrev: number
}

/**
 * Custom hook to fetch license information using SWR.
 * The hook handles fetching, loading state, error, and re-fetching logic for the license.
 *
 * @returns {UseLicenseResponseType} - The response object containing the license data, loading state, error, and methods.
 */
const useLicense = (): UseLicenseResponseType => {
  const { data, error, mutate, isValidating } = useSWR(LICENSE_VALIDATE)

  return {
    data: data?.data || undefined,
    isLoading: !error && !data,
    error,
    getLicenseInfo: mutate,
    isValidating,
  }
}

export default useLicense
