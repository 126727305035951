import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENIconMenu } from "en-react/dist/src/components/Icons/Menu"
import { ENList } from "en-react/dist/src/components/List"
import { ENSideMenu } from "en-react/dist/src/components/SideMenu"
import { useFeatures } from "flagged"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { END_USER_BASE_ROUTE_PREFIX, Roles, RouteType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { getAllowedRoutes } from "src/routes/utils"
import ZtnaIcon from "src/shared/components/Icons"
import { RootState } from "src/store"
import { openModal, setSideNavCurrentRoute, toggleSideNav } from "src/store/ui/uiSlice"
import theme from "src/theme"
import { CollapsibleNavItem } from "./CollapsibleNavItem"
import { NavItem } from "./NavItem"
import { useSideNavStyles } from "./SideNav.styles"

const SideNav: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const flags = useFeatures()
  const queries = new URLSearchParams(search)
  const isRedirectedFromEndUser = queries.get("redirect_url") || ""
  const { role } = JSON.parse(localStorage.getItem("userData") || JSON.stringify({}))
  const isEndUserPortal = pathname.includes(END_USER_BASE_ROUTE_PREFIX) && role !== Roles.SUPER_ADMIN
  const { isOpened, currentRouteId } = useSelector((state: RootState) => state.ui.sideNav)
  const [routes] = role
    ? getAllowedRoutes(
        [isEndUserPortal || isRedirectedFromEndUser === END_USER_BASE_ROUTE_PREFIX ? Roles.END_USER : role],
        flags,
      ).reverse()
    : []
  const allowedRoutes = routes && routes.childs ? routes.childs : []
  const [expandedNavItemRoute, setExpandedNavItemRoute] = useState<RouteType | null>(null)
  const isDevicePostureUnsaved = useSelector((state: RootState) => state.ui.isDevicePostureUnsaved)
  const isPolicyOrderInProgress = useSelector((state: RootState) => state.ui.isPolicyOrderInProgress)

  const classes = useSideNavStyles({ isOpened })
  const isCustomerAdmin = isTenantRole(role)
  const isSuperAdmin = role === Roles.SUPER_ADMIN

  const handleSideNavToggle = () => {
    localStorage.setItem("isSideNavOpened", `${!isOpened}`)
    for (let t = 0; t <= 300; t += 10) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"))
      }, t)
    }
    dispatch(toggleSideNav())
  }

  const handleNavItemClick = (route: RouteType) => {
    const { path } = route
    if (isDevicePostureUnsaved) {
      dispatch(openModal("unsavedPosture"))
    }
    if (path && !isDevicePostureUnsaved && !isPolicyOrderInProgress) {
      dispatch(setSideNavCurrentRoute(route?.id))
      navigate(path.absolutePath)
    }
    if (isPolicyOrderInProgress) {
      dispatch(openModal("savePolicyOrder"))
    }
  }

  const handleNavItemExpanded = (route: RouteType) => {
    setExpandedNavItemRoute(route)
  }

  const renderNavItem = (route: RouteType, isChild?: boolean) => {
    return (
      <NavItem route={route} selectedRoute={currentRouteId} isChild={isChild} handleNavItemClick={handleNavItemClick} />
    )
  }

  const renderCollapsibleNavItem = (route: RouteType) => {
    return (
      <CollapsibleNavItem
        route={route}
        selectedRoute={currentRouteId}
        handleNavItemClick={handleNavItemClick}
        handleNavItemExpanded={handleNavItemExpanded}
        expandedNavItemRoute={expandedNavItemRoute}
      />
    )
  }

  const renderNavbarItems = (route: RouteType) => {
    const { childs } = route
    return childs?.length ? renderCollapsibleNavItem(route) : renderNavItem(route)
  }

  const sideNavAllowedRoutes = allowedRoutes?.filter((route) => route.showInSideNavigation === true)

  return (
    <div className={clsx(classes.sticky, classes.navContainer)}>
      <div className={clsx({ [classes.hamburgerIcon]: isOpened }, { [classes.hamburgerCollapsed]: !isOpened })}>
        <ENButton hideText variant="tertiary" onClick={handleSideNavToggle}>
          <ENIconMenu size="md" slot="before" style={{ color: theme.color.neutralLight[16] }} />
        </ENButton>
      </div>
      <div className={clsx({ [classes.drawerPaper]: isOpened }, { [classes.drawerClose]: !isOpened })}>
        <div
          className={clsx(
            { [classes.menuButtonContainerOpened]: isOpened },
            { [classes.menuButtonContainerClosed]: !isOpened },
          )}
        ></div>
        {isOpened && (
          <ENSideMenu>
            <div
              className={clsx(
                classes.navMenuContainer,
                { [classes.sideNavHeightLarge]: isSuperAdmin },
                { [classes.sideNavHeightTenant]: !isSuperAdmin },
              )}
            >
              <ENList aria-labelledby="nested-list-subheader">
                {sideNavAllowedRoutes?.map((route) => {
                  return <Fragment key={`NavBarItem-${route.id}`}>{renderNavbarItems(route)}</Fragment>
                })}
              </ENList>
            </div>
            {isCustomerAdmin && (
              <ENButton
                variant="tertiary"
                type="button"
                onClick={() => {
                  window.open("https://supportdocs.extremenetworks.com/support/documentation/uztna/", "_blank")
                }}
                className={classes.helpButton}
              >
                <div className={classes.helpIconWrapper}>
                  <ZtnaIcon name="enHelp" className={classes.helpIcon} size="md" />
                </div>
              </ENButton>
            )}
          </ENSideMenu>
        )}
      </div>
    </div>
  )
}

export default SideNav
