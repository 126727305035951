import {
  add,
  addDays,
  addMonths,
  addYears,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  getDay,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSameMonth,
  isValid,
  setDay,
  setYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  sub,
  subMonths,
  subYears,
  toDate
} from 'date-fns';
import { TemplateResult, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENChipGroup } from '../chip-group/chip-group';
import { ENChip } from '../chip/chip';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENIconChevronLeft } from '../icon/icons/chevron-left';
import { ENIconChevronRight } from '../icon/icons/chevron-right';
import { ENInlineTimeSelector } from '../inline-time-selector/inline-time-selector';
import { ENSwitch } from '../switch/switch';
import { ENTimezoneDropdown } from '../timezone-dropdown/timezone-dropdown';
import styles from './range-calendar.scss';

/**
 * Component: en-range-calendar
 * @slot - The components content
 */
export class ENRangeCalendar extends ENElement {
  static el = 'en-range-calendar';

  private elementMap = register({
    elements: [
      [ENButton.el, ENButton],
      [ENSwitch.el, ENSwitch],
      [ENChipGroup.el, ENChipGroup],
      [ENChip.el, ENChip],
      [ENInlineTimeSelector.el, ENInlineTimeSelector],
      [ENIconChevronLeft.el, ENIconChevronLeft],
      [ENIconChevronRight.el, ENIconChevronRight],
      [ENIconChevronDown.el, ENIconChevronDown],
      [ENTimezoneDropdown.el, ENTimezoneDropdown]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private switchEl = unsafeStatic(this.elementMap.get(ENSwitch.el));
  private chipEl = unsafeStatic(this.elementMap.get(ENChip.el));
  private chipGroupEl = unsafeStatic(this.elementMap.get(ENChipGroup.el));
  private inlineTimeSelectorEl = unsafeStatic(this.elementMap.get(ENInlineTimeSelector.el));
  private iconChevronLeftEl = unsafeStatic(this.elementMap.get(ENIconChevronLeft.el));
  private iconChevronRightEl = unsafeStatic(this.elementMap.get(ENIconChevronRight.el));
  private iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));
  private timezoneDropdownEl = unsafeStatic(this.elementMap.get(ENTimezoneDropdown.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Current Month/Year. Recommended not to set it. Set it only if in some case today's date will not be equal to new Date()
   */
  @property()
  currentDate: any = new Date();

  /**
   * Today Month/Year. It is same as current date and not required to set by developer. It is for internal use.
   */
  @property()
  todayCurrentDate: any;

  /**
   * Current Month/Year display in header. By default it is initialized to first day of current date month. Recommended not to set it explicitly.
   */
  @property()
  navDate: any;

  @property({ type: Boolean })
  hideBoxShadow?: boolean = false;

  /**
   * If true show footer along with footer controls. Default is true.
   */
  @property({ type: Boolean })
  showFooter?: boolean = true;

  /**
   * If true hide Done button. Default is false.
   */
  @property({ type: Boolean })
  hideDone?: boolean = false;

  /** This property allows chaning the look and feel of filter chips (Last Week/Last Month/Last Quarter)
   * - **default**:  This `default` variant will show chips as Last Week/Last Month/Last Quarter along with neutral variant chip
   * - **absolute**: This `absolute` variant will show chips as 7 Days/30 Days/90 Days along with secondaryV2 variant chip
   * Default is default.
   */
  @property()
  filterChipVariant?: 'default' | 'absolute' = 'default';

  /**
   * If true show Last Week Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastWeekChip?: boolean = false;

  /**
   * If true show Last Month Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastMonthChip?: boolean = false;

  /**
   * If true show Last Quarter Chip. Default is false.
   */
  @property({ type: Boolean })
  showLastQuarterChip?: boolean = false;

  /**
   * Minimum date for disabled dates (format: yyyy/mm/dd)
   */
  @property()
  disabledMinDate?: string;

  /**
   * Maximum date for disabled dates (format: yyyy/mm/dd)
   */
  @property()
  disabledMaxDate?: string;

  /**
   * If set to true, then show timezone field and switch if enabled. Default is false.
   */
  @property({ type: Boolean })
  showTimezone?: boolean = false;

  /**
   * If set to true, then show timezone switch that can hide and show timezone field. Default is true.
   */
  @property({ type: Boolean })
  showTimezoneSwitch?: boolean = true;

  /**
   * If true, then set default timezone to client timezone. Default is true.
   */
  @property({ type: Boolean })
  setDefaultTimezoneToBrowserTimezone?: boolean = true;

  /**
   * Default is false. If true hide seconds field.
   */
  @property({ type: Boolean })
  hideSecondsField?: boolean = false;

  /*
   * Set this property if you want to set timezone switch on at initial load. Default is false.
   */
  @property({ type: Boolean })
  setTimezoneSwitchOnAtInitialLoad?: boolean = false;

  /**
   * timeSelectorDirection (Optional)
   * - **column** Show label and date above selector where label will be in left and date will be in right
   * - **row** Show label and date in left of selector where date will be below label.
   */
  @property()
  timeSelectorDirection?: 'row' | 'column' = 'column';

  /**
   * timeSelectorVariant (Optional)
   * - **primary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the dropdown to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   */
  @property()
  timeSelectorVariant?: 'primary' | 'secondary' = 'primary';

  /**
   * multiYear (Optional)
   * Amount of years to go before and after the current date. Don't specify it in case your don't want to restrict minimum or maximum month
   */
  @property({ type: Number })
  multiYear?: number = 3;

  @property()
  previousButtonText = 'Previous Month';

  @property()
  nextButtonText = 'Next Month';

  /**
   * Container for current days in month
   */
  @property({ type: Array })
  days: Array<any> = [];

  /**
   * Container for years toggle
   */
  @property({ type: Array })
  years: Array<any> = [];

  /**
   * If true show month popup by default when calendar will be opened. If will be disabled for range selection even if you pass it true
   */
  @property({ type: Boolean })
  showMonthPopup: boolean = false;

  /**
   * Don't use this property. It is for internal use. It is set by double range calendar component.
   */
  @property({ type: Boolean })
  isDoubleRangeCalendar: boolean = false;

  /**
   * Start Selected date
   */
  @property()
  startSelectedDate: any;

  /**
   * End Selected date. Don't set it if range selection is disabled
   */
  @property()
  endSelectedDate: any;

  /**
   * Start Selected time. Specify in HH;mm;ss format.
   */
  @property()
  startSelectedTime: string;

  /**
   * End Selected time. Specify in HH;mm;ss format.
   */
  @property()
  endSelectedTime: any;

  /*
   * Timezone string
   */
  @property()
  timezone?: string = '';

  /**
   * If true than reset data will not be determined by initial start and end date
   */
  @property({ type: Boolean })
  determineResetDateFromInitialDate?: boolean = true;

  /**
   * Initialize it in case reset start date is different from initialSelectedDate. This property is relevant only if `determineResetDateFromInitialDate` is true
   */
  @property()
  resetSelectedDate?: string = '';

  /**
   * To initialize intial end selected date. Relevant only in case of range selection. This property is relevant only if `determineResetDateFromInitialDate` is true
   */
  @property()
  resetSelectedEndDate?: string = '';

  /**
   * Reset Start Selected time. Specify in HH;mm;ss format. Relevant only if `determineResetDateFromInitialDate` is false.
   */
  @property()
  resetStartSelectedTime: string = '';

  /**
   * Reset End Selected time. Specify in HH;mm;ss format. Relevant only if `determineResetDateFromInitialDate` is false.
   */
  @property()
  resetEndSelectedTime: string = '';

  /**
   * To store the initial reset value of timezone
   */
  @property()
  resetTimezone: string = '';

  /**
   * Internal ID only for labeling datepicker popup
   */
  @property()
  datepickerId: any;

  /**
   * Specify date format for UI display
   */
  @property()
  dateFormat: 'MMM dd, yyyy' | 'MM/dd/yyyy' | 'dd/MM/yyyy' | 'yyyy/MM/dd' | 'MMM dd yyyy' | 'dd MMM yyyy' = 'MMM dd, yyyy';

  /**
   * enableRangeSelection
   * If true, user can select start and end date/time. Default is true. If false user can select single date only.
   */
  @property({ type: Boolean })
  enableRangeSelection: boolean = true;

  /**
   * enableMonthAndYearSelection
   * If true, user can select month and year. Default is false. For range selection, it will always be false even if you set it true.
   */
  @property({ type: Boolean })
  enableMonthAndYearSelection: boolean = false;

  /**
   * enableTimeSelection
   * If true, user can also provide time input. Default is false.
   */
  @property({ type: Boolean })
  enableTimeSelection: boolean = false;

  /**
   * If true, disable reset button. Default is false.
   */
  @property({ type: Boolean })
  forceDisableResetButton: boolean = false;

  /**
   * If true, disable done button. Default is false.
   */
  @property({ type: Boolean })
  disableDoneButton: boolean = false;

  /**
   * Specify "full" date format for aria role
   */
  @property()
  fullDateFormat = 'd, EEEE MMMM yyyy';

  /**
   * Field note icon name
   */
  @property()
  iconName?: string;

  /**
   * Month gap with which range calendar will open. Default is 1.
   */
  @property({ type: Number })
  rangeMonthGap?: number = 1;

  /**
   * Show the day of the week as a short hand, e.g. "M" for Monday
   */
  @property({ type: Boolean })
  isDayShortHand?: boolean = false;

  /**
   * Start the day of the week on Monday
   */
  @property({ type: Boolean })
  startOnMonday?: boolean = false;

  /**
   * If set to true next button navigation will be removed. Default is false. Required in Double Range Calendar
   */
  @property({ type: Boolean })
  removeNextNavigation?: boolean = false;

  /**
   * If set to true prev button navigation will be removed. Default is false. Required in Double Range Calendar
   */
  @property({ type: Boolean })
  removePrevNavigation?: boolean = false;

  /**
   * If set to true, center header view else show it on left. Default is false.
   */
  @property({ type: Boolean })
  centerHeaderView?: boolean = false;

  /**
   * Query the calendar month popup
   */
  @query('.en-c-calendar__month-selector-popup')
  calendarMonthPopup: HTMLElement;

  /**
   * Query the month selector button
   */
  @query('.en-c-calendar__month-selector-button')
  calendarMonthButton: HTMLElement;

  @state()
  showTimeSelector: boolean = false;

  private _defaultStartSelectedDate: string = '';
  private _defaultEndSelectedDate: string = '';
  private _defaultStartSelectedTime: string = '';
  private _defaultEndSelectedTime: string = '';
  private _monthPopupTimer: number = null;

  /**
   * Initializations
   */
  constructor() {
    super();
    this.updateGrid = this.updateGrid.bind(this);
    this.setGrid = this.setGrid.bind(this);
    this.setNextMonth = this.setNextMonth.bind(this);
    this.setPrevMonth = this.setPrevMonth.bind(this);
    this.toggleMonthPopup = this.toggleMonthPopup.bind(this);
  }

  private _handleStartOrEndDateMissing() {
    if (this.isDoubleRangeCalendar && !this.enableRangeSelection) return;
    if (!this.startSelectedDate && !!this.endSelectedDate) {
      this.startSelectedDate = this.endSelectedDate;
    } else if (!this.endSelectedDate && !!this.startSelectedDate) {
      this.endSelectedDate = this.startSelectedDate;
    }
    if (this.startSelectedTime && !this.endSelectedTime) {
      this.endSelectedTime = this.startSelectedTime;
    } else if (!this.startSelectedTime && this.endSelectedTime) {
      this.startSelectedTime = this.endSelectedTime;
    }
  }

  private _handleCurrentDate() {
    /* 1 */
    this.navDate = startOfMonth(this.currentDate);

    if (!this.isDoubleRangeCalendar) {
      if (this.startSelectedDate || this.endSelectedDate) {
        /* 2 */
        this._handleStartOrEndDateMissing();
        let endSelectedDate = new Date(this.endSelectedDate);
        endSelectedDate = isValid(endSelectedDate) ? endSelectedDate : new Date();
        endSelectedDate.setHours(0, 0, 0, 0);
        this.updateGrid(endSelectedDate);
      } else {
        /* 3 */
        this.setGrid();
      }
    } else {
      this.setGrid();
    }
  }

  /**
   * Connected callback lifecycle
   * 1. Use the start of the month for the month navigation
   * 2. Set calendar grid based on end date month and year
   * 3. Set calendar grid based on current date month and year
   */
  connectedCallback() {
    super.connectedCallback();
    if (typeof this.currentDate === 'string') {
      this.currentDate = new Date(this.currentDate);
    }
    this._handleCurrentDate();
  }

  /**
   * Initialize default values
   */
  firstUpdated() {
    if (this.enableRangeSelection || !this.enableMonthAndYearSelection) {
      this.showMonthPopup = false;
    }
    if (!this.enableTimeSelection) {
      this.showTimeSelector = false;
    }
    setTimeout(() => {
      this._defaultStartSelectedDate = this.startSelectedDate;
      this._defaultEndSelectedDate = this.endSelectedDate;
      this._defaultStartSelectedTime = this.startSelectedTime;
      this._defaultEndSelectedTime = this.endSelectedTime;
      if (!!this.startSelectedTime || !!this.endSelectedTime) {
        this.showTimeSelector = true;
      }
      if (this.enableTimeSelection && this.showTimezone) {
        if (this.resetTimezone === '') {
          this.resetTimezone = this.timezone;
        } else {
          this.timezone = this.resetTimezone;
        }
      }
    }, 0);
  }

  /**
   * Updated lifecycle
   * 1. Iterates over the changed properties of the component after an update.
   * 2. Checks if the changed property is start or end selected date and it has been modified.
   * 3. Update grid
   * @param changedProperties - A map of changed properties in the component after an update.
   */
  updated(changedProperties: Map<string, unknown>) {
    let updateGrid = false;
    /* 1 */
    changedProperties.forEach((oldValue, propName) => {
      /* 2 */
      if (propName === 'startSelectedDate' && this.startSelectedDate !== oldValue) {
        updateGrid = true;
      } else if (propName === 'endSelectedDate' && this.endSelectedDate !== oldValue) {
        updateGrid = true;
      } else if (propName === 'enableRangeSelection' && this.enableRangeSelection !== oldValue && this.enableRangeSelection === true) {
        this.showMonthPopup = false;
        this.enableMonthAndYearSelection = false;
      } else if (
        propName === 'enableMonthAndYearSelection' &&
        this.enableMonthAndYearSelection !== oldValue &&
        this.enableMonthAndYearSelection === false
      ) {
        this.showMonthPopup = false;
      } else if (propName === 'enableTimeSelection' && this.enableTimeSelection !== oldValue) {
        this.showTimeSelector = false;
      } else if (propName === 'currentDate' && this.currentDate !== oldValue) {
        if (typeof this.currentDate === 'string') {
          this.currentDate = new Date(this.currentDate);
        }
        this.navDate = startOfMonth(this.currentDate);
        this.setGrid();
      }
    });
    if (updateGrid) {
      /* 3 */
      if (!this.isDoubleRangeCalendar) {
        this._handleStartOrEndDateMissing();
        let endSelectedDate = new Date(this.endSelectedDate);
        endSelectedDate = isValid(endSelectedDate) ? endSelectedDate : new Date();
        endSelectedDate.setHours(0, 0, 0, 0);
        this.updateGrid(endSelectedDate);
      }
    }
    if (this.enableMonthAndYearSelection && !this.enableRangeSelection) {
      if (this._monthPopupTimer) {
        clearTimeout(this._monthPopupTimer);
        this._monthPopupTimer = null;
      }
      this._monthPopupTimer = setTimeout(() => {
        const currentMonth: HTMLElement = this.calendarMonthPopup.querySelector('.en-is-current');
        const activeMonth: HTMLElement = this.calendarMonthPopup.querySelector('.en-is-active');
        if (activeMonth) {
          /* 1 */
          activeMonth.tabIndex = -1;
          if (this.showMonthPopup === true) {
            activeMonth.tabIndex = 0;
            activeMonth.focus();
          }
        } else if (currentMonth) {
          /* 2 */
          currentMonth.tabIndex = -1;
          if (this.showMonthPopup === true) {
            currentMonth.tabIndex = 0;
            currentMonth.focus();
          }
        }
      }, 1);
    }
  }

  /**
   * Update the calendar grid to the month and year selected
   * 1. If showMonthPop is set then open month popup
   * 2. Focus back on the month header button after month grid has been updated
   * @param date date timestamp
   */
  updateGrid(date: Date) {
    if (date !== undefined) {
      const yearNumber = getYear(date);
      this.navDate = setYear(date, yearNumber);
      const enableMonthPopup = this.enableMonthAndYearSelection && !this.enableRangeSelection;
      if (enableMonthPopup && this.showMonthPopup === true) {
        /* 1 */
        this.toggleMonthPopup();
      }
      this.setGrid();
      // if (enableMonthPopup) {
      //   const monthButton = this.shadowRoot.querySelector<HTMLButtonElement>('.en-c-calendar__month-selector-button');
      //   if (monthButton) {
      //     /* 2 */
      //     monthButton.focus();
      //   }
      // }
    }
  }

  /**
   * Make the calendar days header
   * 1. Set the start of the week
   * 2. Set the pattern for the day of the week
   * 3. Add the day to the array
   * 4. Return the array
   */
  setWeekdaysHeader() {
    const weekdays = [];
    const start = this.startOnMonday ? 1 : 0; /* 1 */
    const pattern = this.isDayShortHand ? 'eeeee' : 'eeeeee'; /* 2 */
    for (let i = 0; i < 7; i++) {
      weekdays.push(format(setDay(new Date(), start + i), pattern)); /* 3 */
    }
    return weekdays; /* 4 */
  }

  /**
   * Navigate previous in sequential month order
   * 1. Go back a month if able to
   * 2. Reset the calendar grid
   */
  setPrevMonth() {
    if (this.canChangeSubNavMonth()) {
      this.navDate = subMonths(this.navDate, this.rangeMonthGap || 1); /* 1 */
      this.setGrid(); /* 2 */
      this.dispatch({
        eventName: 'prev',
        detailObj: {
          navDate: this.navDate
        }
      });
      return true;
    }
  }

  /**
   * Navigate next in sequential month order
   * 1. Go forward a month if able to
   * 2. Reset the calendar grid
   */
  setNextMonth() {
    if (this.canChangeAddNavMonth()) {
      this.navDate = addMonths(this.navDate, this.rangeMonthGap || 1); /* 1 */
      this.setGrid(); /* 2 */
      this.dispatch({
        eventName: 'next',
        detailObj: {
          navDate: this.navDate
        }
      });
      return true;
    }
  }

  /**
   * Validate if month can proceed previous.
   * 1. If the current end of the month date is before the minimum date, return false
   */
  canChangeSubNavMonth() {
    if (typeof this.currentDate !== 'object') {
      return false;
    }
    let disabledMinDate;
    if (this.multiYear) {
      disabledMinDate = startOfYear(subYears(this.currentDate, this.multiYear));
    }
    if (this.disabledMinDate) {
      disabledMinDate = startOfDay(toDate(new Date(this.disabledMinDate)));
    }
    /* 1 */
    if (!!disabledMinDate && isBefore(subMonths(endOfMonth(this.navDate), 1), disabledMinDate)) {
      return false;
    }
    return true;
  }

  /**
   * Validate if month can proceed next.
   * 1. If the day is after the maximum date, return false
   */
  canChangeAddNavMonth() {
    if (typeof this.currentDate !== 'object') {
      return false;
    }
    let disabledMaxDate;
    if (this.multiYear) {
      disabledMaxDate = endOfYear(addYears(this.currentDate, this.multiYear));
    }
    if (this.disabledMaxDate) {
      disabledMaxDate = endOfDay(toDate(new Date(this.disabledMaxDate)));
    }
    /* 1 */
    if (!!disabledMaxDate && isAfter(addMonths(startOfMonth(this.navDate), 1), disabledMaxDate)) {
      return false;
    }
    return true;
  }

  /**
   * Toggle the month popup overlay
   * 1. Starting Year: Get the current year - this.multiYear years (defaults to 3)
   * 2. Ending Year: Get the current year + this.multiYear years (defaults to 3)
   * 3. Get the years in between
   * 4. Get the months in each year
   * 5. Increment the year
   * 6. Position scroll on year of clicked month
   */
  toggleMonthPopup() {
    if (!this.enableMonthAndYearSelection || this.enableRangeSelection) {
      this.showMonthPopup = false;
      return false;
    }
    this.showMonthPopup = !this.showMonthPopup;
    /* 1 */
    let startYear = subYears(this.currentDate, this.multiYear);
    /* 2 */
    const endYear = addYears(this.currentDate, this.multiYear);
    /* 3 */
    this.years = [];
    while (startYear <= endYear) {
      const year: any = {};
      year.value = startYear;
      this.years.push(year);
      /* 4 */
      year.month = eachMonthOfInterval({
        start: startOfYear(startYear),
        end: endOfYear(startYear)
      });
      /* 5 */
      startYear = addYears(startYear, 1);
    }

    setTimeout(() => {
      /* 6 */
      const monthToggleYear = this.calendarMonthButton.dataset.year;
      this.shadowRoot.querySelector('#en-year-' + monthToggleYear).scrollIntoView({ block: 'nearest' });
    }, 1);
  }

  /**
   * Create days of month grid
   * 1. Add empty days to align the first day of the month with the correct day of the week
   * 2. Add the days of the selected month
   * 3. Add empty days to align the end of the month with the end of the week
   */
  setGrid() {
    setTimeout(() => {
      this.days = [];
      const weekStart = this.startOnMonday ? 1 : 0;
      const selectedMonth = getMonth(this.navDate);
      let currentDate = startOfMonth(this.navDate);
      let firstDayOfMonth = (getDay(currentDate) + 7 - weekStart) % 7;
      let weekDays = [];
      /* 1 */
      while (firstDayOfMonth > 0) {
        weekDays.push({ value: null, available: false });
        firstDayOfMonth--;
      }
      /* 2 */
      while (getMonth(currentDate) === selectedMonth) {
        currentDate.setHours(0, 0, 0, 0);
        const day = { value: currentDate, available: true };
        weekDays.push(day);
        currentDate = addDays(currentDate, 1);
      }
      /* 3 */
      while (weekDays.length % 7 !== 0) {
        weekDays.push({ value: null, available: false });
      }
      let counter = 0;
      let i = 0;
      let week = [];
      do {
        week.push(weekDays[i]);
        i++;
        counter++;
        if (counter === 7 || i === weekDays.length) {
          this.days.push(week);
          week = [];
          counter = 0;
        }
      } while (i < weekDays.length);
    }, 1);
  }

  /**
   * Apply active state if day has been selected
   * 1. Check if selected day date is selected start date
   * 2. Check if selected day date is selected end date
   * @param day selected day
   */
  setActive(day: any) {
    if (day.available) {
      const startDate = new Date(this.startSelectedDate);

      /* 1 */
      if (isValid(startDate)) {
        startDate.setHours(0, 0, 0, 0);
        if (day.available && isEqual(day.value, startDate)) {
          return true;
        }
      }

      /* 2 */
      const endDate = new Date(this.endSelectedDate);
      if (isValid(endDate)) {
        endDate.setHours(0, 0, 0, 0);
        if (day.available && isEqual(day.value, endDate)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Check if day can be selected
   * @param day selected day
   */
  setAvailableDay(day: Date): boolean {
    if (typeof this.currentDate !== 'object') {
      return false;
    }
    let disabledMinDate;
    if (this.multiYear) {
      disabledMinDate = startOfYear(subYears(this.currentDate, this.multiYear));
    }

    if (this.disabledMinDate) {
      disabledMinDate = startOfDay(toDate(new Date(this.disabledMinDate)));
    }
    let disabledMaxDate;
    if (this.multiYear) {
      disabledMaxDate = endOfYear(addYears(this.currentDate, this.multiYear));
    }
    if (this.disabledMaxDate) {
      disabledMaxDate = endOfDay(toDate(new Date(this.disabledMaxDate)));
    }
    if (isAfter(disabledMinDate, day) || isBefore(disabledMaxDate, day)) {
      return false;
    }
    return true;
  }

  /**
   * Check if month can be selected
   * @param month selected month
   */
  setAvailableMonth(month: any): boolean {
    let disabledMinDate;
    if (this.multiYear) {
      disabledMinDate = startOfYear(subYears(this.currentDate, this.multiYear));
    }
    if (this.disabledMinDate) {
      disabledMinDate = toDate(subMonths(new Date(this.disabledMinDate), 1));
    }
    let disabledMaxDate;
    if (this.multiYear) {
      disabledMaxDate = endOfYear(addYears(this.currentDate, this.multiYear));
    }
    if (this.disabledMaxDate) {
      disabledMaxDate = toDate(new Date(this.disabledMaxDate));
    }
    if (isAfter(disabledMinDate, month) || isBefore(disabledMaxDate, month)) {
      return false;
    }
    return true;
  }

  /**
   * Check if day in calendar is today and highlight it
   * @param day
   */
  setToday(day: any): boolean {
    if (this.todayCurrentDate) {
      if (isSameDay(this.todayCurrentDate, day.value)) {
        return true;
      } else false;
    } else {
      if (isSameDay(this.currentDate, day.value)) {
        return true;
      }
      return false;
    }
  }

  isStartDay(day: { value: Date; available: boolean }): boolean {
    if (this.startSelectedDate && day.available && this.endSelectedDate && this.endSelectedDate !== this.startSelectedDate) {
      const startDate = new Date(this.startSelectedDate);
      startDate.setHours(0, 0, 0, 0);
      if (isSameDay(startDate, day.value)) return true;
    }
    return false;
  }

  isEndDay(day: { value: Date; available: boolean }): boolean {
    if (this.endSelectedDate && day.available && this.startSelectedDate && this.startSelectedDate !== this.endSelectedDate) {
      const endDate = new Date(this.endSelectedDate);
      endDate.setHours(0, 0, 0, 0);
      if (isSameDay(endDate, day.value)) return true;
    }
    return false;
  }

  isBetweenStartAndEndDay(day: { value: Date; available: boolean }): boolean {
    if (this.startSelectedDate && this.endSelectedDate && day.available) {
      const startDate = new Date(this.startSelectedDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(this.endSelectedDate);
      endDate.setHours(0, 0, 0, 0);
      if (isAfter(day.value, startDate) && isBefore(day.value, endDate)) {
        return true;
      }
    }
    return false;
  }

  isLastMonthDay(day: { value: Date; available: boolean }, lastDay: { value: Date; available: boolean }): boolean {
    if (day.value === null && !!lastDay.value) {
      if (this.isBetweenStartAndEndDay(lastDay)) {
        return isLastDayOfMonth(lastDay.value);
      }
    }
    return false;
  }

  isFirstMonthDay(day: { value: Date; available: boolean }, nextDay: { value: Date; available: boolean }): boolean {
    if (day.value === null && !!nextDay.value) {
      if (this.isBetweenStartAndEndDay(nextDay)) {
        return isFirstDayOfMonth(nextDay.value);
      }
      return false;
    }
  }

  /**
   * Select the day and emit
   * 1. Bind to form control
   * @param day selected day
   */
  handleOnClickDay(day: any) {
    /* 1 */
    if (day.available) {
      const selectedDayDate: Date = day.value;
      if (!this.startSelectedDate) {
        if (this.enableRangeSelection && this.endSelectedDate) {
          const endDate = new Date(this.endSelectedDate);
          if (isAfter(selectedDayDate, endDate)) {
            this.startSelectedDate = this.endSelectedDate;
            this.endSelectedDate = selectedDayDate.toISOString();
          } else {
            this.startSelectedDate = selectedDayDate.toISOString();
          }
        } else {
          this.startSelectedDate = selectedDayDate.toISOString();
        }
      } else if (this.enableRangeSelection && (!this.endSelectedDate || this.startSelectedDate === this.endSelectedDate)) {
        const startDate = new Date(this.startSelectedDate);
        if (isAfter(selectedDayDate, startDate)) {
          this.endSelectedDate = selectedDayDate.toISOString();
        } else {
          this.endSelectedDate = this.startSelectedDate;
          this.startSelectedDate = selectedDayDate.toISOString();
        }
      } else {
        this.startSelectedDate = selectedDayDate.toISOString();
        this.endSelectedDate = '';
      }
      this._handleStartOrEndDateMissing();
      this.handleOnChange();
    }
  }

  /**
   * Handle event dispatch on change
   * 1. Handle dispatch in case range selection is diabled.
   * 2. Handle dispatch in case range selection is enabled
   */
  public handleOnChange(evtName: string = 'change') {
    let timeAttributes: { startTime?: string; endTime?: string } = {};
    if (this.enableTimeSelection) {
      timeAttributes = { startTime: this.startSelectedTime };
      if (this.enableRangeSelection) {
        timeAttributes.endTime = this.endSelectedTime;
      }
    }
    const startDate = new Date(this.startSelectedDate);
    if (isValid(startDate)) {
      startDate.setHours(0, 0, 0, 0);
    }
    if (!this.enableRangeSelection) {
      /* 1 */
      this.dispatch({
        eventName: evtName,
        detailObj: {
          startDate: isValid(startDate) ? format(startDate, this.dateFormat) : '',
          rawStartDate: this.startSelectedDate,
          ...timeAttributes,
          timezone: this.timezone ?? ''
        }
      });
    } else {
      /* 2 */
      const endDate = new Date(this.endSelectedDate);
      if (isValid(endDate)) {
        endDate.setHours(0, 0, 0, 0);
      }
      this.dispatch({
        eventName: evtName,
        detailObj: {
          startDate: isValid(startDate) ? format(startDate, this.dateFormat) : '',
          rawStartDate: this.startSelectedDate,
          endDate: isValid(endDate) ? format(endDate, this.dateFormat) : '',
          rawEndDate: this.endSelectedDate,
          ...timeAttributes,
          timezone: this.timezone ?? ''
        }
      });
    }
  }

  /**
   * Handle Reset calendar
   */
  handleReset() {
    if (!this.determineResetDateFromInitialDate) {
      this.startSelectedDate = this.resetSelectedDate;
      this.endSelectedDate = this.resetSelectedEndDate;
      this.startSelectedTime = this.resetStartSelectedTime;
      this.endSelectedTime = this.resetEndSelectedTime;
    } else {
      this.startSelectedDate = this._defaultStartSelectedDate;
      this.endSelectedDate = this._defaultEndSelectedDate;
      this.startSelectedTime = this._defaultStartSelectedTime;
      this.endSelectedTime = this._defaultEndSelectedTime;
    }
    if (this.enableTimeSelection && this.showTimezone) {
      this.timezone = this.resetTimezone;
    }
    this.handleOnChange('reset');
  }

  /**
   * Handle Done button click on calendar
   */
  handleDone() {
    this.handleOnChange('done');
  }

  handleTimeToggle() {
    if (!this.enableTimeSelection) {
      return false;
    }
    this.showTimeSelector = !this.showTimeSelector;
    if (this.showTimeSelector) {
      setTimeout(() => {
        const timeSelector = this.shadowRoot.querySelector<HTMLElement>('.en-c-calendar__time-selector--selector');
        if (timeSelector) {
          timeSelector.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
    this.dispatch({
      eventName: 'time-toggle',
      detailObj: {
        startTime: this.startSelectedTime,
        endTime: this.endSelectedTime
      }
    });
  }

  handleTimeSelectorChange(evt: CustomEvent, type: 'start' | 'end' = 'start') {
    evt.stopPropagation();
    const detail = evt.detail;
    if (type === 'start') {
      this.startSelectedTime = `${detail.hours || ''}:${detail.minutes || ''}:${detail.seconds || ''}`;
    } else {
      this.endSelectedTime = `${detail.hours || ''}:${detail.minutes || ''}:${detail.seconds || ''}`;
    }
    this.handleOnChange();
  }

  private _handleTimezoneSelection(evt: CustomEvent) {
    const { timezone } = evt.detail;
    if (!timezone) {
      this.timezone = undefined;
    } else {
      this.timezone = timezone;
    }
    if (this.setDefaultTimezoneToBrowserTimezone && this.resetTimezone === '') {
      this.resetTimezone = this.timezone;
    }
    this.handleOnChange();
  }

  /**
   * 1. Adding one day in week, month and quarter because today day is not excluded. It is included.
   * @param type
   */
  handleChipClick(type: 'week' | 'month' | 'quarter' = 'week') {
    const todayDate = new Date(this.currentDate);
    todayDate.setHours(0, 0, 0, 0);
    if (type === 'week') {
      let weekBeforeDate = sub(todayDate, { weeks: 1 });
      /* 1 */
      weekBeforeDate = add(weekBeforeDate, { days: 1 });
      weekBeforeDate.setHours(0, 0, 0, 0);
      this.startSelectedDate = weekBeforeDate.toISOString();
      this.endSelectedDate = todayDate.toISOString();
    } else if (type === 'month') {
      const subOb = this.filterChipVariant === 'absolute' ? { days: 30 } : { months: 1 };
      let monthBeforeDate = sub(todayDate, subOb);
      monthBeforeDate = add(monthBeforeDate, { days: 1 });
      monthBeforeDate.setHours(0, 0, 0, 0);
      this.startSelectedDate = monthBeforeDate.toISOString();
      this.endSelectedDate = todayDate.toISOString();
    } else if (type === 'quarter') {
      const subOb = this.filterChipVariant === 'absolute' ? { days: 90 } : { months: 3 };
      let monthBeforeDate = sub(todayDate, subOb);
      monthBeforeDate = add(monthBeforeDate, { days: 1 });
      monthBeforeDate.setHours(0, 0, 0, 0);
      this.startSelectedDate = monthBeforeDate.toISOString();
      this.endSelectedDate = todayDate.toISOString();
    }
    this.handleOnChange('change');
  }

  /**
   * Handle keydown
   * 1. Close the month calendar popup when escape is hit when the user is focused within the popup
   * 2. Return focus back to the month selector button
   */
  handleOnKeydown(e: KeyboardEvent) {
    if (e.code === 'Escape') {
      this.showMonthPopup = false; /* 1 */
      this.calendarMonthButton.tabIndex = 0; /* 2 */
      this.calendarMonthButton.focus();
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-range-calendar', {
      'hide-box-shadow': this.hideBoxShadow
    });
    const startTimes = this.startSelectedTime ? this.startSelectedTime.split(':') : [];
    const endTimes = this.endSelectedTime ? this.endSelectedTime.split(':') : [];
    const startDate = new Date(this.startSelectedDate);
    if (isValid(startDate)) startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(this.endSelectedDate);
    if (isValid(endDate)) endDate.setHours(0, 0, 0, 0);

    const showChips = this.enableRangeSelection && (this.showLastWeekChip || this.showLastMonthChip || this.showLastQuarterChip);
    let lastDay: { value: Date; available: boolean } = { value: null, available: false };
    let nextDay: { value: Date; available: boolean } = { value: null, available: false };
    let disableResetButton = this.forceDisableResetButton;
    if (!disableResetButton) {
      if (!this.enableRangeSelection) {
        if (this.determineResetDateFromInitialDate) {
          disableResetButton = this.startSelectedDate === this._defaultStartSelectedDate && this.startSelectedTime === this._defaultStartSelectedTime;
        } else {
          disableResetButton = this.startSelectedDate === this.resetSelectedDate && this.startSelectedTime === this.resetStartSelectedTime;
        }
      } else {
        if (this.determineResetDateFromInitialDate) {
          disableResetButton =
            this.startSelectedDate === this._defaultStartSelectedDate &&
            this.endSelectedDate === this._defaultEndSelectedDate &&
            this.startSelectedTime === this._defaultStartSelectedTime &&
            this.endSelectedTime === this._defaultEndSelectedTime;
        } else {
          disableResetButton =
            this.startSelectedDate === this.resetSelectedDate &&
            this.endSelectedDate === this.resetSelectedEndDate &&
            this.startSelectedTime === this.resetStartSelectedTime &&
            this.endSelectedTime === this.resetEndSelectedTime;
        }
      }
      if (disableResetButton && this.enableTimeSelection && this.showTimezone) {
        disableResetButton = disableResetButton && this.timezone === this.resetTimezone;
      }
    }
    return html`
      <div class="${componentClassNames}">
        ${
          showChips
            ? html`<div class="en-c-chips">
          <${this.chipGroupEl}>
            ${this.showLastWeekChip ? html`<${this.chipEl} @click=${() => this.handleChipClick('week')} variant="${this.filterChipVariant === 'absolute' ? 'secondary' : 'neutral'}">${this.filterChipVariant === 'absolute' ? '7 Days' : 'Last Week'}</${this.chipEl}>` : html``}
            ${this.showLastMonthChip ? html`<${this.chipEl} @click=${() => this.handleChipClick('month')} variant="${this.filterChipVariant === 'absolute' ? 'secondary' : 'neutral'}">${this.filterChipVariant === 'absolute' ? '30 Days' : 'Last Month'}</${this.chipEl}>` : html``}
            ${this.showLastQuarterChip ? html`<${this.chipEl} @click=${() => this.handleChipClick('quarter')} variant="${this.filterChipVariant === 'absolute' ? 'secondary' : 'neutral'}">${this.filterChipVariant === 'absolute' ? '90 Days' : 'Last Quarter'}</${this.chipEl}>` : html``}
          </${this.chipGroupEl}>
        </div>`
            : html``
        }
        <div class="en-c-scroll">
        <nav class=${classMap({ 'en-c-calendar__header': true, 'center-header-view': this.centerHeaderView })}>
          ${
            this.centerHeaderView
              ? html`
          <${this.buttonEl}
              type="button"
              variant="quaternary"
              class=${classMap({ 'en-c-calendar__nav-btn': true, 'en-c-calendar__nav-btn--prev': true, 'd-none': this.removePrevNavigation })}
              @click=${() => this.setPrevMonth()}
              ?hideText=${true}
              ?isDisabled="${!this.canChangeSubNavMonth()}"
            >
              ${this.previousButtonText}
              <slot slot="before">
                <${this.iconChevronLeftEl} size="lg"></${this.iconChevronLeftEl}>
              </slot>
            </${this.buttonEl}>
            <div class="en-c-calendar__header--text">${
              this.enableRangeSelection || !this.enableMonthAndYearSelection
                ? html`${format(this.navDate, 'MMMM yyyy')}`
                : html`
            <button class="en-c-calendar__month-selector-button" data-year="${format(this.navDate, 'y')}" id="${this.datepickerId}"
            aria-live="polite" @click=${() => this.toggleMonthPopup()}>
              ${format(this.navDate, 'MMMM yyyy')}
              <${this.iconChevronDownEl} class="en-c-calendar__month-selector-button-icon"></${this.iconChevronDownEl}>
            </button>
            `
            }</div>
            <${this.buttonEl}
              type="button"
              variant="quaternary"
              class=${classMap({ 'en-c-calendar__nav-btn': true, 'en-c-calendar__nav-btn--next': true, 'd-none': this.removeNextNavigation })}
              @click=${() => this.setNextMonth()}
              ?hideText=${true}
              ?isDisabled="${!this.canChangeAddNavMonth()}"
            >
              ${this.nextButtonText}
              <slot slot="after">
                <${this.iconChevronRightEl} size="lg"></${this.iconChevronRightEl}>
              </slot>
            </${this.buttonEl}>`
              : html`<div class="en-c-calendar__header--text">${
                  this.enableRangeSelection || !this.enableMonthAndYearSelection
                    ? html`${format(this.navDate, 'MMMM yyyy')}`
                    : html`
          <button class="en-c-calendar__month-selector-button" data-year="${format(this.navDate, 'y')}" id="${this.datepickerId}"
          aria-live="polite" @click=${() => this.toggleMonthPopup()}>
            ${format(this.navDate, 'MMMM yyyy')}
            <${this.iconChevronDownEl} class="en-c-calendar__month-selector-button-icon"></${this.iconChevronDownEl}>
          </button>
          `
                }</div>
          <div class="en-c-calendar__header--controls">
            <${this.buttonEl}
              type="button"
              variant="quaternary"
              class=${classMap({ 'en-c-calendar__nav-btn': true, 'd-none': this.removePrevNavigation })}
              @click=${() => this.setPrevMonth()}
              ?hideText=${true}
              ?isDisabled="${!this.canChangeSubNavMonth()}"
              
            >
              ${this.previousButtonText}
              <slot slot="before">
                <${this.iconChevronLeftEl} size="lg"></${this.iconChevronLeftEl}>
              </slot>
            </${this.buttonEl}>
            <${this.buttonEl}
              type="button"
              variant="quaternary"
              class=${classMap({ 'en-c-calendar__nav-btn': true, 'd-none': this.removeNextNavigation })}
              @click=${() => this.setNextMonth()}
              ?hideText=${true}
              ?isDisabled="${!this.canChangeAddNavMonth()}"
            >
              ${this.nextButtonText}
              <slot slot="after">
                <${this.iconChevronRightEl} size="lg"></${this.iconChevronRightEl}>
              </slot>
            </${this.buttonEl}>
          </div>`
          }
          
        </nav>

        <div class=${classMap({ 'en-c-calendar__table-container': true, 'en-show-footer': this.showFooter })}>
          <table role="presentation" class="en-c-calendar__table" cellspacing="0">
            <thead class="en-c-calendar__table-header">
              <tr class="en-c-calendar__table-row">
                ${this.setWeekdaysHeader().map((day: any) => {
                  return html` <th abbr="${day}" class="en-c-calendar__header-cell">${day}</th> `;
                })}
              </tr>
            </thead>
            <tbody class="en-c-calendar__table-body">
              ${this.days.map((week: any) => {
                return html`
                  <tr class="en-c-calendar__table-row">
                    ${week.map((day: any, index: number) => {
                      if (index > 0) {
                        lastDay = week[index - 1];
                      }
                      if (index < week.length - 1) {
                        nextDay = week[index + 1];
                      }
                      return html`
                        <td
                          class=${classMap({
                            'en-c-calendar__table-cell': true,
                            'en-is-start': this.isStartDay(day),
                            'en-is-end': this.isEndDay(day),
                            'en-is-between-start-end': this.isBetweenStartAndEndDay(day),
                            'en-show-last-day-gradient': this.isLastMonthDay(day, lastDay),
                            'en-show-first-day-gradient': this.isFirstMonthDay(day, nextDay)
                          })}
                        >
                          ${day.value !== null
                            ? html`
                                <button
                                  type="button"
                                  class=${classMap({
                                    'en-c-calendar__item': true,
                                    'en-is-active': this.setActive(day),
                                    'en-is-available': this.setAvailableDay(day.value),
                                    'en-is-today': this.setToday(day)
                                  })}
                                  aria-pressed=${ifDefined(this.setActive(day) ? true : undefined)}
                                  @click=${() => this.handleOnClickDay(day)}
                                  ?disabled=${!this.setAvailableDay(day.value)}
                                  aria-label="${format(day.value, this.fullDateFormat)}"
                                >
                                  ${format(day.value, 'd')}
                                </button>
                              `
                            : ''}
                        </td>
                      `;
                    })}
                  </tr>
                `;
              })}
            </tbody>
          </table>
          ${
            this.enableTimeSelection
              ? html`<div class="en-c-calendar__time-selector">
              <div class="en-c-calendar__time-selector--toggle">
                <label for="time-toggle-switch">${this.enableRangeSelection ? 'Start and End Time' : 'Time'}</label>
                <${this.switchEl} id="time-toggle-switch" .isChecked=${this.showTimeSelector} @changed=${this.handleTimeToggle} label="${this.enableRangeSelection ? 'Start and End Time' : 'Time'}" variant="primary" name="time-toggle"></${this.switchEl}>
              </div>
              ${
                this.showTimeSelector
                  ? html`<div class="en-c-calendar__time-selector--selector">
                    <${this.inlineTimeSelectorEl} .hideSecondsField=${this.hideSecondsField} direction=${this.timeSelectorDirection} variant=${this.timeSelectorVariant} dropdownAlign="top" label="${this.enableRangeSelection ? 'Start Time' : 'Select Time'}" datePlaceholder="${isValid(startDate) ? format(startDate, 'yyyy/MM/dd') : ''}" hours=${startTimes[0] || ''} minutes=${startTimes[1] || ''} seconds=${startTimes[2] || ''} @change=${(evt: CustomEvent) => this.handleTimeSelectorChange(evt, 'start')} ></${this.inlineTimeSelectorEl}>
                    ${this.enableRangeSelection ? html`<${this.inlineTimeSelectorEl} .hideSecondsField=${this.hideSecondsField} direction=${this.timeSelectorDirection} variant=${this.timeSelectorVariant} dropdownAlign="top" label="End Time" datePlaceholder="${isValid(endDate) ? format(endDate, 'yyyy/MM/dd') : ''}" hours=${endTimes[0] || ''} minutes=${endTimes[1] || ''} seconds=${endTimes[2] || ''} @change=${(evt: CustomEvent) => this.handleTimeSelectorChange(evt, 'end')} ></${this.inlineTimeSelectorEl}>` : html``}
          ${
            this.showTimezone
              ? html`<${this.timezoneDropdownEl} .showTimezoneSwitch=${this.showTimezoneSwitch} .setTimezoneSwitchOnAtInitialLoad=${this.setTimezoneSwitchOnAtInitialLoad} .setDefaultTimezoneToBrowserTimezone=${this.setDefaultTimezoneToBrowserTimezone}  .enableDynamicPositioning=${false} variant="${this.timeSelectorVariant}" dropdownAlign="top" timezone=${this.timezone ?? ''} @timezoneChange=${
                  this._handleTimezoneSelection
                }>
            </${this.timezoneDropdownEl}>`
              : html``
          }
              </div>`
                  : html``
              }
          </div>`
              : html``
          }
        </div>

        ${
          this.enableMonthAndYearSelection && !this.enableRangeSelection
            ? html`<div class="en-c-calendar__month-selector-popup" role="dialog" ?hidden="${!this.showMonthPopup}" @keydown=${this.handleOnKeydown}>
                <div class="en-c-month-selector">
                  <ul class="en-c-month-selector__list">
                    ${this.years.map((year: any) => {
                      return html`
                        <li class="en-c-month-selector__item" id="en-year-${format(year.value, 'y')}">
                          <h4 class="en-c-month-selector__year-heading">${format(year.value, 'y')}</h4>
                          <ul class="en-c-month-selector__sub-list">
                            ${year.month.map((month: any) => {
                              return html`
                                <li class="en-c-month-selector__sub-item">
                                  <button
                                    class="en-c-month-selector__button
                              ${isSameMonth(month, this.currentDate) ? 'en-is-current' : ''}
                              ${isEqual(startOfMonth(startOfDay(this.navDate)), startOfMonth(startOfDay(month))) ? 'en-is-active' : ''}"
                                    ?disabled=${!this.setAvailableMonth(month)}
                                    @click=${() => this.updateGrid(month)}
                                    aria-label="${format(month, 'MMMM, y')}"
                                  >
                                    <abbr title="${format(month, 'MMMM')}">${format(month, 'MMM')}</abbr>
                                  </button>
                                </li>
                              `;
                            })}
                          </ul>
                        </li>
                      `;
                    })}
                  </ul>
                </div>
              </div>`
            : html``
        }

        ${
          this.showFooter && !this.showMonthPopup
            ? html`<div class="en-c-calendar__footer">
              <${this.buttonEl} class="en-c-calendar__footer--left" variant="tertiary" @click=${this.handleReset} .assignThemeToTertiary=${true} ?isDisabled=${disableResetButton}>Reset to Default</${this.buttonEl}>
              ${!this.hideDone ? html`<${this.buttonEl} .isDisabled=${this.disableDoneButton} class="en-c-calendar__footer--right" variant="primary" @click=${this.handleDone}>Done</${this.buttonEl}>` : ``}
        </div>`
            : html``
        }
      </div>
    </div>
    </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENRangeCalendar.el) === undefined) {
  customElements.define(ENRangeCalendar.el, ENRangeCalendar);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-range-calendar': ENRangeCalendar;
  }
}
