import axios, { AxiosRequestConfig } from "axios"
import { CRAAS_BASE_URL } from "src/constants/apiUrlsCraas"
import { keysToCamelCase, keysToSnakeCase } from "src/utils"
import { UAA } from "src/utils/constants"
import { DEFAULT_ERROR_MESSAGE } from "src/utils/craasUserMessages"

const REQUEST_HEADERS = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  "Content-Type": "application/json",
  Accept: "application/json",
  UAA,
}

const config: AxiosRequestConfig = {
  baseURL: CRAAS_BASE_URL,
  headers: REQUEST_HEADERS,
}

export const craasAxios = axios.create(config)

/**
 * Intercepts the response from the axios request and converts the response data keys to camel case
 * if the response type is not "application/octet-stream".
 *
 * @param {object} response - The response object from the axios request.
 * @param {object} response.data - The data from the response object.
 * @param {string} response.data.type - The type of the response data.
 * @returns {object} - The modified response object with keys converted to camel case if applicable.
 */
craasAxios.interceptors.response.use((response) => {
  return response?.data?.type !== "application/octet-stream" ? keysToCamelCase(response) : response
})

/**
 * Handles error responses by converting the response data keys to camel case.
 *
 * @param {any} err - The error object that contains the response data.
 * @returns {any} - The response data with keys converted to camel case, or a default error message if no response data is present.
 */
const errorResponseHandler = (err: any) => {
  const responseData = err?.response?.data
  if (responseData) return keysToCamelCase(responseData)
  return DEFAULT_ERROR_MESSAGE
}

/**
 * Sends a POST request to the specified URL with the provided data.
 *
 * @param {Object} params - The parameters for the POST request.
 * @param {string} params.url - The URL to send the POST request to.
 * @param {any} [params.data] - The data to be sent in the POST request. If `isFormData` is true, this should be a FormData object.
 * @param {boolean} [params.isFormData] - Indicates whether the data is a FormData object. Defaults to false.
 * @returns {Promise<any>} - A promise that resolves with the response of the POST request.
 * @throws Will throw an error if the POST request fails.
 */
export const postRequest = async ({ url, data, isFormData }: { url: string; data?: any; isFormData?: boolean }) => {
  try {
    return await craasAxios.post(url, data ? (isFormData ? data : keysToSnakeCase(data)) : undefined)
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

/**
 * Sends an HTTP PUT request using the craasAxios instance.
 *
 * @param {Object} params - The parameters for the PUT request.
 * @param {string} params.url - The URL to send the PUT request to.
 * @param {any} [params.data] - The data to be sent in the PUT request body. Optional.
 * @param {Record<string, string>} [params.headers] - The headers to be included in the PUT request. Optional.
 * @param {"json" | "text" | "arraybuffer" | "blob" | "document" | "stream"} [params.responseType] - The type of response expected. Optional.
 * @returns {Promise<any>} - A promise that resolves to the response of the PUT request.
 * @throws Will throw an error if the request fails.
 */
export const putRequest = async ({
  url,
  data,
  headers = {},
  responseType,
}: {
  url: string
  data?: any
  headers?: Record<string, string>
  responseType?: "json" | "text" | "arraybuffer" | "blob" | "document" | "stream"
}) => {
  try {
    return await craasAxios.put(url, data ? keysToSnakeCase(data) : undefined, {
      ...(headers ? { headers } : {}),
      ...(responseType ? { responseType } : {}),
    })
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

/**
 * Sends a GET request using the craasAxios instance.
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} params.url - The URL to send the request to.
 * @param {Record<string, string>} [params.headers] - Optional headers to include in the request.
 * @param {"json" | "text" | "arraybuffer" | "blob" | "document" | "stream"} [params.responseType] - Optional response type for the request.
 * @returns {Promise<any>} The response from the GET request.
 * @throws Will throw an error if the request fails.
 */
export const getCraasRequest = async ({
  url,
  headers = {},
  responseType,
}: {
  url: string
  headers?: Record<string, string>
  responseType?: "json" | "text" | "arraybuffer" | "blob" | "document" | "stream"
}) => {
  try {
    return await craasAxios.get(url, {
      ...(headers ? { headers } : {}),
      ...(responseType ? { responseType } : {}),
    })
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

/**
 * Sends a DELETE request to the specified URL using the craasAxios instance.
 *
 * @param {Object} params - The parameters for the delete request.
 * @param {string} params.url - The URL to send the DELETE request to.
 * @returns {Promise<any>} - A promise that resolves with the response of the DELETE request.
 * @throws Will throw an error if the request fails, handled by errorResponseHandler.
 */
export const deleteRequest = async ({ url }: { url: string }) => {
  try {
    return await craasAxios.delete(url)
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

/**
 * Sends a PATCH request to the specified URL with the provided data.
 *
 * @param {Object} params - The parameters for the PATCH request.
 * @param {string} params.url - The URL to send the PATCH request to.
 * @param {any} [params.data] - The data to be sent with the PATCH request. If provided, the keys will be converted to snake_case.
 * @returns {Promise<any>} - A promise that resolves with the response of the PATCH request.
 * @throws Will throw an error if the request fails, handled by `errorResponseHandler`.
 */
export const patchRequest = async ({ url, data }: { url: string; data?: any }) => {
  try {
    return await craasAxios.patch(url, data ? keysToSnakeCase(data) : undefined)
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

/**
 * Creates a function that performs a POST request using the provided payload.
 *
 * @param payload - The data to be sent in the body of the POST request. It will be formatted to snake_case.
 * @returns A function that takes a URL and headers, and returns a Promise resolving to the response data.
 *
 * @throws Will throw an error if the POST request fails, handled by `errorResponseHandler`.
 */
export const postFetcher = (payload: any): ((url: string, headers: Record<string, string>) => Promise<any>) => {
  return async (url: string, headers: Record<string, string>): Promise<any> => {
    try {
      const formattedPayload = payload ? keysToSnakeCase(payload) : undefined
      const response = await craasAxios.post(url, formattedPayload, { headers })
      return response.data
    } catch (err) {
      throw errorResponseHandler(err)
    }
  }
}
