import clsx from "clsx"
import { ENDropdown } from "en-react/dist/src/components/Dropdown"
import { ENList } from "en-react/dist/src/components/List"
import { ENListItem } from "en-react/dist/src/components/ListItem"
import { ENDropdown as DropdownType } from "en-web-components/dist/en/components/dropdown/dropdown"
import { PartialDataSource } from "en-web-components/dist/en/components/dropdown/dropdown.model"
import { useEffect, useRef, useState } from "react"
import { ZtnaSelectType } from "../FormComponents.types"
import { useEnSelectStyles } from "./EnSelect.styles"

const EnSelect: React.FC<ZtnaSelectType> = ({
  id,
  label,
  value = "",
  isSearchable = false,
  options = [],
  error,
  isError,
  onChange,
  onClearDropdown = () => onChange(null),
  placeholder = "Select",
  disabled = false,
  optionValue = "value",
  optionLabel = "label",
  isLoading = false,
  isClearable = false,
  isOptional = false,
  customOption = null,
  fullObjectSelection = false,
  menuPosition = "fixed",
  styles,
  className,
  menuRenderer,
  menuIsOpen,
  setMenuIsOpen,
  minSelectHeight,
  objectName = "",
  objectCreationFunction = () => {},
  fieldNote = "",
  verticalPosition = "bottom",
  enableDynamicPositioning = false,
  noLabelOnFocused = false,
  ...extraProps
}): JSX.Element => {
  const classes = useEnSelectStyles()

  const dropdownRef = useRef<DropdownType | null>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [selectedValue, setSelectedValue] = useState<undefined | string>(undefined)

  const onOptionChange = (selectedItem: PartialDataSource) => {
    const selected = {
      [optionValue]: !isNaN(Number(selectedItem.value)) ? Number(selectedItem.value) : selectedItem.value,
      [optionLabel]: selectedItem.label,
    }

    if (selected?.[optionValue] === `create-new-${objectName}-btn`) {
      objectCreationFunction()
      return
    }

    if (selected || selected === null) {
      if (fullObjectSelection) onChange(selected)
      else {
        const selectedValue = selected ? selected[optionValue] : null
        onChange(selectedValue)
      }
    }
  }

  const formattedOptions: PartialDataSource[] = objectName
    ? [
        {
          label: `Create New ${objectName}`,
          value: `create-new-${objectName}-btn`,
        },
        ...options.map((option) => ({ label: `${option?.[optionLabel]}`, value: `${option?.[optionValue]}` })),
      ]
    : options.map((option) => ({ label: `${option?.[optionLabel]}`, value: `${option?.[optionValue]}` }))

  useEffect(() => {
    setSelectedValue(
      fullObjectSelection
        ? formattedOptions.find((option) => `${value?.[optionValue]}` === option?.value)?.value
        : formattedOptions.find((option) => `${value}` === option?.value)?.value,
    )

    const searchInputValue = dropdownRef.current?.searchInputValue
    if (searchInputValue) {
      dropdownRef.current?.handleSearchFormChange({ detail: { value: dropdownRef.current?.searchInputValue } })
    }
  }, [value, formattedOptions])

  return (
    <ENDropdown
      ref={dropdownRef}
      fieldId={id}
      label={label}
      hideLabel={((isFocused || selectedValue) && noLabelOnFocused) || !label}
      hasSearch={isSearchable}
      isDisabled={disabled}
      // isLoading={isLoading}
      placeholder={placeholder}
      dataSource={formattedOptions}
      value={selectedValue ?? ""}
      fieldNote={fieldNote}
      isError={!!error}
      errorNote={error}
      onClick={(e) => {
        if ((e.target as HTMLSelectElement).localName?.includes("en-list-item")) {
          onOptionChange({
            value: (e.target as HTMLSelectElement).value,
            label: (e.target as HTMLSelectElement).innerText,
          })
        }
      }}
      isOptional={isFocused && isOptional}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      enableClearSelection={isClearable}
      onClearDropdown={onClearDropdown}
      dropdownPanelContainerSelector="#network-service-group-table-dropdown"
      align={verticalPosition}
      enableDynamicPositioning={enableDynamicPositioning}
      data-testid={`en-select-${id}`}
    >
      <ENList>
        {/* TODO: Styles need to be added for this first item */}
        {formattedOptions.length === 0 && <p className={classes.noOptionText}>No options available</p>}

        {/* <Virtuoso
          data={formattedOptions}
          totalCount={formattedOptions.length}
          style={{ height: "calc(var(--size-base-unit) * 18)" }}
          itemContent={(index, option) =>
            !customOption ? (
              <ENListItem
                className={clsx({ [classes.creationBtn]: option.value === `create-new-${objectName}-btn` })}
                key={index}
                value={option?.value as any}
                isActive={selectedValue === option.value}
                onClick={() => {
                  onOptionChange({ value: option?.[optionValue], label: option?.[optionLabel] as string })
                }}
              >
                {option.label}
              </ENListItem>
            ) : (
              <ENListItem
                className={clsx({ [classes.creationBtn]: option.value === `create-new-${objectName}-btn` })}
                key={(option?.[optionValue] || index) as string}
                value={(option?.[optionValue] || index) as string}
                isActive={selectedValue === option?.[optionValue]}
                onClick={() => {
                  onOptionChange({ value: option?.[optionValue], label: option?.[optionLabel] as string })
                }}
              >
                {customOption?.(option)}
              </ENListItem>
            )
          }
        /> */}
        {customOption
          ? options.map((option, index) => {
              return (
                <ENListItem
                  className={clsx({ [classes.creationBtn]: option.value === `create-new-${objectName}-btn` })}
                  key={(option?.[optionValue] || index) as string}
                  value={(option?.[optionValue] || index) as string}
                  isActive={selectedValue === option?.[optionValue]}
                  // onClick={() => {
                  //   console.log(option)
                  //   // onOptionChange({ value: option?.[optionValue], label: option?.[optionLabel] as string })
                  // }}
                >
                  {customOption(option)}
                </ENListItem>
              )
            })
          : formattedOptions.map((option) => (
              <ENListItem
                className={clsx({ [classes.creationBtn]: option.value === `create-new-${objectName}-btn` })}
                key={option.value}
                value={option.value}
                isActive={selectedValue === option.value}
              >
                {option.label}
              </ENListItem>
            ))}
      </ENList>
    </ENDropdown>
  )
}

export default EnSelect
