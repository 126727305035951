import { ACCOUNT_SETTINGS, TENANT_ADMIN_BASE_URL } from "src/constants"
import { RoleValueType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { API_URL, REQUEST_HEADERS } from "src/services"
import useSWR from "swr"

type DateTimeFormatPayload = {
  dateFormat: string
  timeFormat: string
}

type UseDateTimeFormatType = {
  data?: DateTimeFormatPayload
  isLoading: boolean
  error: any
  isValidating: boolean
  getDateTimeFormat: () => void
}

/**
 * Custom hook to fetch the date and time format settings based on the user role.
 *
 * @param {RoleValueType} role - The role of the user which determines the API endpoint.
 *
 * @returns {UseDateTimeFormatType} - An object containing the data, loading state, error,
 * validation state, and a function to refresh the date-time format settings.
 */
const useDateTimeFormat = (role: RoleValueType): UseDateTimeFormatType => {
  const url = isTenantRole(role)
    ? `${API_URL}${TENANT_ADMIN_BASE_URL}${ACCOUNT_SETTINGS}`
    : `${API_URL}${ACCOUNT_SETTINGS}`

  const { data, error, mutate, isValidating } = useSWR([url, REQUEST_HEADERS])

  const payload = data?.payload

  return {
    data: payload || undefined,
    isLoading: !error && !data,
    error,
    getDateTimeFormat: mutate,
    isValidating,
  }
}

export default useDateTimeFormat
