import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ENElement } from '../ENElement';
import styles from './divider.scss';

/**
 * Component: en-divider
 * - A divider is a thin line that groups content in lists and layouts.
 */
export class ENDivider extends ENElement {
  static el = 'en-divider';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * If true, divider shown will be dashed line. Default is false.
   */
  @property({ type: Boolean })
  isDashedLine?: boolean = false;

  /**
   * Variant
   * - **default** A divider the displays horizontally
   * - **vertical** A divider the displays vertically
   */
  @property()
  variant?: 'vertical';

  render() {
    const componentClassNames = this.componentClassNames('en-c-divider', {
      'en-c-divider--vertical': this.variant === 'vertical',
      'en-c-divider--dashed': this.isDashedLine
    });

    return html` <hr class="${componentClassNames}" /> `;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENDivider.el) === undefined) {
  customElements.define(ENDivider.el, ENDivider);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-divider': ENDivider;
  }
}
