import { END_APPLICATION_TROUBLESHOOTING, START_APPLICATION_TROUBLESHOOTING } from "src/constants"
import { patchRequest, postRequest } from "src/services"

/**
 * Initiates the application troubleshooting process for a given evaluation.
 *
 * This function sends a POST request to start the troubleshooting process
 * for the application associated with the provided evaluation ID. The status
 * of the troubleshooting process is set to "IN_PROGRESS".
 *
 * @param {string} evaluationId - The unique identifier of the evaluation for which troubleshooting is to be started.
 * @returns {Promise<void>} A promise that resolves when the request is complete.
 */
export const startApplicationTroubleshooting = async (evaluationId: string) => {
  await postRequest(START_APPLICATION_TROUBLESHOOTING(evaluationId), {
    status: "IN_PROGRESS",
  })
}

/**
 * Ends the application troubleshooting process by updating the status to "TERMINATED".
 *
 * @param {string} evaluationId - The unique identifier of the evaluation to be terminated.
 * @returns {Promise<void>} - A promise that resolves when the request is complete.
 */
export const endApplicationTroubleshooting = async (evaluationId: string) => {
  await patchRequest(END_APPLICATION_TROUBLESHOOTING(evaluationId), {
    status: "TERMINATED",
  })
}
