import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ENElement } from '../ENElement';
import styles from './heading.scss';

/**
 * Component: en-heading
 * - The heading is used to render semenatic heading tags with specific styles.
 * @slot - The components content
 * @slot "prefix" - Slot to override the default icon
 */
export class ENHeading extends ENElement {
  static el = 'en-heading';

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Heading variants
   * - **default** renders a heading with the XL font styles
   * - **sm** renders a heading with the SM font styles
   * - **md** renders a heading with the MD font styles
   * - **lg** renders a heading with the LG font styles
   * - **display-sm** renders a heading with the Display SM font styles
   * - **display-md** renders a heading with the Display MD font styles
   * - **display-lg** renders a heading with the Display LG font styles
   */
  @property()
  variant?: 'display-lg' | 'display-md' | 'display-sm' | 'lg' | 'md' | 'sm';

  /**
   * Heading tag name
   * - **h1** renders an `h1` tag
   * - **h2** renders an `h2` tag, this is the default
   * - **h3** renders an `h3` tag
   * - **h4** renders an `h4` tag
   * - **h5** renders an `h5` tag
   * - **h6** renders an `h6` tag
   */
  @property()
  tagName: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h2';

  /**
   * Is bold?
   * - **true** Renders the heading with semibold font weight
   * - **false** Renders the heading with regular font weight
   */
  @property({ type: Boolean })
  isBold?: boolean;

  /**
   * has Prefix Icon?
   * - **true** Renders the heading with default Prefix Icon
   * - **false** Renders the heading without default Prefix Icon
   */
  @property({ type: Boolean })
  hasPrefixIcon?: boolean = false;

  render() {
    const componentClassNames = this.componentClassNames('en-c-heading', {
      'en-c-heading--sm': this.variant === 'sm',
      'en-c-heading--md': this.variant === 'md',
      'en-c-heading--lg': this.variant === 'lg',
      'en-c-heading--display-sm': this.variant === 'display-sm',
      'en-c-heading--display-md': this.variant === 'display-md',
      'en-c-heading--display-lg': this.variant === 'display-lg',
      'en-is-bold': this.isBold
    });
    switch (this.tagName) {
      case 'h1':
        return html`<h1 class="${componentClassNames}"><slot></slot></h1>`;
      case 'h2':
        if (this.hasPrefixIcon) {
          return html`
            <h2 class="${componentClassNames}">
              <div class="en-c-heading--contain-icon">
                 <en-icon-emoji size="md"></en-icon-emoji>
                <slot></slot>
              </div>
            </h2>`;
        }
        return html`
          ${this.slotNotEmpty('prefix') ?
            html`<h2 class="${componentClassNames}"><slot name="prefix"></slot><slot></slot></h2>` :
            html`<h2 class="${componentClassNames}"><slot></slot></h2>`}`;
      case 'h3':
        return html`<h3 class="${componentClassNames}"><slot></slot></h3>`;
      case 'h4':
        return html`<h4 class="${componentClassNames}"><slot></slot></h4>`;
      case 'h5':
        return html`<h5 class="${componentClassNames}"><slot></slot></h5>`;
      case 'h6':
        return html`<h6 class="${componentClassNames}"><slot></slot></h6>`;
    }
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENHeading.el) === undefined) {
  customElements.define(ENHeading.el, ENHeading);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-heading': ENHeading;
  }
}
