import useAppManifest from "src/services/api/swrHooks/useAppManifest"
import Modal from "src/shared/components/Modal"
import { COMPANY_NAME } from "src/utils/constants"
import { useAboutUztnaModalStyles } from "./AboutUZTNA.styles"

type AboutUZTNAModalProps = {
  onModalClose: () => void
}

export const AboutUZTNAModal: React.FC<AboutUZTNAModalProps> = ({ onModalClose }): JSX.Element => {
  const classes = useAboutUztnaModalStyles()

  const currentTime = new Date()
  const { data } = useAppManifest()

  return (
    <Modal
      title={"About Universal ZTNA"}
      onOk={onModalClose}
      onCancel={onModalClose}
      onCancelProps={{ visible: false }}
      onOkProps={{
        title: "Close",
      }}
      width={432}
    >
      <div>
        <div className={classes.content}>
          <div className={classes.boldContent}>Release Version</div>
          <div className={classes.text}>{data?.version === "APP_VERSION" ? "24.2.3.163" : data?.version}</div>
        </div>
        <div className={classes.text}>
          © Copyright {currentTime.getFullYear()} {COMPANY_NAME} - All rights reserved.
        </div>
      </div>
    </Modal>
  )
}
