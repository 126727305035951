import {
  CHECK_FOR_FORCE_LOGOUT,
  CHECK_FOR_VALID_SESSION_END_USER,
  GOOGLE_AUTH_URL_LOGIN,
  GOOGLE_AUTH_URL_SIGNUP_INVITE,
  LOGIN_URL,
  LOGOUT_URL,
  MICROSOFT_OIDC_AUTH_URL_LOGIN,
  MICROSOFT_OIDC_AUTH_URL_SIGNUP_INVITE,
  SAML_AUTH_URL_LOGIN,
  SAML_AUTH_URL_SIGNUP_INVITE,
  TENANT_ADMIN_BASE_URL,
  XIQ_LOGOUT_URL,
} from "src/constants"
import { Roles, RoleValueType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { getRequest, postRequest } from "src/services"

type LoginResponseType = {
  loginUrl: string
}
type LoginPayloadType = {
  email?: string
  return_url?: string | null
  UAAV?: string
  UAA?: string
  platform?: string | null
  auth_token?: string
}
export const parseUserData = ({
  email,
  role,
  firstName,
  lastName,
  sessionId,
  workspaceUrl,
  workspaceId,
  userFirstLogin,
  id,
}: {
  email: string
  role: { name: string; code: string }
  firstName: string
  lastName: string
  sessionId: string
  workspaceUrl: string
  workspaceId: number
  userFirstLogin: boolean
  id?: number
}): any => {
  return {
    email,
    role: role.code,
    firstName,
    lastName,
    sessionId,
    workspaceUrl,
    workspaceId,
    userFirstLogin,
    id,
  }
}

/**
 * Sets a cookie with the specified name, value, and expiration days.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} days - The number of days until the cookie expires. If not provided, the cookie will be a session cookie.
 */
function setCookie(name: string, value: string, days: number) {
  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

/**
 * Authenticates a user with the provided credentials and returns user details.
 *
 * @param body - The user credentials.
 * @param body.email - The email of the user.
 * @param body.password - The password of the user.
 * @param body.userAuthenticationCode - Optional user authentication code.
 * @param isZTNAAdmin - A boolean indicating if the user is a ZTNA admin.
 * @returns A promise that resolves to an object containing user details.
 * @property id - The ID of the user.
 * @property email - The email of the user.
 * @property role - The role of the user.
 * @property firstName - The first name of the user.
 * @property lastName - The last name of the user.
 * @property sessionId - The session ID of the user.
 * @property workspaceUrl - The workspace URL of the user.
 * @property workspaceId - The workspace ID of the user.
 * @property userFirstLogin - A boolean indicating if this is the user's first login.
 */
export const authenticateUser = async (
  body: {
    email: string
    password: string
    userAuthenticationCode?: string
  },
  isZTNAAdmin: boolean,
): Promise<{
  id: number | undefined
  email: string
  role: RoleValueType
  firstName: string
  lastName: string
  sessionId: string
  workspaceUrl: string
  workspaceId: number
  userFirstLogin: boolean
}> => {
  const url = window.location.pathname.includes("/zta") ? `${TENANT_ADMIN_BASE_URL}${LOGIN_URL}` : LOGIN_URL
  const resp = await postRequest(url, {
    email: body.email,
    password: body.password,
    is_ztna_admin: isZTNAAdmin,
    user_authentication_code: body?.userAuthenticationCode,
  })

  if (process.env.NODE_ENV !== "production") {
    setCookie("sessionid", resp.data.payload.sessionId, 1)
  }

  if (resp?.data?.payload?.workspace?.id) localStorage.removeItem("firstTimeLogin")
  else localStorage.setItem("firstTimeLogin", "true")

  return {
    ...parseUserData(resp.data.payload),
    userFirstLogin: resp.data?.payload?.workspace?.id ? true : false,
  }
}

/**
 * Logs out the user based on their role.
 *
 * @param {RoleValueType} role - The role of the user to be logged out.
 * @returns {Promise<{ status: boolean }>} - A promise that resolves to an object containing the status of the logout operation.
 *
 * The function constructs a logout URL based on the user's role. If the role is a tenant role, it uses the URL stored in localStorage.
 * Otherwise, it uses a predefined LOGOUT_URL and appends a query parameter indicating if the user is an end user.
 * It then makes a GET request to the constructed URL and returns the status of the logout operation.
 */
export const logoutUser = async (role: RoleValueType): Promise<{ status: boolean }> => {
  let url = ""
  if (isTenantRole(role)) {
    url = url + `${localStorage.getItem("xcdUrl")}/oauth/logout`
  } else {
    url = url + `${LOGOUT_URL}?is_end_user=${role === Roles.END_USER || undefined}`
  }

  const resp = await getRequest(url, undefined, isTenantRole(role) ? false : true)
  return {
    status: resp.data.status,
  }
}

/**
 * Logs out the end user by sending a GET request to the logout URL.
 *
 * @returns {Promise<{ status: boolean }>} A promise that resolves to an object containing the status of the logout operation.
 */
export const logoutEndUserOnly = async (): Promise<{ status: boolean }> => {
  const resp = await getRequest(LOGOUT_URL)
  return {
    status: resp.data.status,
  }
}

/**
 * Logs out the SSO user by sending a GET request to the specified logout URL.
 *
 * @returns {Promise<{ status: boolean }>} A promise that resolves to an object containing the status of the logout operation.
 */
export const logoutSsoUser = async (): Promise<{ status: boolean }> => {
  const resp = await getRequest(XIQ_LOGOUT_URL)
  return {
    status: resp.data.status,
  }
}

/**
 * Checks if the user should be forcefully logged out based on their session ID.
 *
 * This function retrieves the user's role from local storage and constructs the appropriate URL
 * to check for a forced logout. It then makes a GET request to that URL.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.sessionId - The session ID of the user.
 * @returns {Promise<void>} A promise that resolves when the check is complete.
 */
export const checkForForceLogout = async ({ sessionId }: { sessionId: string }): Promise<void> => {
  const { role } = JSON.parse(localStorage.getItem("userData") || "{}")
  const url = isTenantRole(role)
    ? `${TENANT_ADMIN_BASE_URL}${CHECK_FOR_FORCE_LOGOUT(sessionId)}`
    : CHECK_FOR_FORCE_LOGOUT(sessionId)
  await getRequest(url)
}

/**
 * Checks for a valid session by making an asynchronous GET request to the
 * `CHECK_FOR_VALID_SESSION_END_USER` endpoint.
 *
 * @returns {Promise<void>} A promise that resolves when the request is complete.
 */
export const checkForValidSession = async (): Promise<void> => {
  await getRequest(CHECK_FOR_VALID_SESSION_END_USER)
}

/**
 * Signs in a user with Google authentication.
 *
 * @param {Object} params - The parameters for the sign-in request.
 * @param {string} params.email - The email of the user.
 * @param {string} params.return_url - The URL to return to after authentication.
 * @param {string} params.UAAV - User Agent Application Version.
 * @param {string} params.UAA - User Agent Application.
 * @param {string} params.platform - The platform from which the request is made.
 * @returns {Promise<LoginResponseType>} A promise that resolves to the login response.
 */
export const signInWithGoogle = async ({
  email,
  return_url,
  UAAV,
  UAA,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(GOOGLE_AUTH_URL_LOGIN, { email, return_url, UAAV, UAA, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

/**
 * Signs in a user with Microsoft using the provided login payload.
 *
 * @param {Object} params - The login payload.
 * @param {string} params.email - The email of the user.
 * @param {string} params.return_url - The URL to return to after login.
 * @param {string} params.UAAV - User agent application version.
 * @param {string} params.UAA - User agent application.
 * @param {string} params.platform - The platform being used.
 * @returns {Promise<LoginResponseType>} A promise that resolves to the login response containing the login URL.
 */
export const signInWithMicrosoft = async ({
  email,
  return_url,
  UAAV,
  UAA,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(MICROSOFT_OIDC_AUTH_URL_LOGIN, { email, return_url, UAAV, UAA, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

/**
 * Signs in a user using SAML authentication.
 *
 * @param {Object} params - The login parameters.
 * @param {string} params.email - The email of the user.
 * @param {string} params.return_url - The URL to return to after authentication.
 * @param {string} params.UAAV - The User Agent Application Version.
 * @param {string} params.UAA - The User Agent Application.
 * @param {string} params.platform - The platform being used.
 * @returns {Promise<LoginResponseType>} A promise that resolves to the login response containing the login URL.
 */
export const signInWithSAML = async ({
  email,
  return_url,
  UAAV,
  UAA,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(SAML_AUTH_URL_LOGIN, { email, return_url, UAAV, UAA, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

/**
 * Signs up a user with SAML authentication.
 *
 * @param {Object} params - The parameters for the sign-up request.
 * @param {string} params.email - The email address of the user.
 * @param {string} params.UAA - The UAA (User Authentication Assertion) token.
 * @param {string} params.UAAV - The UAAV (User Authentication Assertion Version) token.
 * @param {string} params.auth_token - The authentication token.
 * @param {string} params.platform - The platform identifier.
 * @returns {Promise<LoginResponseType>} A promise that resolves to the login response containing the login URL.
 */
export const signUpWithSAML = async ({
  email,
  UAA,
  UAAV,
  auth_token,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(SAML_AUTH_URL_SIGNUP_INVITE, { email, UAA, UAAV, auth_token, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

/**
 * Signs up a user with Microsoft using the provided payload.
 *
 * @param {Object} payload - The payload for the sign-up request.
 * @param {string} payload.email - The email address of the user.
 * @param {string} payload.UAA - The UAA (User Authentication Assertion) token.
 * @param {string} payload.UAAV - The UAAV (User Authentication Assertion Version) token.
 * @param {string} payload.auth_token - The authentication token.
 * @param {string} payload.platform - The platform from which the request is made.
 * @returns {Promise<LoginResponseType>} A promise that resolves to the login response containing the login URL.
 */
export const signUpWithMicrosoft = async ({
  email,
  UAA,
  UAAV,
  auth_token,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(MICROSOFT_OIDC_AUTH_URL_SIGNUP_INVITE, { email, UAA, UAAV, auth_token, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

/**
 * Signs up a user with Google using the provided login payload.
 *
 * @param {Object} params - The login payload.
 * @param {string} params.email - The email of the user.
 * @param {string} params.UAA - The UAA token.
 * @param {string} params.UAAV - The UAAV token.
 * @param {string} params.auth_token - The authentication token.
 * @param {string} params.platform - The platform information.
 * @returns {Promise<LoginResponseType>} A promise that resolves to the login response containing the login URL.
 */
export const signUpWithGoogle = async ({
  email,
  UAA,
  UAAV,
  auth_token,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(GOOGLE_AUTH_URL_SIGNUP_INVITE, { email, UAA, UAAV, auth_token, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}
