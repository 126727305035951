import { ChangeEvent, DragEventHandler, useCallback, useState } from "react"

/**
 * Custom hook to handle drag-and-drop file input functionality.
 *
 * @returns {Object} An object containing:
 * - `value` {FileList | null}: The current list of files.
 * - `set` {React.Dispatch<React.SetStateAction<FileList | null>>}: Function to manually set the file list.
 * - `isDragOver` {boolean}: Boolean indicating if a drag event is currently happening over the target element.
 * - `onChange` {(e: ChangeEvent<HTMLInputElement>) => void}: Event handler for file input change events.
 * - `events` {Object}: An object containing drag-and-drop event handlers:
 *   - `onDragOver` {DragEventHandler<HTMLElement>}: Event handler for drag over events.
 *   - `onDragLeave` {DragEventHandler<HTMLElement>}: Event handler for drag leave events.
 *   - `onDrop` {DragEventHandler<HTMLElement>}: Event handler for drop events.
 */
const useDragAndDrop = () => {
  const [value, set] = useState<FileList | null>(null)
  const [isDragOver, setIsDragOver] = useState(false)

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const file = e.target.files
      set(file)
    }
  }, [])

  const onDragOver: DragEventHandler<HTMLElement> = useCallback((e) => {
    e.preventDefault()
    setIsDragOver(true)
  }, [])

  const onDragLeave: DragEventHandler<HTMLElement> = useCallback(() => {
    setIsDragOver(false)
  }, [])

  const onDrop: DragEventHandler<HTMLElement> = useCallback((e) => {
    e.preventDefault()
    const droppedFile = e?.dataTransfer?.files
    set(droppedFile || null)
    setIsDragOver(false)
  }, [])

  return { value, set, isDragOver, onChange, events: { onDragOver, onDragLeave, onDrop } }
}

export default useDragAndDrop
