import { useEffect, useRef } from "react"

import clsx from "clsx"

import { ENButton } from "en-react/dist/src/components/Button"
import { ENFieldNote } from "en-react/dist/src/components/FieldNote"
import { ENHeading } from "en-react/dist/src/components/Heading"
import { ENIconClose } from "en-react/dist/src/components/Icons/Close"
import { ENIconDocument } from "en-react/dist/src/components/Icons/Document"
import useDragAndDrop from "src/hooks/useDragAndDrop"
import { useDragAndDropStyles } from "./DragAndDrop.styles"

type Props = {
  id: string
  name: string
  value?: any
  onChange: (e: FileList | null) => void
  error: string
  supportedFiles: string
  variant?: "elevation1" | "elevation2"
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  fileType?: string
}

const DragAndDrop = (props: Props) => {
  const {
    id,
    name,
    onChange,
    error,
    supportedFiles,
    variant = "elevation1",
    value: externalValue,
    fileType = "",
    ...rest
  } = props
  const classes = useDragAndDropStyles({ variant })
  const { value, set, isDragOver, events, onChange: onFileChange } = useDragAndDrop()
  const fileInputRef = useRef<any>()

  useEffect(() => {
    if (value) {
      onChange(value)
    }
  }, [value])

  const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const input = event.target as HTMLInputElement
    input.value = "" // clear the value so that the same file can be selected again if needed
  }

  const getBrowseButton = (label: string) => (
    <>
      <input
        accept={supportedFiles}
        id={id}
        name={name}
        data-testid="import-zip-input"
        className={classes.fileInput}
        type="file"
        onChange={onFileChange}
        onClick={handleFileInputClick}
        ref={fileInputRef}
        {...rest}
      />
      <ENButton onClick={() => fileInputRef.current.click()}>{label}</ENButton>
    </>
  )

  return (
    <>
      {!value && !externalValue ? (
        <div
          className={clsx(
            classes.fileWrapper,
            { [classes.errorContainer]: !!error },
            { [classes.dragOver]: isDragOver },
          )}
          {...events}
        >
          <div className={classes.file}>
            <ENIconDocument size="xl" />
            <div className={classes.textAlignCenter}>
              <ENHeading isBold>
                <p className={classes.greyColor}>{`Drag and Drop ${fileType ? `${fileType} ` : ""}File Here`}</p>
              </ENHeading>
              <ENHeading>
                <p className={classes.greyColor}>or</p>
              </ENHeading>
            </div>
            {getBrowseButton("Browse Files")}
            <ENFieldNote>Supported Format: {supportedFiles}</ENFieldNote>
            {error && <ENFieldNote isError>{error}</ENFieldNote>}
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.fileNameWrapper}>
            <ENIconDocument size="md" />
            <p className={classes.fileName}>{externalValue || value?.[0]?.name}</p>
            <ENButton
              variant="tertiary"
              className={classes.dismissFileButton}
              onClick={() => {
                set(null)
                onChange?.(null)
              }}
            >
              <ENIconClose size="md" />
            </ENButton>
          </div>
          {error && (
            <div className={classes.errorNote}>
              <ENFieldNote isError>{error}</ENFieldNote>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default DragAndDrop
