import { ENProgress } from "en-react/dist/src/components/Progress"
import { memo } from "react"
import { useCircularLoaderStyles } from "./CircularLoader.styles"

type Props = {
  isDark?: boolean
  size?: "md" | "lg" | "xl"
}

const CircularLoader = (props: Props) => {
  const { isDark = false, size = "md" } = props
  const classes = useCircularLoaderStyles()
  return (
    <div className={classes.animation}>
      <ENProgress
        circleSize={size}
        currentProgress={100}
        endProgress={85}
        duration={1}
        isDarkContrast={isDark}
        isCircle
      />
    </div>
  )
}

export default memo(CircularLoader)
