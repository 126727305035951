import { ENTooltip } from "en-react/dist/src/components/Tooltip"
import { ENTooltip as TooltipType } from "en-web-components/dist/en/components/tooltip/tooltip"
import { ReactNode, useEffect, useRef } from "react"

interface ZtnaTooltipProps {
  children: ReactNode
  title: ReactNode
  classes?: any
  prefix?: string
  placement?: "left" | "right" | "top" | "topLeft" | "topRight" | "bottom" | "bottomLeft" | "bottomRight"
  isInteractive?: boolean
  arrow?: boolean
  open?: boolean
  shouldLineBreak?: boolean
  enableTextWrap?: boolean
  customStyle?: Object
  cssPosition?: "absolute" | "fixed"
  fullWidthContainer?: boolean
}

const ZtnaTooltip: React.FC<React.PropsWithChildren<ZtnaTooltipProps>> = ({
  children,
  title,
  prefix,
  placement = "top",
  isInteractive = false,
  arrow = true,
  open = false,
  shouldLineBreak = true,
  enableTextWrap = true,
  customStyle,
  cssPosition = "fixed",
  fullWidthContainer = false,
}) => {
  const tooltipRef = useRef<TooltipType | null>(null)
  useEffect(() => {
    setTimeout(() => {
      if (fullWidthContainer && tooltipRef.current?.tooltipTrigger) {
        tooltipRef.current.tooltipTrigger.style.width = "100%"
      }
    })
  }, [])
  if (!title) {
    return <>{children}</>
  }

  return (
    <ENTooltip
      ref={tooltipRef}
      position={placement}
      isInteractive={isInteractive}
      hasArrow={arrow}
      isActive={open}
      style={{ display: "flex", ...customStyle }}
      styleModifier="en-u-width-100"
      enableTextWrap={enableTextWrap}
      cssPosition={cssPosition}
    >
      <>
        <div slot="trigger">{children}</div>
        {prefix && <span slot="prefix">{prefix}</span>}
        <div style={shouldLineBreak ? { lineBreak: "normal" } : {}}>{title}</div>
      </>
    </ENTooltip>
  )
}

export default ZtnaTooltip
