import { PROFILE_IMAGE } from "src/constants"
import { RoleValueType } from "src/routes/config"
import { Roles } from "src/routes/config/Roles"
import { API_URL, REQUEST_HEADERS } from "src/services"
import { getExternalLogoutUrl } from "src/utils"
import useSWR from "swr"

type UseProfileImageResponseType = {
  data?: {
    profileImage: string
  }
  isLoading: boolean
  error: any
  isValidating: boolean
  getProfileImage: () => void
}

const fetchWithCredentials = async (url: string, isEndUser: boolean = false) => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    headers: isEndUser
      ? REQUEST_HEADERS
      : {
          accept: "image/png",
        },
  })

  const contentType = response.headers.get("Content-Type")

  if (contentType?.includes("application/json")) {
    return response.json() // If response is JSON, return JSON
  } else if (contentType?.includes("image")) {
    const blob = await response.blob() // Convert image response to Blob
    return { payload: URL.createObjectURL(blob) } // Create an object URL for the image
  }

  throw new Error("Unsupported response type")
}
/**
 * Custom hook to fetch the profile image URL based on the user's role.
 *
 * This hook uses SWR to fetch the profile image URL based on the user's role, returning the image data,
 * loading status, error, validation status, and a function to refresh the profile image.
 *
 * @param {RoleValueType} role - The user's role, which determines the API endpoint to call.
 *
 * @returns {UseProfileImageResponseType} The profile image data, loading, error, and validation states.
 */
const useProfileImage = (role: RoleValueType): UseProfileImageResponseType => {
  const isEndUser = role === Roles.END_USER

  const url = isEndUser
    ? `${API_URL}${PROFILE_IMAGE}`
    : `${getExternalLogoutUrl()}/iam-service/myaccount/avatar?size=SMALL`

  const { data, error, mutate, isValidating } = useSWR([url, isEndUser], fetchWithCredentials)

  return {
    data: { profileImage: isEndUser ? data?.payload?.profile_image : data?.payload || "" },
    isLoading: !error && !data,
    error,
    getProfileImage: mutate,
    isValidating,
  }
}

export default useProfileImage
