import { useEffect, useState } from "react"

/**
 * Custom hook that debounces a value. The debounced value will only update after the specified delay has passed
 * since the last time the value was changed.
 *
 * @param value - The value to debounce.
 * @param delay - The delay in milliseconds to wait before updating the debounced value. Defaults to 500ms if not provided.
 * @returns The debounced value.
 */
const useDebounce = (value: any, delay?: number): any => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay || 500)

    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export default useDebounce
