import clsx from "clsx"
import { ENRadio } from "en-react/dist/src/components/Radio"
import { ENRadioItem } from "en-react/dist/src/components/RadioItem"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { Fragment } from "react"
import ZtnaIcon from "../../Icons/ZtnaIcon"
import ZtnaTooltip from "../../ZtnaTooltip"
import { useRadioGroupStyles } from "./EnRadioGroup.styles"

type RadioItemType = {
  id: string
  label: string
  value: string
  infoIconTooltip?: string
}

interface RadioGroupProps {
  label?: string
  className?: string
  name: string
  value: string
  onChange: (option: string) => void
  options: RadioItemType[]
  isDisabled?: boolean
}

const EnRadioGroup = (props: RadioGroupProps) => {
  const { label, name, value, onChange, options, className = "", isDisabled = false } = props
  const classes = useRadioGroupStyles()

  return (
    <div className={clsx(classes.root, className)}>
      {label && <ENTextPassage>{label}</ENTextPassage>}

      <ENRadio variant="horizontal">
        <Fragment key=".0">
          {options.map((opt) => (
            <div className={classes.radioContainer}>
              <ENRadioItem
                id={opt.id}
                handleOnChange={() => onChange(opt.value)}
                name={name}
                value={opt.value}
                isChecked={value === opt.value}
                isDisabled={isDisabled}
                data-testid={opt.id}
              >
                {opt.label}
              </ENRadioItem>

              {opt?.infoIconTooltip && (
                <ZtnaTooltip title={opt.infoIconTooltip} cssPosition="absolute">
                  <div className={classes.iconWrapper}>
                    <ZtnaIcon name="infoCircle" height={18} width={18} />
                  </div>
                </ZtnaTooltip>
              )}
            </div>
          ))}
        </Fragment>
      </ENRadio>
    </div>
  )
}

export default EnRadioGroup
