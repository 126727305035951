import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { MIN_PAGE_LIMIT, ZtnaPaginationType } from "src/components/ZtnaPagination"
import { RootState } from "src/store"
import { setRowsPerPage } from "src/store/ui/uiSlice"

type PaginationDataReturnType = {
  limit: number
  offset: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  setOffset: React.Dispatch<React.SetStateAction<number>>
  paginationCallback: ZtnaPaginationType["callback"]
}

/**
 * Custom hook to manage pagination data.
 *
 * @param {number} [defaultLimit=MIN_PAGE_LIMIT] - The default number of items per page.
 * @param {boolean} [persistLimit=false] - Flag to determine if the limit should be persisted in the Redux store.
 * @returns {PaginationDataReturnType} An object containing pagination data and methods to update it.
 *
 * @typedef {Object} PaginationDataReturnType
 * @property {number} limit - The current limit of items per page.
 * @property {number} offset - The current offset for pagination.
 * @property {number} page - The current page number.
 * @property {Function} setPage - Function to set the current page number.
 * @property {Function} setOffset - Function to set the current offset.
 * @property {Function} paginationCallback - Callback function to update pagination data.
 */
const usePaginationData = (defaultLimit = MIN_PAGE_LIMIT, persistLimit = false): PaginationDataReturnType => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const limitFromRedux = useSelector((state: RootState) => state.ui.rowsPerPage?.[pathname] || defaultLimit)

  const [limit, setLimit] = useState(defaultLimit)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)

  const paginationCallback = (newOffset: number, newLimit: number, newPage: number) => {
    setOffset(newOffset)
    setPage(newPage)
    setLimit(newLimit)
    if (persistLimit) {
      dispatch(setRowsPerPage({ routePath: pathname, rowsPerPage: newLimit }))
    }
  }

  return {
    limit: persistLimit ? limitFromRedux : limit,
    offset,
    page,
    setPage,
    setOffset,
    paginationCallback,
  }
}

export default usePaginationData
