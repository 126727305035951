import { intersection } from "lodash"
import { RouteConfig, RouteType, RouteWithoutComponentType } from "src/routes/config"
import { FeatureFlagsType } from "src/store/ui/types"

/**
 * Filters the given routes based on the provided roles and their permissions.
 *
 * @param {RouteType[]} routes - An array of route objects to be filtered.
 * @param {string[]} roles - An array of role strings to check against route permissions.
 * @returns {RouteType[]} - An array of routes that match the given roles and permissions.
 *
 * @example
 * const routes = [
 *   { path: '/dashboard', permission: ['admin'] },
 *   { path: '/profile', permission: ['user', 'admin'] },
 *   { path: '/login' }
 * ];
 * const roles = ['user'];
 * const filteredRoutes = filterRoutesWithPermission(routes, roles);
 * // filteredRoutes will be:
 * // [
 * //   { path: '/profile', permission: ['user', 'admin'] },
 * //   { path: '/login' }
 * // ]
 */
const filterRoutesWithPermission = (routes: RouteType[], roles: string[]): RouteType[] => {
  return routes.filter(({ permission }) => {
    if (!permission) return true
    if (!permission?.length) return true
    return intersection(permission, roles).length
  })
}

/**
 * Removes the `component` key from each route object in the provided routes array.
 * If a route has child routes, it also removes the `component` key from each child route.
 *
 * @param {RouteType[]} routes - The array of route objects to process.
 * @returns {RouteWithoutComponentType[]} - A new array of route objects without the `component` key.
 */
const removeComponentsKeyfromRoutesArray = (routes: RouteType[]): RouteWithoutComponentType[] => {
  return routes.map(({ component, ...route }) => {
    const childRoutes = route.childs?.map(({ component, ...child }) => child)
    return { ...route, childs: childRoutes }
  })
}

/**
 * Filters and returns the allowed routes based on the provided roles and feature flags.
 *
 * @param {string[]} roles - An array of user roles.
 * @param {FeatureFlagsType} flags - An object representing feature flags.
 * @returns {RouteType[]} - An array of routes that the user is allowed to access.
 */
export const getAllowedRoutes = (roles: string[], flags: FeatureFlagsType): RouteType[] => {
  const routes = RouteConfig(flags, roles[0]) || []
  const filteredRoutesConfig = filterRoutesWithPermission(removeComponentsKeyfromRoutesArray(routes), roles)
  return filteredRoutesConfig.map((route) => {
    const { childs } = route
    if (childs && childs?.length) {
      const filteredChilds = filterRoutesWithPermission(removeComponentsKeyfromRoutesArray(childs), roles)
      return { ...route, childs: filteredChilds }
    }
    return route
  })
}

/**
 * Retrieves the selected route based on the provided URL and allowed routes.
 *
 * @param {string} url - The URL to match against the allowed routes.
 * @param {RouteType[]} allowedRoutes - An array of allowed routes to search within.
 * @returns {RouteType | undefined} - The matched route if found, otherwise undefined.
 */
export const getSelectedRoute = (url: string, allowedRoutes: RouteType[]): RouteType | undefined => {
  let route

  allowedRoutes.forEach((element) => {
    const { childs, path } = element
    const matchURL = path
    if (childs?.length) {
      childs.forEach((childElement) => {
        const matchChildURL = childElement.path
        if (url === matchChildURL?.absolutePath) {
          route = childElement
        }
      })
    } else if (url === matchURL?.absolutePath) {
      route = element
    }
  })

  return route
}
