import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { useDispatch } from "react-redux"
import WarningContainer from "src/components/WarningContainer"
import ErrorContainer from "src/shared/components/ErrorContainer"
import Modal from "src/shared/components/Modal"
import { closeModal } from "src/store/ui"
import { retryUpgradeSingleUztnaGateway } from "./RetryUztnaGatewayUpgrade.service"
import { useRetryUztnaGatewayUpgradeStyles } from "./RetryUztnaGatewayUpgrade.style"

interface RetryUztnaGatewayUpgradeProps {
  onClose: () => void
  uztnaGatewayData: any
}

const RetryUztnaGatewayUpgrade: React.FC<RetryUztnaGatewayUpgradeProps> = ({ onClose, uztnaGatewayData }) => {
  const classes = useRetryUztnaGatewayUpgradeStyles()

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const dispatch = useDispatch()

  const handleRetryClick = () => {
    setError("")
    setLoading(true)
    retryUpgradeSingleUztnaGateway(uztnaGatewayData?.id || "")
      .then(() => {
        onClose()
        dispatch(closeModal("retryUztnaGatewayUpgrade"))
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.message)
      })
  }

  return (
    <Modal
      onCancel={(): void => {
        onClose()
        dispatch(closeModal("retryUztnaGatewayUpgrade"))
        setError("")
      }}
      onCancelProps={{ disabled: isLoading }}
      title="Upgrade Failed: Resolve Or Retry Now"
      onOk={() => {
        handleRetryClick()
      }}
      onOkProps={{
        title: "Retry",
        isLoading,
        disabled: isLoading,
      }}
      width="477px"
      contentClass={classes.contentClass}
    >
      <div>
        <WarningContainer message=" Unfortunately the upgrade process has encountered an issue." />
      </div>

      <ENTextPassage className={classes.disclaimerText}>
        <p>
          Please retry upgrading the UZTNA Gateway <strong>{uztnaGatewayData?.name}</strong> to use the new version of
          UZTNA Gateway.
        </p>
      </ENTextPassage>

      <ErrorContainer message={error} open={!!error} handleClose={() => setError("")} />
    </Modal>
  )
}

export default RetryUztnaGatewayUpgrade
