import { createUseStyles } from "react-jss"
import theme from "src/theme"

export const useNetworkPolicyEvaluationStyles = createUseStyles(() => ({
  container: {
    overflowX: "hidden",
    height: "100%",
  },
  paper: {
    backgroundColor: theme.color.background.transparentEmphasis,
    width: "100%",
    /* height: "100%", */
    display: "inline-block",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow:
      "0px 2px 2px 0px rgba(30, 31, 42, 0.24), 0px 4px 4px 0px rgba(30, 31, 42, 0.24), 0px 8px 8px 0px rgba(30, 31, 42, 0.24)",
    borderRadius: "4px",
  },
  hostingProviderContainer: {
    display: "grid",
    gridTemplateColumns: "424px 424px",
    gap: 24,
  },
  cert: {
    marginBottom: 24,
    display: "inline-grid",
    gridTemplateColumns: 872,
  },
  subProviderContainer: {
    display: "grid",
    gridTemplateColumns: "207px 207px",
    gap: 10,
  },
  simpleInput: {
    marginBottom: 24,
  },
  marginBottom35: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  title: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 400,
    color: theme.color.grey[300],
  },
  optional: {
    fontStyle: "italic",
    marginLeft: 6,
    color: theme.color.grey[800],
    fontWeight: 400,
    fontSize: 14,
  },
  select: {
    marginBottom: 24,

    "& .react-select__placeholder": {
      fontSize: 14,
    },
    "& .react-select__option--is-selected": {
      "& p": {
        color: theme.color.neutralLight[16],
      },
    },
    "& .react-select__control": {
      borderRadius: 2,
      maxHeight: 72,
      overflowX: "hidden",
    },
    "& .react-select__indicators": {
      top: 8,
      right: 0,
    },
  },
  hostingProviderTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.color.grey[300],
  },
  infoIconContainer: {
    display: "inline-block",
    verticalAlign: "top",
    marginLeft: 7,
  },
  iconInfo: {
    marginRight: 10,
    cursor: "pointer",
  },
  root: {
    borderColor: theme.color.grey[700],
    borderRadius: 3,
    marginBottom: 15.4,
    overflow: "visible",
  },
  content: {
    display: "flex",
    alignItems: "center",
    padding: "21px 16px 6px",
  },
  turnArrowIcon: {
    transform: "rotate(180deg)",
  },
  iconWrapper: {
    width: 34,
    display: "flex",
    justifyContent: "center",
    marginRight: 16,
  },
  dFlex: {
    display: "flex",
  },
  para: {
    lineHeight: 2,
  },
  returnAttr: {
    display: "grid",
    gridTemplateColumns: "160px auto",
  },
  redBg: {
    width: 24,
    height: 24,
    background: theme.color.error.main,
    textAlign: "center",
    borderRadius: 4,
    lineHeight: "28px",
    marginRight: 20,
  },
  greenBg: {
    width: 24,
    height: 24,
    background: theme.color.green[9],
    textAlign: "center",
    borderRadius: 4,
    lineHeight: "28px",
    marginRight: 20,
  },
  contentBlk: {
    padding: "0px 24px 8px 70px",
  },
  circle: {
    height: 10,
    width: 10,
    borderRadius: "100%",
    display: "inline-block",
    marginRight: 15,
  },
  red: {
    backgroundColor: theme.color.background.dangerDefault,
  },
  green: {
    background: theme.color.background.successDefault,
  },
  mb8: {
    marginBottom: 8,
  },
  result: {
    background: theme.color.background.surfaceElevation1,
    padding: "16px 24px",
  },

  status: {
    fontSize: "14px",
    fontWeight: 600,
    borderRadius: "47px",
    padding: "3px 10px",
    display: "inline-block",
  },
  normal: {
    color: theme.color.grey[300],
    background: `${theme.color.grey[300]}1A`,
  },
  success: {
    color: theme.color.success[500],
    backgroundColor: theme.color.success[400],
  },
  failed: {
    color: theme.color.error.main,
    backgroundColor: "rgba(235, 0, 0, 0.10)",
  },
  subheading: {
    fontSize: 26,
    fontWeight: 700,
    color: theme.color.grey[300],
    marginRight: 17,
  },
  subTitle: {
    fontWeight: 600,
    color: theme.color.grey[300],
  },
  alignCenter: {
    alignItems: "center",
  },
  mb12: {
    marginBottom: 12,
  },
  label: {
    color: theme.color.grey[300],
  },
  sublabel: {
    color: theme.color.grey[300],
    fontSize: 14,
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  errorContainer: {
    marginTop: 6,
    marginBottom: 20,
  },
  scrollbar: {},
  repeatblk: {
    marginBottom: 10,
  },
  show: {
    margin: "12px 0px 14px 70px",
    color: theme.color.purple[9],
  },
  textTrim: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  marginmtmdop: {
    marginTop: "calc(var(--size-base-unit)* 3)",
    marginBottom: "calc(var(--size-base-unit)* 3)",
  },
  marginrightsm: {
    marginLeft: "calc(var(--size-base-unit)* 1)",
  },
  testDesc: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 2,
  },
  dgrid: {
    display: "grid",
  },
  grid2: {
    gridTemplateColumns: "58.33% 41.66%",
    gap: "1rem",
  },
  gridTemplateColumns4_6: {
    gridTemplateColumns: "40% 60%",
  },
  disabledLabel: {
    color: theme.color.neutralDark[13],
    "& *": {
      color: theme.color.neutralDark[13],
    },
  },
  inputLabel: {
    fontSize: 12,
    marginBottom: -10,
    fontWeight: 400,
    "& b": {
      fontWeight: "400 !important",
    },
    color: `${theme.color.neutralDark[8]} !important`,
  },
  posRelative: {
    position: "relative",
    "& div:first-child": {
      height: 44,
    },
  },
  floatLabel: {
    position: "absolute",
    top: -8,
    left: 10,
    zIndex: 999,
    background: theme.color.neutralDark[3],
    padding: "0 3px",
  },
}))
