import { GET_MANIFEST } from "src/constants"
import { API_URL, REQUEST_HEADERS } from "src/services"
import useSWR from "swr"

type UseAppManifestResponseType = {
  data: {
    version: string
  }
  isLoading: boolean
  error: any
  isValidating: boolean
  getAppManifest: () => void
}

/**
 * Custom hook to fetch the application manifest data, including its version.
 *
 * @returns {UseAppManifestResponseType} - The response object contains data, error, isLoading, getAppManifest, and isValidating.
 */
const useAppManifest = (): UseAppManifestResponseType => {
  const url = `${API_URL}${GET_MANIFEST}`

  const { data, error, mutate, isValidating } = useSWR([url, REQUEST_HEADERS])

  return {
    data,
    isLoading: !error && !data,
    error,
    getAppManifest: mutate,
    isValidating,
  }
}

export default useAppManifest
