import { TENANT_ADMIN_BASE_URL, USER_LOCATION } from "src/constants"
import { RoleValueType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { keysToSnakeCase } from "src/utils"
import { postRequest } from ".."
import { LocationType } from "./swrHooks/useLocation"

/**
 * Sends location data to the server.
 *
 * @param {LocationType} location - The location data containing country, countryCode, and city.
 * @param {RoleValueType} role - The role of the user.
 * @returns {Promise<{ status: boolean }>} A promise that resolves to an object containing the status of the request.
 *
 * @example
 * const location = { country: 'USA', countryCode: 'US', city: 'New York' };
 * const role = 'admin';
 * const response = await sendLocationData(location, role);
 * console.log(response.status); // true or false
 */
export const sendLocationData = async (
  { country, countryCode, city }: LocationType,
  role: RoleValueType,
): Promise<{
  status: boolean
}> => {
  const data = keysToSnakeCase({ countryCode, countryName: country, city })

  const url = isTenantRole(role) ? `${TENANT_ADMIN_BASE_URL}${USER_LOCATION}` : USER_LOCATION
  const resp = await postRequest(url, data)

  return {
    status: resp.data.success,
  }
}
