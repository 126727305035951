import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENIconCopy } from "en-react/dist/src/components/Icons/Copy"
import React, { useCallback, useState } from "react"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { useCopyIconWithTooltipStyles } from "./CopyIconWithTooltip.styles"

interface CopyIconWithTooltipProps {
  text: string
  iconButtonTestId?: string
  iconButtonId?: string
  iconButtonClass?: string
  iconWidth?: string
  iconHeight?: string
  iconColor?: string
  tooltipPlacement?: "top" | "bottom" | "left" | "right"
  tooltipCssPosition?: "absolute" | "fixed"
}

const CopyIconWithTooltip = ({
  text,
  iconButtonTestId,
  iconButtonId,
  iconButtonClass,
  iconWidth,
  iconHeight,
  iconColor,
  tooltipPlacement = "top",
  tooltipCssPosition = "fixed",
}: CopyIconWithTooltipProps) => {
  const classes = useCopyIconWithTooltipStyles()
  const [isTextCopied, setIsTextCopied] = useState(false)

  const handleCopyText = useCallback(() => {
    navigator.clipboard.writeText(text)
    setIsTextCopied(true)
    setTimeout(() => setIsTextCopied(false), 1000)
  }, [text])

  return (
    <ZtnaTooltip
      title={isTextCopied ? "Copied" : "Copy"}
      placement={tooltipPlacement}
      arrow
      open={isTextCopied}
      cssPosition={tooltipCssPosition}
    >
      <ENButton
        variant="tertiary"
        data-testid={iconButtonTestId}
        id={iconButtonId}
        className={clsx(classes.iconButton, iconButtonClass)}
        onClick={handleCopyText}
      >
        <ENIconCopy iconTitle="copy" size="md" style={{ color: iconColor }} />
      </ENButton>
    </ZtnaTooltip>
  )
}

export default React.memo(CopyIconWithTooltip)
