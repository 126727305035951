import fetcher from "../fetcher"

/**
 * Global configuration for SWR (stale-while-revalidate).
 * This includes a custom fetcher, retry logic on error, and revalidation behavior.
 */
const SWRGlobalConfig = {
  fetcher,

  /**
   * Determines if the data should be revalidated when the component is focused.
   * @type {boolean}
   */
  revalidateOnFocus: false,

  /**
   * Determines if the data should be revalidated when the component is mounted.
   * @type {boolean}
   */
  revalidateOnMount: true,

  /**
   * Custom error retry logic for SWR requests.
   * Retries the request when an error occurs, with specific conditions:
   * - Does not retry on 404, 403, or 401 errors.
   * - Retries up to 10 times.
   * - Retries after 5 seconds.
   *
   * @param {...any} args - The arguments passed to the error handler.
   * @param {Error} args[0] error - The error object that was thrown.
   * @param {Function} args[3] revalidate - The revalidation function to retry the request.
   * @param {Object} args[4] - Contains retry information.
   * @param {number} args[4].retryCount - The number of retry attempts that have been made.
   *
   * @returns {any} - No return value; it handles retries internally.
   */
  onErrorRetry: (...args: any[]): any => {
    const {
      0: error,
      3: revalidate,
      4: { retryCount },
    } = args

    // Never retry on 404, 403, or 401.
    if (error?.response?.status === 404 || error?.response?.status === 403 || error?.response?.status === 401) return

    if (retryCount >= 10)
      // Only retry up to 10 times.
      return

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000)
  },
}

export default SWRGlobalConfig
