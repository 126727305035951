import ztnaAxios from ".."

/**
 * Fetches data from the given URL using a GET request and returns the response data.
 *
 * @param {string} url - The URL to send the GET request to.
 * @param {Object<string, string>} [headers] - Optional headers to include in the request.
 *
 * @returns {any} The data from the response.
 */
const fetcher = (url: string, headers?: { [key: string]: string }): any =>
  ztnaAxios.get(url, { headers }).then((res) => res?.data)

export default fetcher
