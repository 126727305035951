import { createUseStyles } from "react-jss"

type Props = {
  variant: "elevation1" | "elevation2"
}
export const useDragAndDropStyles = createUseStyles<string, Props>((theme) => ({
  fileWrapper: {
    display: "flex",
    justifyContent: "space-between",
    border: `2px dashed ${theme.color.border.default}`,
    borderSpacing: 10,
    borderRadius: 4,
    backgroundColor: theme.color.background.surfaceElevation1,
    padding: "21px 20px",
    fontSize: 14,
    color: theme.color.content.secondary,
    marginTop: theme.spacing(2),
  },
  errorContainer: {
    borderColor: theme.color.background.dangerDefault,
    // border: "none",
  },
  file: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  fileInput: {
    display: "none",
  },
  fileNameWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.color.background.surfaceElevation3,
    borderRadius: 4,
    padding: theme.spacing(0.5, 2),
    marginTop: theme.spacing(2),
  },
  fileName: {
    marginLeft: 10,
    wordBreak: "break-word",
  },
  dismissFileButton: {
    marginLeft: "auto",
  },
  dragOver: {
    borderColor: theme.color.border.accentEmphasis,
    backgroundColor: theme.color.background.surfaceElevation2,
  },
  errorNote: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  greyColor: {
    color: theme.color.content.secondary,
  },
  textAlignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))
