import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { useDispatch } from "react-redux"
import ErrorContainer from "src/shared/components/ErrorContainer"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import Modal from "src/shared/components/Modal"
import { updateSignals } from "src/store/ui/uiSlice"
import { upgradeAllConnectors, upgradeAllRelayNodes, upgradeRadsecProxyVersion } from "../UpgradeBanner.service"
import { useInitiateUpgradeModalStyles } from "./InitiateUpgradeModal.styles"

const mapUpgradeType = {
  relayNode: "UZTNA Gateway",
  connector: "Service Connector",
  radsecProxy: "RadSec Proxy",
} as const

interface InitiateUpgradeModalProps {
  type: keyof typeof mapUpgradeType
  onModalClose: () => void
  version?: any
}

const InitiateUpgradeModal = ({ type, onModalClose, version }: InitiateUpgradeModalProps) => {
  const dispatch = useDispatch()
  const classes = useInitiateUpgradeModalStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

  const onSubmit = async () => {
    setError("")
    setIsLoading(true)
    try {
      if (type === "connector") {
        await upgradeAllConnectors()
      } else if (type === "relayNode") {
        await upgradeAllRelayNodes()
      } else if (type === "radsecProxy") {
        await upgradeRadsecProxyVersion(version)
      }
      onModalClose()
      dispatch(
        updateSignals(
          type === "connector" ? ["getServiceConnectors", "getConnectorsExpandedViewData"] : ["getRelayNodes"],
        ),
      )
    } catch (error: any) {
      setIsLoading(false)
      setError(error?.message)
    }
  }

  const information =
    type === "connector" ? (
      "The upgrade will be carried out in a step-by-step manner, focusing on individual connector instances sequentially. During this upgrade, there will be a brief traffic disruption lasting 2-3 seconds for the applications connected to the respective service connector instance."
    ) : type === "radsecProxy" ? (
      "The upgrade will be carried out to all proxies. Users might experience an interruption of 2-3 mins during this process. For the proxies with an action in-progress, the upgrade will be pending until the current action completes."
    ) : (
      <>
        The upgrade of all UZTNA Gateways will occur simultaneously. To prevent any disruptions in traffic, ZTA will
        make sure to provision backup resources before initiating the upgrade.
        <br />
        You may still face traffic disruption of about 2-3 seconds.
      </>
    )

  const question = ["connector", "radsecProxy"].includes(type)
    ? "Are you sure you want to initiate upgrade?"
    : "Would you like to proceed and initiate the upgrade?"

  return (
    <Modal
      title={`Upgrade ${mapUpgradeType[type]}${type !== "radsecProxy" ? "s" : ""}`}
      width={type === "radsecProxy" ? 578 : 686}
      onOk={onSubmit}
      onOkProps={{ title: type === "radsecProxy" ? "Initiate" : "Initiate Upgrade", isLoading }}
      onCancel={onModalClose}
      onCancelProps={{ disabled: isLoading }}
      contentClass={classes.contentClass}
      disableMinHeight
    >
      <div className={classes.infoContainer}>
        <span className={classes.iconWrapper}>
          <ZtnaIcon name="triangleWarningEmpty" />
        </span>

        <ENTextPassage>{information}</ENTextPassage>
      </div>

      <ENTextPassage>{question}</ENTextPassage>

      <ErrorContainer
        message={error}
        open={!!error}
        handleClose={() => setError("")}
        errorClass={classes.errorContainer}
      />
    </Modal>
  )
}

export default InitiateUpgradeModal
