import { TemplateResult, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENButton } from '../button/button';
import { ENIconChevronDown } from '../icon/icons/chevron-down';
import { ENIconChevronRight } from '../icon/icons/chevron-right';
import { ENLink } from '../link/link';
import styles from './menu-item.scss';

/**
 * Component: en-menu-item
 * - A menu item is a singular item that is meant to be used within the ENMenu
 * @slot - The content to display in the menu item
 * @slot "before" - Optional prefix content to display in the menu item
 */
export class ENMenuItem extends ENElement {
  static el = 'en-menu-item';
  private elementMap = register({
    elements: [
      [ENLink.el, ENLink],
      [ENButton.el, ENButton],
      [ENIconChevronDown.el, ENIconChevronDown],
      [ENIconChevronRight.el, ENIconChevronRight]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private linkEl = unsafeStatic(this.elementMap.get(ENLink.el));
  private buttonEl = unsafeStatic(this.elementMap.get(ENButton.el));
  private iconChevronDownEl = unsafeStatic(this.elementMap.get(ENIconChevronDown.el));
  private iconChevronRightEl = unsafeStatic(this.elementMap.get(ENIconChevronRight.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * The optional menu link URL
   */
  @property()
  href?: string;

  /**
   * Target attribute for the menu link (i.e. set to _blank to open in new tab)
   */
  @property()
  target?: '_blank' | '_self' | '_parent' | '_top';

  /**
   * Title attribute
   * - Optional title for a11y
   * - If a link opens a new window or performs an action, use the title attribute to provide a tooltip with additional information.
   */
  @property()
  linkTitle?: string;

  /**
   * isHeader property
   * - **true** Applies the header treatment to the menu item
   */
  @property({ type: Boolean })
  isHeader?: boolean;

  /**
   * isExpandableHeader property
   * - **true** Applies expandable functionality to the menu item
   */
  @property({ type: Boolean })
  isExpandableHeader?: boolean;

  /**
   * isNested property
   * - **true** Applies nested functionality to the menu item
   */
  @property({ type: Boolean })
  isNested?: boolean;

  /**
   * isExpanded property
   * - **true** Applies the expanded treatment to a Header item
   * - **false** Applies the collapsed treatment to a Header item
   */
  @property({ type: Boolean })
  isExpanded?: boolean;

  /**
   * Selected attribute
   * - Changes the component's treatment to represent a selected state
   */
  @property({ type: Boolean })
  isSelected?: boolean;

  /**
   * Current attribute
   * - Changes the component's treatment to represent a focused state
   */
  @property({ type: Boolean })
  isFocused?: boolean;

  /**
   * Disabled attribute
   * - Changes the component's treatment to represent a disabled state
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Is hidden property
   * - Is set on child menu items based on their Header's expanded state
   * - **true** Hide the item
   * - **false** Show the item
   */
  @property()
  isHidden?: boolean = false;

  /**
   * Group id property
   * - Dynamically set by the Menu parent component
   * - Used to associate menu group headers and group items
   */
  @property()
  groupId?: string;

  /**
   * Index property
   * - Dynamically set by the Menu parent component
   * - Used to track and set focus with keyboard navigation
   */
  @property({ type: Number })
  idx?: number;

  /**
   * Indentation
   * - Dynamically set by the menu parent component
   * - Adds padding to a menu item to left-align its text with that of its Header
   */
  @property({ type: Number })
  indentation?: number;

  /**
   * Label attribute
   * - Sets the ariaLabel for A11y
   */
  @property()
  label?: string;

  /**
   * Aria controls
   * - Associates a Expandable Header's control with the items in that group
   */
  @property()
  ariaControls?: string;

  /**
   * variant?
   * - If default,  selected menu items will be highlighed.
   * - If tertiary, selected menu items will be theme colored not highlighted.
   * Default selected variant is 'default'
   */
  @property({ type: String })
  variant?: 'default' | 'tertiary' = 'default';

  /**
   * headerLinkShouldExpand?
   * - If true, then if menu item is header, then clicking anywhere on it will expand or collapse only.
   * - If false, then clicking menu will perform click action, not expand/collapse
   * Default selected value is 'false'
   */
  @property({ type: Boolean })
  headerLinkShouldExpand?: boolean = false;

  /**
   * focusOnCompleteItem?
   * - If true, then whole menu item get focus.
   * - If false, then individual link or button get focus
   * Default selected value is 'false'
   */
  @property({ type: Boolean })
  focusOnCompleteItem?: boolean = false;

  /**
   * linkTextInnerHTML?
   * If set, then link text html will be replaced by it
   * Default value is undefined
   */
  @property({ type: String })
  linkTextInnerHTML?: TemplateResult;

  /**
   * Query the EN-LINK element inside the component
   */
  @query('.en-c-menu-item__link')
  menuItemLink: ENLink;

  /**
   * Query the EN-BUTTON element inside the component
   */
  @query('.en-c-menu-item__control')
  menuItemControl: ENButton;

  /**
   * Query the link element inside the ENLink
   * - This will be either an `a` tag or `button`
   */
  get menuItemLinkEl(): HTMLAnchorElement | HTMLButtonElement {
    if (this.menuItemLink) {
      return this.menuItemLink.shadowRoot.querySelector('.en-c-link');
    }
  }

  /**
   * Query the button element inside the ENButton
   */
  get menuItemControlEl(): HTMLButtonElement {
    if (this.menuItemControl) {
      return this.menuItemControl.shadowRoot.querySelector('.en-c-button');
    }
  }

  /**
   * First updated lifecycle
   * 1. Wait for slotted elements to be loaded
   */
  async firstUpdated() {
    await this.updateComplete;
    this.setIndentation();
    this.setLinkClasses();
    this.setControlClasses();
  }

  /**
   * Updated lifecycle
   * 1. Wait for slotted elements to be loaded
   */
  updated() {
    this.setIndentation();
    this.setLinkClasses();
    this.setControlClasses();
  }

  handleMenuItemTextOverflow() {}

  /**
   * Updates the classes for the menu item link element based on its state.
   *
   * 1. Adds base classes when the element is available.
   * 2. Adds specific classes if the menu item has a "tertiary" variant.
   * 3. Adds a header-specific class if the menu item is a header.
   * 4. Toggles the selected and theme-specific classes based on the isSelected state.
   */
  setLinkClasses() {
    if (this.menuItemLinkEl) {
      // 1. Add base classes
      this.menuItemLinkEl.classList.add('en-c-menu-item-link');

      // 2. Add classes based on the variant
      if (this.variant === 'tertiary') {
        this.menuItemLinkEl.classList.add('en-c-menu-item-link--tertiary');

        if (!this.isHeader) {
          this.menuItemLinkEl.classList.add('en-c-button--tertiary-theme');
        }
      }

      // 3. Add header-specific classes
      if (this.isHeader) {
        this.menuItemLinkEl.classList.add('en-c-menu-item-header-link');
      }

      // 4. Update classes based on isSelected property
      if (this.isSelected) {
        this.menuItemLinkEl.classList.add('en-is-selected');
        if (this.isHeader && !this.isExpandableHeader) {
          this.menuItemLinkEl.classList.add('en-c-button--tertiary-theme');
        }
      } else {
        this.menuItemLinkEl.classList.remove('en-is-selected');
        if (this.isHeader && !this.isExpandableHeader) {
          this.menuItemLinkEl.classList.remove('en-c-button--tertiary-theme');
          // Explicitly ensure en-is-selected is removed for this case
          this.menuItemLinkEl.classList.remove('en-is-selected');
        }
      }
    }
  }

  /**
   * Set the appropriate classes for the control button
   * 1. Add classes once the element loads
   * 2. Toggle selected class based on isSelected property
   */
  setControlClasses() {
    if (this.menuItemControlEl) {
      /* 1 */
      this.menuItemControlEl.classList.add('en-c-menu-item-button');
      if (this.variant === 'tertiary') {
        this.menuItemLinkEl.classList.add('en-c-menu-item-button--tertiary');
      }
      /* 2 */
      if (this.isSelected) {
        this.menuItemControlEl.classList.add('en-is-selected');
      } else {
        this.menuItemControlEl.classList.remove('en-is-selected');
      }
    }
  }

  /**
   * Set indentation
   * 1. Set the indentation on the menu item link to align with header items
   */
  setIndentation() {
    if (this.indentation) {
      this.style.setProperty('--en-link-padding-inline-start', this.indentation.toString() + 'px'); /* 1 */
    }
  }

  /**
   * Set selected item
   * 1. If the item is not disabled or already selected, set its selected state to true
   * 2. Dispatch the custom event
   */
  setSelected() {
    /* 1 */
    if (!this.isDisabled && !this.isSelected) {
      this.isSelected = true;
      const linkEl = this.menuItemLinkEl;
      if (linkEl) {
        linkEl.click();
      }
      /* 2 */
      this.dispatch({
        eventName: 'menuItemSelected',
        detailObj: {
          selected: this.isSelected,
          item: this
        }
      });
    }
  }

  /**
   * Toggle the item's expanded state
   * 1. If the item is a group header, toggle its expanded state
   * 2. Dispatch the custom event
   */
  toggleExpanded() {
    /* 1 */
    if (this.isHeader && this.groupId) {
      this.isExpanded = !this.isExpanded;
      /* 2 */
      this.dispatch({
        eventName: 'menuItemExpanded',
        detailObj: {
          expanded: this.isExpanded,
          item: this
        }
      });
    }
  }

  /**
   * Handle on click of the menu item control button
   * - Expand the item
   */
  handleOnControlClick() {
    this.toggleExpanded();
  }

  /**
   * Handle on click of a menu item
   * - Select the item on click
   * 1. If header and 'headerLinkShouldExpand' feature flag is set then expand/collapse menu on item click rather default selected click action
   */
  handleOnLinkClick() {
    if (this.isHeader && !this.isExpandableHeader) {
      this.setSelected();
    }
    if (this.isHeader && this.headerLinkShouldExpand) {
      /* 1 */
      return this.handleOnControlClick();
    }
    this.setSelected();
  }

  /**
   * Handle on keydown
   * 1. If enter key is pressed, handle link click
   */
  handleOnKeydown(e: KeyboardEvent) {
    /* 1 */
    if (e.code === 'Enter') {
      this.handleOnLinkClick();
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-menu-item', {
      'en-c-menu-item--header': this.isHeader,
      'en-c-variant--tertiary': this.variant === 'tertiary',
      'en-is-hidden': this.isHidden
    });

    return html`
      <li
        class="${componentClassNames}"
        aria-label=${ifDefined(this.label)}
        aria-current=${ifDefined(this.isSelected)}
        tabIndex="${this.focusOnCompleteItem ? '0' : '-1'}"
        @keydown=${this.handleOnKeydown}
      >
        <${this.linkEl}
          class="en-c-menu-item__link"
          @click=${this.handleOnLinkClick}
          href=${ifDefined(this.href)}
          target=${ifDefined(this.target)}
          linkTitle=${ifDefined(this.linkTitle)}
          ?isDisabled=${this.isDisabled}
          tabIndex="${this.focusOnCompleteItem ? '-1' : '0'}"
          @keydown=${this.handleOnKeydown}
        >
          ${
            this.slotNotEmpty('before') &&
            html`
              <div class="en-c-menu-item__prefix">
                <slot name="before"></slot>
              </div>
            `
          }
          ${this.linkTextInnerHTML ? this.linkTextInnerHTML : html`<slot></slot>`}
        </${this.linkEl}>
       
        ${
          (this.isHeader && this.groupId) || this.isNested
            ? html`
          <${this.buttonEl}
            class="en-c-menu-item__control"
            styleModifier=${this.variant === 'tertiary' ? 'en-c-menu-item-button en-c-menu-item-button--tertiary' : 'en-c-menu-item-button'}
            @click=${this.handleOnControlClick}
            ?isExpanded=${this.isExpanded}
            aria-controls=${this.ariaControls}
            ?hideText=${true}
            ?isDisabled=${this.isDisabled}
            tabIndex="${this.focusOnCompleteItem ? '-1' : '0'}"
          >
         ${this.isNested ? html`<${this.iconChevronRightEl}  slot="after"></${this.iconChevronRightEl}>` : html`<${this.iconChevronDownEl} slot="before"></${this.iconChevronDownEl}>`}
         ${this.isExpanded ? 'Collapse' : 'Expand'}
          </${this.buttonEl}>
        `
            : html``
        }
      
      </li>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENMenuItem.el) === undefined) {
  customElements.define(ENMenuItem.el, ENMenuItem);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-menu-item': ENMenuItem;
  }
}
