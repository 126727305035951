import { CSSProperties } from "@material-ui/core/styles/withStyles"
import { CustomCellRendererProps } from "ag-grid-react"
import { ENButton } from "en-react/dist/src/components/Button"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { useDataGridPopOverStyles } from "./DataGridPopOver.styles"

const RowActionRenderer = (props: CustomCellRendererProps) => {
  const classes = useDataGridPopOverStyles({ top: 0, left: 0 })
  const { node, colDef, data } = props
  const cellRendererParams = colDef?.cellRendererParams
  const { isDisabled = false, disabledTooltip } = cellRendererParams

  const actionsDisabled = isDisabled ? isDisabled({ node }) : false

  return (
    <div className={classes.actionRoot}>
      <ZtnaTooltip
        title={
          actionsDisabled ? disabledTooltip?.(data) || "No action can be taken with the current state of a resource" : null
        }
        placement="left"
        cssPosition="absolute"
        fullWidthContainer
      >
        <ENButton
          variant="tertiary"
          hideText
          isDisabled={actionsDisabled}
          style={{ "--en-button-width": "100%" } as CSSProperties}
        >
          <span slot="after">
            <ZtnaIcon name="actionsDataGrid" />
          </span>
        </ENButton>
      </ZtnaTooltip>
    </div>
  )
}

export default RowActionRenderer
