import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import { CUSTOMER_ADMIN_UZTNA_GATEWAYS } from "src/constants"
import { API_URL, REQUEST_HEADERS } from "src/services"
import { IconNameType } from "src/shared/components/Icons"
import useSWR from "swr"

dayjs.extend(relativeTime)
dayjs.extend(utc)

type InstanceTypeSpecsType = {
  cpuCores: number
  memory: string
  networkBandwidth: string
}

export type RelayNodeResponseType = {
  id: string
  connectionLastUpdated: string | null
  accessKeyId: string
  name: string
  isNameSet: boolean
  lastStatusUpdate: string
  regionName: string
  regionAlias: string
  isHa: boolean
  upgradeStatus: keyof typeof mapUpgradeStatus
  upgradeMessage: string
  state: keyof typeof mapStatus
  connectionStatus: keyof typeof mapConnectionStatus
  instanceType: string
  instancesCount: number
  agentVersion: string
  instanceTypeSpecs: InstanceTypeSpecsType
  metaData: { key: string; msg: string }
  upgradeAvailable: boolean
  softwareVersion: string
  upgradeFailed: boolean
}

export type RelayNodeType = {
  id: string
  connectionLastUpdated: string | null
  name: string
  accessKeyId: string
  isNameSet: boolean
  lastStatusUpdate: number
  regionName: string
  regionAlias: string
  isHA: string
  upgradeStatus: (typeof mapUpgradeStatus)[keyof typeof mapUpgradeStatus]
  upgradeMessage: string
  state: (typeof mapStatus)[keyof typeof mapStatus] | "N/A"
  connectionStatus: (typeof mapConnectionStatus)[keyof typeof mapConnectionStatus]
  relayNodeStatusTooltip: (typeof mapStatusTooltip)[keyof typeof mapStatusTooltip]
  instanceTypeSpecsIconTooltip: string
  showInstanceTypeSpecsIcon: boolean
  instanceTypeSpecsIcon: IconNameType
  instanceType: string
  instancesCount: number
  agentVersion: string
  upgradeRelayIcon: IconNameType
  showUpgradeIcon: boolean
  upgradeIconTooltip: string
  softwareVersion: string
  deploymentFailureMessage: string
  upgradeFailed: boolean
  metaData?: { key: string; msg: string }
}

export type RelayNodePayloadType = {
  relayNodes: RelayNodeType[] | null
  total: number
  from?: number
  to?: number
}

type UseRelayNodesResponseType = {
  data?: RelayNodePayloadType
  isLoading: boolean
  error: any
  isValidating: boolean
  getRelayNodes: () => void
}

export type UseRelayNodesType = {
  offset?: number
  limit?: number
  refreshInterval?: number
  filterQuery?: string
  relayUpgradeDetails?: boolean
}

const mapStatus = {
  DELETE_FAILED: "Remove Failure",
  IN_PROGRESS: "Deployment in-progress",
  ENABLED: "Up",
  DELETE_IN_PROGRESS: "Remove In-Progress",
  ENABLE_FAILED: "Enable Failure",
  DEPLOYMENT_FAILED: "Deployment Failure",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
  UPDATE_ROLLBACK_FAILED: "Rollback Failed",
  UPDATE_ROLLBACK_IN_PROGRESS: "Rollback In-Progress",
  IN_ACTIVE: "Down",
  DEPLOYMENT_IN_PROGRESS: "Deployment In-Progress",
} as const

const mapUpgradeStatus = {
  UPGRADE_COMPLETED: "Upgrade Completed",
  ...mapStatus,
} as const

const mapStatusTooltip = {
  DELETE_FAILED: "UZTNA Gateway Failed to remove",
  IN_PROGRESS: `1. Resources are being deployed.    
                2. UZTNA Gateway agent is being installed.`,
  ENABLED: "UZTNA Gateway is up & running",
  DELETE_IN_PROGRESS: "Remove In-progress",
  ENABLE_FAILED: "Enable Failed",
  DEPLOYMENT_FAILED: "Deployment Failed",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
  UPDATE_ROLLBACK_FAILED: "Rollback Failed",
  UPDATE_ROLLBACK_IN_PROGRESS: "Rollback In-Progress",
  IN_ACTIVE: "UZTNA Gateway is not working as expected",
  DEPLOYMENT_IN_PROGRESS: "Deployment In-Progress",
} as const

const mapConnectionStatus = {
  ACTIVE: "active",
  IN_ACTIVE: "inActive",
  RESOURCE_DOWN: "resourceDown",
} as const

/**
 * Parses the relay nodes response and maps it to a more user-friendly format.
 *
 * @param {RelayNodeResponseType[]} relayNodes - Array of relay node responses to be parsed.
 *
 * @returns {RelayNodeType[]} - Array of parsed relay node data.
 */
export const parseRelayNodesData = (relayNodes: RelayNodeResponseType[]): RelayNodeType[] =>
  relayNodes.map((relayNode) => {
    const {
      name,
      connectionLastUpdated,
      connectionStatus,
      lastStatusUpdate,
      state,
      isHa,
      upgradeStatus,
      regionAlias,
      regionName,
      instanceType,
      instancesCount,
      instanceTypeSpecs,
      metaData,
      upgradeAvailable,
      softwareVersion,
      upgradeFailed,
    } = relayNode
    return {
      ...relayNode,
      connectionLastUpdated: connectionLastUpdated ? dayjs(connectionLastUpdated).fromNow() : "N/A",
      regionAlias,
      regionName,
      name: name || "N/A",
      instanceType,
      instancesCount,
      softwareVersion,
      connectionStatus: mapConnectionStatus[connectionStatus],
      upgradeRelayIcon: "enCautionFilled",
      showUpgradeIcon: upgradeAvailable,
      upgradeIconTooltip: state === "UPGRADE_FAILED" ? "Upgrade Failed" : "Upgrade your UZTNA Gateway",
      state: state === "ENABLED" && connectionStatus === "IN_ACTIVE" ? mapStatus[connectionStatus] : mapStatus[state],
      relayNodeStatusTooltip:
        state === "ENABLED" && connectionStatus === "IN_ACTIVE"
          ? mapStatusTooltip[connectionStatus]
          : mapStatusTooltip[state],
      lastStatusUpdate: dayjs().diff(dayjs(lastStatusUpdate)),
      isHA: isHa ? "Enabled" : "Disabled",
      upgradeStatus: mapUpgradeStatus[upgradeStatus],
      showInstanceTypeSpecsIcon: !!instanceTypeSpecs.cpuCores,
      instanceTypeSpecsIcon: "encircledInfo",
      instanceTypeSpecsIconTooltip: `CPU: ${instanceTypeSpecs.cpuCores}GHz
      Memory: ${instanceTypeSpecs.memory}
      Network Bandwidth: ${instanceTypeSpecs.networkBandwidth}`,
      deploymentFailureMessage: metaData?.msg,
      upgradeFailed:
        upgradeFailed &&
        ![
          "UPGRADE_IN_PROGRESS",
          "UPDATE_ROLLBACK_IN_PROGRESS",
          "UPDATE_DRAP_IN_PROGRESS",
          "IN_PROGRESS",
          "DELETE_IN_PROGRESS",
        ].includes(state),
    }
  })

/**
 * Custom hook to fetch relay node data and manage its state.
 *
 * @param {Object} options - Options to configure the request.
 * @param {number} [options.offset] - The offset for pagination, default is undefined.
 * @param {number} [options.limit=10] - The number of results to fetch, default is 10.
 * @param {number} [options.refreshInterval=10000] - The interval (in milliseconds) at which the data should be refreshed, default is 10000ms (10 seconds).
 * @param {string} [options.filterQuery] - The query string to filter the results, default is undefined.
 * @param {boolean} [options.relayUpgradeDetails] - Flag to include relay upgrade details in the request, default is undefined.
 *
 * @returns {UseRelayNodesResponseType} - Object containing the fetched relay node data, loading state, error, and a function to trigger a data refetch.
 */
const useRelayNodes = ({
  offset,
  limit = 10,
  refreshInterval = 10000,
  filterQuery,
  relayUpgradeDetails,
}: UseRelayNodesType): UseRelayNodesResponseType => {
  let url = `${API_URL}${CUSTOMER_ADMIN_UZTNA_GATEWAYS}?`

  if (limit !== undefined) url += `&limit=${100}`

  if (offset) url += `&offset=${offset}`

  if (filterQuery) url += `&${filterQuery}`

  if (relayUpgradeDetails) url += "&upgrade_status=true"

  const { data, error, mutate, isValidating } = useSWR([url, REQUEST_HEADERS], { refreshInterval })

  const payload = data?.payload

  return {
    data: payload
      ? {
          relayNodes: parseRelayNodesData(payload?.relayNodes),
          from: payload?.from,
          to: payload?.to,
          total: payload?.total,
        }
      : undefined,
    isLoading: !error && !data,
    error,
    getRelayNodes: mutate,
    isValidating,
  }
}

export default useRelayNodes
