import { ColDef } from "ag-grid-community"
import { isEqual } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import DataGrid from "src/components/DataGrid/DataGrid"
import BadgeWithIcons from "src/components/DataGrid/renderers/BadgeWithIconsCell/BadgeWithIconCell"
import { default as RetryUztnaGatewayUpgrade } from "src/pages/ExtremeAdminUztnaGateway/RetryUztnaGatewayUpgrade/RetryUztnaGatewayUpgrade"
import { PrivateRoutesMap } from "src/routes/config"
import useRelayNodes, { RelayNodePayloadType, RelayNodeType } from "src/services/api/swrHooks/useRelayNodes"
import Modal from "src/shared/components/Modal"
import { RootState } from "src/store"
import { closeModal, openModal } from "src/store/ui"
import { useUztnaGatewaysUpgradeDetailsModalStyles } from "./UztnaGatewaysUpgradeDetailsModal.styles"

const UztnaGatewaysUpgradeDetailsModal = () => {
  const classes = useUztnaGatewaysUpgradeDetailsModalStyles()
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(closeModal("upgradeUztnaGatewayDetails"))
  }

  const [relayData, setRelayData] = useState<RelayNodeType | null>(null)
  const [isForceLoading, setIsForceLoading] = useState(false)
  const [tableData, setTableData] = useState<RelayNodePayloadType>({ relayNodes: null, total: 0, from: 0, to: 0 })
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isOnRelayPage = pathname.includes(PrivateRoutesMap.MULTI_TENANTS_RELAY.absolutePath)
  const { data, isLoading, isValidating } = useRelayNodes({
    relayUpgradeDetails: true,
  })
  const showLoading = isForceLoading || isLoading
  const openedModals = useSelector((state: RootState) => state.ui.openedModals)

  const colsDefs: ColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "UZTNA Gateway",
      },
      {
        field: "upgradeStatus",
        headerName: "Upgrade Status",
        cellRenderer: BadgeWithIcons,
        cellRendererParams: {
          badgeConfig: {
            successValues: ["active", "upgrade completed", "Up"],
            warningValues: [
              "remove in-progress",
              "upgrade in-progress",
              "deployment in-progress",
              "rollback in-progress",
            ],
            dangerValues: [
              "enable failure",
              "remove failure",
              "deployment failure",
              "upgrade failed",
              "rollback failed",
            ],
            badgeTooltipKey: "upgradeStatus",
            tooltipPlacement: "right",
            iconConfiguration: {
              iconName: "encircledInfo",
              showIconValues: (rowData: RelayNodeType) => (rowData.upgradeFailed ? ["active", "upgrade failed"] : []),
              onIconClick: (rowData: RelayNodeType) => {
                setRelayData(rowData)
                dispatch(openModal("retryUztnaGatewayUpgrade"))
              },
            },
          },
        },
      },
      { field: "softwareVersion", headerName: "Software Version" },
    ],
    [],
  )

  const onModalClose = () => {
    setRelayData(null)
  }

  useEffect(() => {
    if (!isValidating) {
      isForceLoading && setIsForceLoading(false)
    }
    if (data?.relayNodes && !isEqual(tableData.relayNodes, data.relayNodes)) {
      setTableData(data)
    }
  }, [isValidating])

  return (
    <>
      <Modal
        title="UZTNA Gateway Upgrade"
        onOk={() => {
          !isOnRelayPage && navigate(PrivateRoutesMap?.MULTI_TENANTS_RELAY?.absolutePath)
          onClose()
        }}
        onOkProps={{ title: isOnRelayPage ? "Close" : "Navigate to UZTNA Gateways" }}
        onCancel={onClose}
        onCancelProps={{ visible: !isOnRelayPage, title: isOnRelayPage ? "" : "Close" }}
        contentClass={classes.content}
        width="1000px"
      >
        <DataGrid
          rowData={tableData?.relayNodes}
          columnDefs={colsDefs}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit()
          }}
          showLoading={showLoading}
          containerHeight={300}
          hasFilters={false}
        />
      </Modal>
      {openedModals["retryUztnaGatewayUpgrade"] && relayData && (
        <RetryUztnaGatewayUpgrade onClose={onModalClose} uztnaGatewayData={relayData} />
      )}
    </>
  )
}

export default UztnaGatewaysUpgradeDetailsModal
