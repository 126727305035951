import { TemplateResult, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENIconDone } from '../icon/icons/done';
import { ENSkeleton } from '../skeleton/skeleton';
import styles from './stepper-item.scss';

/**
 * Component: en-stepper-item
 * - A stepper item is an item inside the ENStepper
 * @slot - The label for each Stepper item
 * @slot "icon" - The icon that displays next to the label
 * @slot "description" - The description that displays below the label
 */
export class ENStepperItem extends ENElement {
  static el = 'en-stepper-item';

  private elementMap = register({
    elements: [
      [ENIconDone.el, ENIconDone],
      [ENSkeleton.el, ENSkeleton]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private iconCheckEl = unsafeStatic(this.elementMap.get(ENIconDone.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Variant
   * - **default** renders a Stepper item with a horizontal layout
   * - **vertical** renders a Stepper item with a vertical layout
   */
  @property()
  variant?: 'vertical';

  /**
   * Is active?
   * - A visual representation of a step with an active/current state
   */
  @property({ type: Boolean })
  isActive?: boolean;

  /**
   * Is complete?
   * - A visual representation of a step with a completed state
   */
  @property({ type: Boolean })
  isComplete?: boolean;

  /**
   * Is last?
   * - A visual representation of the last step within an ENStepper
   * - Dynamically set by the parent
   */
  @property({ type: Boolean })
  isLast?: boolean;

  /**
   * IsLoading
   * - A visual representation of the loading step within an ENStepper
   */
  @property({ type: Boolean })
  isLoading?: boolean;

  /**
   * Step number
   * - The number that appears for each step
   * - Dynically set by the parent
   */
  @property({ type: Number })
  stepNumber?: number = 1;

  /**
   * If true then show lower case alphabets instead of step number. Make sure that steps should not be more than 26 as we have 26 alphabets only.
   * Default value is false
   */
  @property({ type: Boolean })
  showLowerCaseAlphabets?: boolean = false;

  /**
   * If true then show upper case alphabet instead of step number. Make sure that steps should not be more than 26 as we have 26 alphabets only.
   * Default value is false
   */
  @property({ type: Boolean })
  showUpperCaseAlphabets?: boolean = false;

  /**
   * Enable click on stepper items by setting true
   */
  @property({ type: Boolean })
  isItemClickable?: boolean;

  private _lowerCaseAlphabets = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ];
  private _upperCaseAlphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ];

  handleStepperItemClick(e: Event) {
    if (!this.isItemClickable) {
      e.stopPropagation();
      return;
    }
    this.dispatch({
      e,
      eventName: 'step-item-click',
      detailObj: { stepNumber: this.stepNumber, stepContent: this._getStepContent() }
    });
  }

  private _getStepContent = () => {
    return this.showLowerCaseAlphabets
      ? this._lowerCaseAlphabets[this.stepNumber - 1]
      : this.showUpperCaseAlphabets
        ? this._upperCaseAlphabets[this.stepNumber - 1]
        : this.stepNumber;
  };

  render() {
    const componentClassNames = this.componentClassNames('en-c-stepper-item', {
      'en-c-stepper-item--vertical': this.variant === 'vertical',
      'en-is-active': this.isActive,
      'en-is-complete': this.isComplete,
      'en-is-last': this.isLast,
      'en-is-clickable': this.isItemClickable
    });

    const stepContent = this._getStepContent();

    if (this.isLoading) {
      return html` <div class="${componentClassNames}">
        <div class="en-c-stepper-item__step">
          <div class="en-c-stepper-item__counter">
            <en-skeleton variant="circle" width="32"></en-skeleton>
          </div>
          <hr class="en-c-stepper-item__hr" />
        </div>
        <div class="en-c-stepper-item__content">
          <div class="en-c-stepper-item__icon">
            ${this.variant === 'vertical' ? html`<en-skeleton variant="circle" width="20"></en-skeleton>` : html``}
          </div>
          <div class="en-c-stepper-item__body">
            <div class="en-c-stepper-item__title">
              ${this.variant !== 'vertical' ? html`<en-skeleton variant="circle" width="20"></en-skeleton>` : html``}
              <en-skeleton variant="square" width="${this.variant === 'vertical' ? 84 : 104}" height="24"> </en-skeleton>
            </div>
            <div class="en-c-stepper-item__description">
              <en-skeleton variant="square" width="140" height="20"></en-skeleton>
            </div>
          </div>
        </div>
      </div>` as TemplateResult<1>;
    }

    return html`
      <li
        role="listitem"
        class="${componentClassNames}"
        @click=${this.handleStepperItemClick}
        aria-current=${ifDefined(this.isActive ? 'step' : null)}
      >
        <div class="en-c-stepper-item__step">
          <div class="en-c-stepper-item__counter">
            ${this.isComplete ? html`<${this.iconCheckEl}></${this.iconCheckEl}>` : html` ${stepContent} `}
          </div>
          <hr class="en-c-stepper-item__hr" />
        </div>
        <div class="en-c-stepper-item__content">
          ${this.variant === 'vertical' && this.slotNotEmpty('icon')
            ? html`
                <div class="en-c-stepper-item__icon">
                  <slot name="icon"></slot>
                </div>
              `
            : html``}
          <div class="en-c-stepper-item__body">
            <div class="en-c-stepper-item__title">
              ${this.variant !== 'vertical' && this.slotNotEmpty('icon')
                ? html`
                    <div class="en-c-stepper-item__icon">
                      <slot name="icon"></slot>
                    </div>
                  `
                : html``}
              <slot></slot>
            </div>
            ${this.slotNotEmpty('description')
              ? html`
                  <div class="en-c-stepper-item__description">
                    <slot name="description"></slot>
                  </div>
                `
              : html``}
          </div>
        </div>
      </li>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENStepperItem.el) === undefined) {
  customElements.define(ENStepperItem.el, ENStepperItem);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-stepper-item': ENStepperItem;
  }
}
