import type { CustomLoadingCellRendererProps } from "ag-grid-react"
import { ENSkeleton } from "en-react/dist/src/components/Skeleton"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "src/store"
import { useDatGridLoadingComponentStyles } from "./DatGridLoadingComponent.styles"

interface LoaderProps extends CustomLoadingCellRendererProps {
  hideLoader?: boolean
  onUnmount?: () => void
}

const DataGridLoadingComponent = (props: LoaderProps) => {
  const { hideLoader, onUnmount, api } = props
  const classes = useDatGridLoadingComponentStyles()

  useEffect(() => {
    return () => {
      onUnmount?.()
    }
  }, [])
  const colDefs = api?.getColumnDefs()
  const isPolicyOrderInProgress = useSelector((state: RootState) => state.ui.isPolicyOrderInProgress)
  if (hideLoader) return null
  return isPolicyOrderInProgress ? null : (
    <div className={classes.root} data-testid={"data-grid-row-loader"}>
      {colDefs?.map((item: any, index: number) => (
        <ENSkeleton key={`skeleton-${index}`} width={Number(item?.width) - 20 || 50} height={24} />
      ))}
    </div>
  )
}

export default DataGridLoadingComponent
