import { createUseStyles } from "react-jss"

export const useAboutUztnaModalStyles = createUseStyles((theme) => ({
  content: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    alignSelf: "stretch",
    height: 24,
    marginBottom: theme.spacing(2),
  },
  boldContent: {
    color: theme.color.content.default,
    fontSize: 14,
    fontWeight: "600",
    wordWrap: "break-word",
  },
  text: {
    color: theme.color.content.secondary,
    fontSize: 14,
    fontWeight: "400",
    wordWrap: "break-word",
  },
}))
