// For New EnModal Styles
import { createUseStyles } from "react-jss"

// New EnModal Styles
export const useModalStyles = createUseStyles((theme) => ({
  modalContainer: {
    zIndex: theme.zIndex.modal,
  },
  contentWrapper: {
    position: "relative",
    flexDirection: "column",
    display: "flex",
    flex: 1,
    overflow: "auto",
    maxHeight: "70vh",
    "@media (max-height: 620px)": {
      maxHeight: "60vh",
    },
    "scrollbar-width": "thin",
    "scrollbar-color": `${theme.color.content.tertiary} transparent`,
    "::-webkit-scrollbar": {
      width: 8,
    },
    "::-webkit-scrollbar-thumb": {
      width: 4,
      backgroundColor: theme.color.content.tertiary,
      borderRadius: 4,
    },
    "::-webkit-scrollbar-track": {
      width: 12,
      border: "0px 0px 0px 0.25px",
    },
    paddingRight: 10,
  },
  "@supports (-moz-appearance: none)": {
    contentWrapper: {
      paddingRight: 10, // Extra padding only in Firefox
    },
  },
  maxHeightContent: {
    overflow: "hidden",
  },
  hideFooter: {
    display: "none",
  },
}))
