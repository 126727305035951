import { createUseStyles } from "react-jss"

type Props = {
  top?: number
  left?: number
}

export const useDataGridPopOverStyles = createUseStyles<string, Props>((theme) => ({
  root: {
    position: "relative",
    zIndex: theme.zIndex.popup,
  },
  actionRoot: {
    position: "relative",
    zIndex: theme.zIndex.popup,
    width: "100%",
    paddingTop: 5,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1000,
    position: "relative",
    justifyContent: "center",
    minWidth: 150,
    padding: 0,
  },
  listContainerExpanded: {
    display: "flex",
    zIndex: 1000,
    position: "fixed",
    justifyContent: "center",
    minWidth: 150,
    top: ({ top }) => top || "60%",
    left: ({ left }) => left || "80%",
  },
  paperContainer: {
    padding: "12px 0px !important",
    backgroundColor: `${theme.color.background.surfaceElevation1} !important`,
  },
  actionButton: {
    display: "flex",
    marginTop: 10,
  },
  popOverMenuItem: {
    minWidth: 150,
    textAlign: "center",
  },
  timefilterWrapper: {
    border: `1px solid ${theme.color.background.surfaceElevation3}`,
    alignItems: "center",
  },
  removeButton: {
    color: theme.color.error.light,
  },
}))
