import { useFeatures } from "flagged"
import { Fragment, Suspense } from "react"
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import ZtnaLaunchPage from "src/components/ZtnaLaunchPage"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import { FeatureFlagsType } from "src/store/ui/types"
import {
  AuthRoutesMap,
  HomeRoutes,
  PublicRoutesMap,
  RoleValueType,
  ROOT_PATH,
  RouteConfig,
  RouteType,
  ZTNA_ADMIN_BASE_ROUTE,
  ZTNA_APP_BASE_ROUTE,
} from "./config"
import RouteElementWrapper from "./RouteElementWrapper"
import useRoutesStyles from "./Routes.styles"

/**
 * Constructs a map of routes based on the provided configuration.
 *
 * @param {RouteType[] | undefined} configChilds - An array of route configurations or undefined.
 * @returns {JSX.Element | JSX.Element[]} A JSX element or an array of JSX elements representing the routes.
 *
 * The function recursively processes the route configuration to generate nested routes.
 * If a route has child routes, it will recursively call itself to construct the child routes.
 * If a route has a path, it will create a <Route> element with the specified path and element.
 * If a route does not have a path but has child routes, it will wrap the child routes in a <Fragment>.
 * If a route does not have child routes, it will create a <Route> element with the specified path and element.
 */
const constructRouteMap = (configChilds: RouteType[] | undefined): JSX.Element | JSX.Element[] => {
  if (!configChilds || configChilds.length === 0) return <></>

  return configChilds.map((routeItem: RouteType) => {
    const { id, childs, path, isRelativeRoute } = routeItem
    if (childs && childs.length > 0) {
      if (path) {
        return (
          <Route key={`ZtnaRoute-${id}`} path={path.pathId} element={<RouteElementWrapper {...routeItem} />}>
            {constructRouteMap(childs)}
          </Route>
        )
      }

      return <Fragment key={`ZtnaRoute-${id}`}>{constructRouteMap(childs)}</Fragment>
    }

    return (
      <Route
        key={`ZtnaRoute-${id}`}
        path={isRelativeRoute ? `${path?.pathId}/*` : path?.pathId}
        element={<RouteElementWrapper {...routeItem} />}
      />
    )
  })
}

const ZtnaRoutes: React.FC = () => {
  const classes = useRoutesStyles()
  const flags: FeatureFlagsType = useFeatures()

  const { role = null } = navigator.cookieEnabled
    ? JSON.parse(localStorage.getItem("userData") || JSON.stringify({}))
    : {}

  if (role && flags.isFeatureFlagsLoading) {
    return <ZtnaLaunchPage />
  }

  return (
    <Router>
      <Suspense
        fallback={
          <div className={classes.loader}>
            <CircularLoader size="xl" />
          </div>
        }
      >
        <Routes>
          {constructRouteMap(RouteConfig(flags, role))}

          <Route
            path={ROOT_PATH}
            element={
              <Navigate to={role ? HomeRoutes[role as RoleValueType] : AuthRoutesMap.LOGIN.absolutePath} replace />
            }
          />
          <Route path={ZTNA_APP_BASE_ROUTE} element={<Navigate to={HomeRoutes[role as RoleValueType]} replace />} />
          <Route
            path={`${ROOT_PATH}${ZTNA_ADMIN_BASE_ROUTE}`}
            element={
              <Navigate
                to={role ? HomeRoutes[role as RoleValueType] : AuthRoutesMap.ZTNA_ADMIN_LOGIN.absolutePath}
                replace
              />
            }
          />
          <Route path="*" element={<Navigate to={PublicRoutesMap.NOT_FOUND_PAGE.absolutePath} replace />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default ZtnaRoutes
