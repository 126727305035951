import dayjs from "dayjs"
import { CUSTOMER_ADMIN_HOSTS_EXPANDED_VIEW } from "src/constants"
import useFetch from "src/hooks/useFetch"

const mapInstancesState = {
  ENABLED: "UP",
  DISABLED: "DOWN",
  DEPLOYMENT_IN_PROGRESS: "Deploying...",
  ENABLE_IN_PROGRESS: "Activating...",
  DISABLE_IN_PROGRESS: "Deactivating...",
  DELETE_IN_PROGRESS: "Removing...",
  DOWN: "DOWN",
  TERMINATED: "DOWN",
  DELETE_FAILED: "DOWN",
  DEPLOYMENT_FAILED: "DOWN",
  ENABLE_FAILED: "DOWN",
  UPDATE_MTU_IN_PROGRESS: "Updating MTU...",
  UPGRADE_PENDING: "Upgrade Queued",
  UPGRADE_IN_PROGRESS: "Upgrading...",
  UPGRADE_FAILED: "DOWN",
  DISABLE_FAILED: "DOWN",
} as const

const mapInstanceStateTooltip = {
  ENABLED: "Instance is up and running",
  DISABLED: "Instance is in inactive state",
  DEPLOYMENT_IN_PROGRESS: "Deployment of instance underway.",
  ENABLE_IN_PROGRESS: "Instance activation in progress.",
  DISABLE_IN_PROGRESS: "Instance deactivation in progress.",
  DELETE_IN_PROGRESS: "Instance removal in progress.",
  DOWN: "Instance is currently not reachable.",
  TERMINATED: "Instance has been terminated.",
  DELETE_FAILED: "Failed to remove the instance.",
  DEPLOYMENT_FAILED: "Instance deployment failed.",
  ENABLE_FAILED: "Failed to activate the instance",
  UPDATE_MTU_IN_PROGRESS: "Updating Maximum Transfer Unit for instance.",
  UPGRADE_PENDING: "Upgrade for instance is queued.",
  UPGRADE_IN_PROGRESS: "Instance upgrade in progress",
  UPGRADE_FAILED: "Failed to upgrade the instance",
  DISABLE_FAILED: "Failed to deactivate the instance.",
} as const

const mapScaleStatus = {
  AUTO: "Auto scale",
  DISABLED: "Disabled",
  MANUAL: "Manually Triggered",
}

const mapTunnelType = {
  ipsec: "IPSEC",
  wireguard: "WireGuard",
} as const

const mapHAStatus = {
  ENABLED: { badge: "Enabled", tooltip: "High Availability is Enabled" },
  DISABLED: { badge: "Disabled", tooltip: "High Availability is Disabled" },
  ENABLE_HA_MODE_IN_PROGRESS: { badge: "Enabling HA...", tooltip: "Enable Connector HA is in progress" },
  DISABLE_HA_MODE_IN_PROGRESS: { badge: "Disabling HA...", tooltip: "Disable Connector HA is in progress" },
  UPDATE_HA_MODE_FAILED: { badge: "Update HA Failed", tooltip: "Failed to update HA mode" },
} as const

type InstanceResponseType = {
  name: string
  id: string
  connectionStatus: boolean
  connectionLastUpdated: string
  connectionSince?: string
  instanceId?: string | null
  cloudId?: string | null
  hostCreation: string
  state: keyof typeof mapInstancesState
  upgradeMessage: {
    errMsg: string
  }
  lastStatusUpdate: number
  publicIp: string | null
  privateIp: string | null
  hostname: string | null
  sofwareUpgradeFailedIcon?: boolean
  sofwareUpgradeFailedTooltip?: string | null
  runMode: "SYSTEM" | "CONTAINER"
  agentVersion: string
}

type ExpandableViewResponseType = {
  serviceNames: string[]
  dnsServers: string[]
  tunnelType: keyof typeof mapTunnelType
  deployedBy: string
  createdAt: string
  region?: string
  network?: string
  cloudId?: string
  haStatus: keyof typeof mapHAStatus
  scaleStatus?: "AUTO" | "MANUAL" | "DISABLED"
  instances: InstanceResponseType[]
  publicIp: string
  privateIp: string
  agentVersion: string
  name: string
}

export type InstanceType = Omit<InstanceResponseType, "connectionStatus" | "state"> & {
  connectionStatus: "active" | "inActive"
  state: (typeof mapInstancesState)[keyof typeof mapInstancesState]
  stateBeKey: keyof typeof mapInstancesState
  instanceStatusTooltip: (typeof mapInstanceStateTooltip)[keyof typeof mapInstanceStateTooltip]
  activateDeactivateTitle: "Activate" | "Deactivate"
  upgradeFailedMessage: string
  runMode: "SYSTEM" | "CONTAINER"
}

type ExpandableViewType = Omit<ExpandableViewResponseType, "instances" | "scaleStatus" | "tunnelType"> & {
  instances: InstanceType[]
  scaleStatus?: string
  tunnelType: (typeof mapTunnelType)[keyof typeof mapTunnelType]
  haStatusInfo?: (typeof mapHAStatus)[keyof typeof mapHAStatus]
}

type UseConnectorExpandedView = {
  connectorId: string
  latestVersion?: string
  isScView?: boolean
}

/**
 * Parses the given data and transforms it into an ExpandableViewType.
 *
 * This function processes the instances data, mapping and transforming various fields,
 * and returns a new object with the transformed data.
 *
 * @param data - The data to be parsed, of type ExpandableViewResponseType.
 * @param extraParserProps - Additional properties that may be used during parsing.
 * @returns The parsed data as an ExpandableViewType.
 */
const parser = (data: ExpandableViewResponseType, extraParserProps: any): ExpandableViewType => {
  const instancesData: InstanceType[] = data?.instances?.map(
    ({
      connectionLastUpdated,
      connectionStatus,
      connectionSince,
      state,
      hostname,
      agentVersion,
      hostCreation,
      name,
      publicIp,
      privateIp,
      cloudId,
      ...instance
    }) => ({
      ...instance,
      name: name || "N/A",
      publicIp: publicIp || "N/A",
      privateIp: privateIp || "N/A",
      connectionLastUpdated: connectionLastUpdated ? dayjs(connectionLastUpdated).fromNow() : "N/A",
      connectionStatus: connectionStatus ? "active" : "inActive",
      connectionStatusInfoText:
        connectionStatus && state === "DISABLED" ? "inActive" : connectionStatus ? "active" : "inActive",
      connectionSince: connectionLastUpdated ? dayjs(connectionLastUpdated).fromNow() : "N/A",
      activateDeactivateTitle:
        connectionStatus && (state === "ENABLED" || state === "DISABLE_FAILED")
          ? "Deactivate"
          : connectionStatus && (state === "DISABLED" || state === "ENABLE_FAILED")
          ? "Activate"
          : "Deactivate",
      instanceStatusTooltip: mapInstanceStateTooltip[state],
      state: mapInstancesState[state],
      stateBeKey: state,
      upgradeFailedMessage: instance.upgradeMessage?.errMsg || "",
      lastStatusUpdate: dayjs().diff(dayjs(instance.lastStatusUpdate)),
      upgradeFailedIcon: "enCautionFilled",
      sofwareUpgradeFailedIcon: state === "UPGRADE_FAILED",
      sofwareUpgradeFailedTooltip:
        state === "UPGRADE_FAILED" ? `Failed to upgrade to the latest version ${extraParserProps?.latestVersion}` : "",
      hostname: hostname || "N/A",
      agentVersion: agentVersion || "",
      hostCreation: hostCreation ? `${hostCreation} Instance ID` : "",
    }),
  )

  return {
    ...data,
    ...(data.scaleStatus !== undefined && {
      scaleStatus: data?.scaleStatus && mapScaleStatus[data.scaleStatus],
    }),
    instances: instancesData,
    tunnelType: data?.tunnelType && mapTunnelType[data.tunnelType],
    haStatusInfo: mapHAStatus[data.haStatus],
  }
}

/**
 * Custom hook to fetch and manage the expanded view data for a connector.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.connectorId - The ID of the connector.
 * @param {string} params.latestVersion - The latest version of the connector.
 * @param {boolean} params.isScView - Flag to determine if the view is SC view.
 * @returns {Object} The state and functions related to the expanded view data.
 * @returns {ExpandableViewResponseType | undefined} returns.expandedViewData - The fetched expanded view data.
 * @returns {Error | undefined} returns.expandedViewError - The error object if an error occurred during fetching.
 * @returns {boolean} returns.expandedViewLoading - The loading state of the fetch request.
 * @returns {boolean} returns.expandedViewValidating - The validating state of the fetch request.
 * @returns {Function} returns.getExpandedViewData - Function to manually trigger the data fetch.
 */
export const useConnectorExpandedView = ({ connectorId, latestVersion, isScView }: UseConnectorExpandedView) => {
  const { data, isLoading, mutate, error, isValidating } = useFetch<ExpandableViewResponseType, ExpandableViewType>({
    apiUrl: connectorId ? CUSTOMER_ADMIN_HOSTS_EXPANDED_VIEW(connectorId) : null,
    parser,
    extraParserProps: { latestVersion },
    options: { refreshInterval: isScView ? 10000 : 0 },
  })

  return {
    expandedViewData: data,
    expandedViewError: error,
    expandedViewLoading: isLoading,
    expandedViewValidating: isValidating,
    getExpandedViewData: mutate,
  }
}
