import { ENHeading } from "en-react/dist/src/components/Heading"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { ENVIRONMENT_DETAILS, TENANT_ADMIN_BASE_URL } from "src/constants"
import { isTenantRole } from "src/routes/config/Roles"
import { getRequest } from "src/services"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import { IconNameType } from "src/shared/components/Icons"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { setToasterState } from "src/store/ui/uiSlice"
import { getXiqUrl } from "src/utils"
import { BRAND_NAME } from "src/utils/constants"
import { ERROR_MESSAGES } from "src/utils/craasUserMessages"
import { useAuthorizationMessageStyle } from "./AuthorizationMessage.style"

export type AuthMessageProps = {
  state?: "loading" | "error" | "verifyingLicense" | "invalidLicense" | "licenseVerified" | "roleNotDefined"
  messageDescription?: string
}

type AuthorizationMessageType = {
  image: string
  heading?: string | React.ReactNode
  description: string
  headingIcon?: IconNameType
}

const getEnvironmentDetails = async () => {
  const { role } = JSON.parse(localStorage.getItem("userData") || "{}")

  const url = isTenantRole(role) ? `${TENANT_ADMIN_BASE_URL}${ENVIRONMENT_DETAILS}` : ENVIRONMENT_DETAILS
  const resp = await getRequest(url)
  return resp?.data?.payload
}

const AuthorizationMessage: React.FC<AuthMessageProps> = ({ state, messageDescription }) => {
  const classes = useAuthorizationMessageStyle()

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getEnvironmentDetails()
      .then((resp: any) => {
        localStorage.setItem("xiqUrl", resp?.xiqUrl)
        localStorage.setItem("xcdUrl", resp?.xcdUrl)
        localStorage.setItem("xiqLogoutUrl", resp?.xiqLogoutUrl)
        setIsLoading(false)
      })
      .catch((error: any) => {
        dispatch(
          setToasterState({
            message: error?.response?.data?.errorMessage || ERROR_MESSAGES.UNABLE_TO_FETCH,
            type: "danger",
            autoHide: false,
          }),
        )
      })
  }, [])

  const messageInfo = useMemo<AuthorizationMessageType | undefined>(() => {
    if (state === "loading")
      return {
        image: "loading.gif",
        heading: " ",
        description: `Hang on! We are taking you to the ${BRAND_NAME}. It may take a few seconds.`,
      }
    else if (state === "error")
      return {
        image: `ExtremeCloud-IQ.png`,
        heading: "Invalid License",
        description: "Your license for ExtremeCloud Universal ZTNA is invalid.",
        headingIcon: "invalidCircleIcon",
      }
    else if (state === "verifyingLicense")
      return {
        image: "loading.gif",
        heading: " ",
        description: "Verifying your Universal ZTNA license...",
      }
    else if (state === "licenseVerified")
      return {
        image: "loading.gif",
        heading: "License Verified",
        description: "Creating Universal ZTNA instance...",
        headingIcon: "doneIcon",
      }
    else if (state === "invalidLicense")
      return {
        image: "sessionExpire.svg",
        heading: "License Invalid",
        description: "Your license for UZTNA is invalid. You may go back to ExtremeCloud XIQ home page.",
      }
    else if (state === "roleNotDefined") {
      return {
        image: "ExtremeCloud-IQ.png",
        heading: "Role is not assigned",
        description:
          "Your user role is not authorized to view this application. Please contact Administrator to assign appropriate role.",
      }
    }
  }, [state])

  return state ? (
    <>
      {state === "error" || state === "invalidLicense" ? (
        <img src={`${process.env.PUBLIC_URL}/${messageInfo?.image}`} className={classes.groupImage} />
      ) : (
        state !== "roleNotDefined" && <CircularLoader size="xl" />
      )}

      {state === "roleNotDefined" && <ZtnaIcon name="enSwitchUser" size="xl" />}
      {messageInfo?.heading && (
        <div className={classes.heading}>
          {messageInfo?.headingIcon && <ZtnaIcon name={messageInfo?.headingIcon} />}
          <ENHeading variant="md">{messageInfo?.heading}</ENHeading>
        </div>
      )}

      {state !== "roleNotDefined" && (
        <div className={classes.description}>
          <ENTextPassage>
            <p>{messageInfo?.description}</p>
          </ENTextPassage>
        </div>
      )}

      {state === "roleNotDefined" && (
        <div className={classes.roleDescription}>
          <ENTextPassage>
            <p>{messageInfo?.description}</p>
          </ENTextPassage>
        </div>
      )}
      {(state === "error" || state === "invalidLicense") && (
        <div className={classes.buttonContainer}>
          <ZtnaButton
            buttonType="primary"
            title={"Back to ExtremeCloud XIQ"}
            onClick={() => (window.location.href = getXiqUrl())}
            disabled={isLoading}
          />
        </div>
      )}

      {state === "roleNotDefined" && (
        <div className={classes.buttonContainer}>
          <ZtnaButton
            buttonType="secondary"
            title={"Back to Home"}
            onClick={() => (window.location.href = getXiqUrl())}
            disabled={isLoading}
          />
        </div>
      )}
    </>
  ) : null
}

export default AuthorizationMessage
