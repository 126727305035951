import { UseSearchUsersResponseType } from "src/pages/UserGroups/AddEditUserGroup/AddEditUserGroup.type"

export const parseUsersDropdown = (data: {
  payload?: { users: UseSearchUsersResponseType[]; from: number; to: number; total: number }
}) =>
  data?.payload?.users?.map(({ id, email }) => ({
    label: email,
    value: id.toString(),
    id: id.toString(),
    name: email,
  })) || []

export const parseSelectedUserOption = (data: {
  payload?: { users: UseSearchUsersResponseType[]; from: number; to: number; total: number }
}) => ({
  selectedOptions:
    data?.payload?.users?.map(({ id, email }) => ({
      label: email,
      value: id.toString(),
      id: id.toString(),
      name: email,
    })) || [],
})
