import { ACCOUNTS_XCD_USER_INFO } from "src/constants"
import useFetch from "src/hooks/useFetch"

/**
 * Custom hook to refresh the browser cookie by fetching user information.
 *
 * @param {Object} params - The parameters object.
 * @param {boolean} params.shouldFetch - Flag to determine whether to fetch user information.
 *
 * @returns {void}
 *
 * @example
 * useRefreshBrowserCookie({ shouldFetch: true });
 */
const useRefreshBrowserCookie = ({ shouldFetch }: { shouldFetch: boolean }) => {
  useFetch({
    apiUrl: shouldFetch && process.env.NODE_ENV !== "development" ? ACCOUNTS_XCD_USER_INFO : null,
    options: { refreshInterval: 30000 },
  })
}

export default useRefreshBrowserCookie
