export const CUSTOMER_ADMIN_UZTNA_GATEWAYS = "/resource/api/v1/resources/relay-nodes/"
export const CUSTOMER_ADMIN_UZTNA_GATEWAYS_EXPANDED_VIEW = (id: string) =>
  `/resource/api/v1/resources/relay-nodes/${id}/expanded-view/`
export const REMOVE_CLOUD_UZTNA_GATEWAY = (relayNodeId: string): string =>
  `/resource/api/v1/resources/relay-nodes/${relayNodeId}/`
export const FORCE_REMOVE_UZTNA_GATEWAY = (id: string): string =>
  `/resource/api/v1/resources/relay-nodes/hard-delete/${id}/`

export const RELAY_NODE_NOTIFICATIONS = "/resource/api/v1/release/notifications/relay/"
export const RADSEC_PROXY_NOTIFICATIONS = "/admin/resource/api/v1/release/notifications/radsec-proxy/" // proxy version

export const UPGRADE_RELAY_NODES = "/resource/api/v1/resources/relay-nodes/upgrade/"

export const RETRY_UZTNA_GATEWAY_UPGRADE = "/resource/api/v1/resources/relay-nodes/upgrade/"
export const VERSIONS_CHECK = "/resource/api/v1/release/versions/check/"
export const VERSIONS_ROLLOUT = "/resource/api/v1/release/versions/rollout/"
export const GET_UZTNA_GATEWAYS_INSTANCE_TYPES = "/resource/api/v1/resources/relay-nodes/instance-types"
export const SUPER_ADMIN_WORKSPACES_LIST_ANALYTICS = "/analytics/api/v1/workspaces/list"
export const GET_REGIONS = "/resource/api/v1/resources/relay-nodes/regions/"

export const GET_SUPER_ADMIN_IDENTITY_PROVIDERS = "/auth/api/v1/accounts/ztna-admin/identity-providers/"

export const UPDATE_SUPER_ADMIN_IDENTITY_PROVIDERS = `/auth/api/v1/accounts/ztna-admin/identity-providers/`
