import { VERSIONS_ROLLOUT } from "src/constants"
import { postRequest } from "src/services"
import { VersionsCheckResponseType } from "./useRolloutUpgrade"

/**
 * Initiates the rollout upgrade process by sending a POST request with the specified versions.
 *
 * @param {VersionsCheckResponseType} versions - An object containing the versions to be upgraded.
 * @param {string} versions.connectorVersion - The version of the connector to be upgraded.
 * @param {string} versions.radsecProxyVersion - The version of the RADSEC proxy to be upgraded.
 * @param {string} versions.relayVersion - The version of the relay to be upgraded.
 * @returns {Promise<void>} A promise that resolves when the POST request is complete.
 */
export const rolloutUpgrade = async ({
  connectorVersion,
  radsecProxyVersion,
  relayVersion,
}: VersionsCheckResponseType) => {
  const body = {
    connector_version: connectorVersion,
    relay_version: relayVersion,
    radsec_proxy_version: radsecProxyVersion,
  }
  await postRequest(VERSIONS_ROLLOUT, body)
}
