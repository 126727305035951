import { VERSIONS_CHECK } from "src/constants"
import useFetch from "src/hooks/useFetch"
import { Roles } from "src/routes/config"
import ztnaAxios, { REQUEST_HEADERS } from "src/services"
import { getCookie } from "src/utils"

export interface VersionsCheckResponseType {
  connectorVersion?: string | null
  relayVersion?: string | null
  radsecProxyVersion?: string | null
}

/**
 * Fetches data from the given URL using a POST request with the specified headers.
 *
 * @param {string} url - The URL to send the POST request to.
 * @param {Object.<string, string>} headers - An object containing request headers.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 *
 * @example
 * const data = await fetcher('/api/endpoint', { 'Authorization': 'Bearer token' });
 * console.log(data);
 */
const fetcher = (url: string, headers: { [key: string]: string }): any => {
  const token = getCookie("csrftoken")
  const updatedHeaders: { [key: string]: any } = { ...REQUEST_HEADERS, ...headers }
  if (token) updatedHeaders["X-CSRFToken"] = token
  return ztnaAxios
    .post(url, null, {
      headers: updatedHeaders,
    })
    .then((res) => res.data)
}

/**
 * Custom hook to fetch and manage rollout upgrade data.
 *
 * This hook uses the `useSelector` hook to get the user's role from the Redux store.
 * Depending on the user's role, it fetches the rollout upgrade data using the `useFetch` hook.
 * The data is refreshed every 15 seconds.
 *
 * @returns {Object} An object containing:
 * - `rolloutUpgradeData`: The fetched rollout upgrade data.
 * - `getRolloutUpgrade`: A function to manually trigger the data fetch.
 */
const useRolloutUpgrade = () => {
  const { role } = JSON.parse(localStorage.getItem("userData") || "{}")

  const { data: rolloutUpgradeData, mutate: getRolloutUpgrade } = useFetch<VersionsCheckResponseType>({
    apiUrl: role === Roles.SUPER_ADMIN ? VERSIONS_CHECK : null,
    options: {
      fetcher,
      refreshInterval: 15000,
    },
  })

  return { rolloutUpgradeData, getRolloutUpgrade }
}

export default useRolloutUpgrade
