import clsx from "clsx"
import React, { useCallback } from "react"
import { useDataGridStyles } from "../../DataGrid.styles"

interface DetailCellRendererProps {
  params: any
  expandableRowComponent?: (params: any) => JSX.Element
  expandedRow?: string | null
  setExpandedRow?: (id: string | null) => void
}

const DetailCellRenderer: React.FC<DetailCellRendererProps> = ({
  params,
  expandableRowComponent,
  expandedRow,
  setExpandedRow,
}) => {
  const classes = useDataGridStyles()

  const handleExpand = useCallback(() => {
    const currentRowId = params.data.id

    if (expandedRow && expandedRow !== currentRowId) {
      params.api.forEachNode((node: any) => {
        if (node?.data?.id === expandedRow) {
          node.setExpanded(false)
        }
      })
    }
    setExpandedRow?.(currentRowId)
  }, [params, expandedRow, setExpandedRow])

  handleExpand()

  return (
    <div
      className={clsx({
        [classes.expandableComponentWrapperZeroPaddingBorder]: params.data.endSystems,
        [classes.expandableComponentWrapper]: !params.data.endSystems,
      })}
    >
      {expandableRowComponent?.(params)}
    </div>
  )
}

export default DetailCellRenderer
