import { useCallback, useState } from "react"
import { getRequest, postRequest, REQUEST_HEADERS } from "src/services"
import { DROPDOWN_PAGE_SIZE } from "src/utils/constants"
import { DropDownType, OptionType } from "src/utils/utils.types"

export type ENPaginatedDropdownOptionType = OptionType & DropDownType

type ENFetchByIdType = () => Promise<{ selectedOptions: ENPaginatedDropdownOptionType[] }>

type ENPaginatedApiCallHookOptions<PayloadType = any, FetchByIdPayloadType = PayloadType> = {
  apiUrl: string
  fetchByIdUrl?: string
  pageSize?: number
  paginationType?: "page" | "offset"
  useBaseUrl?: boolean
  parser: (data: PayloadType) => any[]
  fetchByIdParser?: (data: FetchByIdPayloadType) => { selectedOptions: ENPaginatedDropdownOptionType[] }
  pageParam?: string
  limitParam?: string
  requestType?: "get" | "post"
  body?: any
  fetchByIdBody?: any
  searchParam?: string
  firstPageIndex?: number
}

type ENPaginatedApiCallHookResponseType = <PayloadType = any, FetchByIdPayloadType = PayloadType>(
  options: ENPaginatedApiCallHookOptions<PayloadType, FetchByIdPayloadType>,
) => {
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  loadOptions: (page: number, search: string) => Promise<Array<any>>
  loadOptionsById?: ENFetchByIdType
}

const fetchExternalApi = async (method: "post" | "get", url: string, body: any = {}) => {
  const response = await fetch(url, {
    headers: REQUEST_HEADERS,
    method,
    ...(method === "post" ? { body: JSON.stringify(body) } : {}),
    credentials: "include",
  })
  return await response.json()
}

const useENDropdownPaginatedApiCall: ENPaginatedApiCallHookResponseType = ({
  apiUrl,
  fetchByIdUrl = "",
  pageSize = DROPDOWN_PAGE_SIZE,
  paginationType = "offset",
  useBaseUrl = true,
  parser,
  fetchByIdParser,
  pageParam = "page",
  limitParam = "limit",
  requestType = "get",
  body,
  fetchByIdBody,
  searchParam = "search",
  firstPageIndex = 0,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  // Load Data from an api
  const loadOptions = useCallback(
    async (currentPage: number, search: string) => {
      const page = firstPageIndex + currentPage - 1
      try {
        const url = `${apiUrl}${apiUrl.includes("?") ? "&" : "?"}${
          paginationType === "page" ? `${pageParam}=${page}` : `offset=${page * pageSize}`
        }&${limitParam}=${pageSize}${searchParam && search ? `&${searchParam}=${search}` : ""}`

        const response =
          requestType === "get"
            ? useBaseUrl
              ? await getRequest(url, useBaseUrl)
              : await fetchExternalApi("get", url)
            : useBaseUrl
            ? await postRequest(url, body, {}, useBaseUrl, true)
            : await fetchExternalApi("post", url, body)

        setIsSuccess(true)

        return parser(useBaseUrl ? response.data : response)
      } catch (error) {
        console.log("error", error)
        setIsError(true)
        return []
      } finally {
        setIsLoading(false)
      }
    },
    [apiUrl, paginationType, pageParam, firstPageIndex, pageSize, limitParam, searchParam, useBaseUrl, body],
  )

  const loadOptionsById: ENFetchByIdType = async () => {
    try {
      const response =
        requestType === "get"
          ? useBaseUrl
            ? await getRequest(fetchByIdUrl)
            : await fetchExternalApi("get", fetchByIdUrl)
          : useBaseUrl
          ? await postRequest(fetchByIdUrl, fetchByIdBody, {}, useBaseUrl, true)
          : await fetchExternalApi("post", fetchByIdUrl, fetchByIdBody)
      const parsedData = fetchByIdParser?.(useBaseUrl ? response.data : response)
      return { selectedOptions: parsedData ? parsedData.selectedOptions : [] }
    } catch (error) {
      console.log("error", error)
      return { selectedOptions: [] }
    }
  }
  return { loadOptions, loadOptionsById, isLoading, isSuccess, isError }
}

export default useENDropdownPaginatedApiCall
