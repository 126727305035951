import { createUseStyles } from "react-jss"

export const useUztnaGatewaysUpgradeDetailsModalStyles = createUseStyles(() => ({
  content: { height: 530 },
  table: {
    "& tr:nth-child(odd)": { height: 50 },
    "& h6, td": { fontSize: 16 },
  },
  tableHeight: {
    minHeight: 400,
    maxHeight: 400,
  },
  upgradeFailedWrapper: {
    justifyContent: "flex-end",
  },
}))
