import { TemplateResult, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, unsafeStatic } from 'lit/static-html.js';
import { nanoid } from 'nanoid';
import register from '../../directives/register';
import PackageJson from '../../package.json';
import { ENElement } from '../ENElement';
import { ENIconInfo } from '../icon/icons/info';
import { ENTooltip } from '../tooltip/tooltip';
import styles from './switch.scss';

/**
 * Component: en-switch
 * - Switches toggle the state of a single setting on or off.
 */
export class ENSwitch extends ENElement {
  static el = 'en-switch';

  private elementMap = register({
    elements: [
      [ENIconInfo.el, ENIconInfo],
      [ENTooltip.el, ENTooltip]
    ],
    suffix: (globalThis as any).enAutoRegistry === true ? '' : PackageJson.version
  });

  private iconInfoEl = unsafeStatic(this.elementMap.get(ENIconInfo.el));
  private tooltipEl = unsafeStatic(this.elementMap.get(ENTooltip.el));

  static get styles() {
    return unsafeCSS(styles.toString());
  }

  /**
   * Checked attribute
   * - If true, sets the treatment to represent an on state
   * - If false, sets the treatment to represent an off state
   */
  @property({ type: Boolean })
  isChecked?: boolean;

  /**
   * Disabled attribute
   * - Changes the component's treatment to represent a disabled state
   */
  @property({ type: Boolean })
  isDisabled?: boolean;

  /**
   * Readonly attribute
   * - Specifies that an input field is read-only. This is different from the
   * disabled state, where the element is entirely removed from focus and interaction.
   */
  @property({ type: Boolean })
  isReadonly?: boolean;

  /**
   * Field ID
   * - Links the label to the switch
   * - By default it is autogenerated by nanoid
   */
  @property()
  fieldId?: string;

  /**
   * Label
   * - The text inside the label tag
   * - Does not display on the front-end, but is used for A11y
   */
  @property()
  label: string = 'Switch';

  /**
   * Name attribute
   * - The name attribute used on the switch
   */
  @property()
  name?: string;

  /**
   * Style variant
   * - **primary** renders the switch to be used on backgrounds with var(--en-theme-color-background-surface-elevation-1) (Dialogs Tables Panels etc)
   * - **secondary** renders the switch to be used on backgrounds with var(--en-theme-color-background-surface-elevation-0) (The main body background)
   */
  @property()
  variant?: 'primary' | 'secondary';

  /**
   * Label is not shown by default. But if this property will be set then label will be shown along with switch
   */
  @property({ type: Boolean })
  showLabel?: boolean = false;

  /**
   * Helper text will be shown only if label will be enabled(via `showLabel` or `showLabelInRight`)
   */
  @property()
  helperText?: string = '';

  /**
   * If label is shown then it is shown in left. But if this property will be set then label will be shown in right to switch
   */
  @property({ type: Boolean })
  showLabelInRight?: boolean = false;

  /**
   * If tooltipInfo will be set then info icon will be shown with label. But if your are providing tooltipInfo then please also provide label
   * and set either of property showLabel or showLabelInRight
   */
  @property()
  tooltipInfo?: string;

  /**
   * Icon size
   * - **sm** size is 16px
   * - **md** renders a larger size than sm (20px)
   * - **lg** renders a larger size than the md variant (24px)
   * - **xl** renders a larger size than the lg variant (32px)
   */
  @property()
  infoIconSize?: 'sm' | 'md' | 'lg' | 'xl' = 'md';

  /**
   * Connected callback lifecycle
   * 1. Autogenerates Field ID for A11y if this property isn't provided
   */
  connectedCallback() {
    super.connectedCallback();
    this.fieldId = this.fieldId || nanoid(); /* 1 */
  }

  /**
   * Trigger switch event
   *  1. Toggle the component's checked state
   *  2. Dispatch the custom event
   */
  triggerSwitchEvent() {
    if (this.isDisabled || this.isReadonly) return false;
    /* 1 */
    this.isChecked = !this.isChecked;

    /* 2 */
    this.dispatch({
      eventName: 'changed',
      detailObj: {
        checked: this.isChecked
      }
    });
  }

  /**
   * Handle on keydown events
   * 1. If the Enter key is pressed, trigger the switch event
   */
  handleKeydown(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      this.triggerSwitchEvent();
    }
  }

  render() {
    const componentClassNames = this.componentClassNames('en-c-switch', {
      'en-is-checked': this.isChecked === true,
      'en-is-disabled': this.isDisabled === true
    });
    if (this.variant === undefined) {
      this.variant = 'primary';
    }
    const labelClassNames = this.componentClassNames('en-c-switch', {
      'en-c-switch__label': this.variant === 'primary',
      'en-c-switch__label--secondary': this.variant === 'secondary'
    });

    return html`
      <div class="${componentClassNames}">
        <div class="${classMap({ 'en-show-label': this.showLabel, 'en-show-label-right': this.showLabelInRight, 'en-c-switch-container': true })}">
          ${this.showLabel || this.showLabelInRight
            ? html`<div class="en-label-container" tabindex="-1">
                <label for="${this.fieldId}">${this.label}</label>
                ${!!this.tooltipInfo
                  ? html`<${this.tooltipEl}>
            <${this.iconInfoEl} size="${this.infoIconSize}" slot="trigger"></${this.iconInfoEl}>
          ${this.tooltipInfo}
          </${this.tooltipEl}>`
                  : html``}
              </div>`
            : html``}
          <input
            class="en-c-switch__checkbox"
            type="checkbox"
            id="${this.fieldId}"
            name="${ifDefined(this.name)}"
            ?checked=${this.isChecked}
            ?disabled=${this.isDisabled}
            @change=${this.triggerSwitchEvent}
            @keydown=${this.handleKeydown}
            tabindex="${this.showLabel || this.showLabelInRight ? -1 : 0}"
          />
          <label
            class="${labelClassNames}"
            @keydown=${this.showLabel || this.showLabelInRight ? this.handleKeydown : () => {}}
            tabindex="${(this.showLabel || this.showLabelInRight) && !this.isDisabled ? 0 : -1}"
            for=${this.fieldId}
          >
            <span class="en-u-is-vishidden">${this.label}</span>
          </label>
        </div>
        ${!!this.helperText ? html`<div class="en-label--helper-text">${this.helperText}</div>` : html``}
      </div>
    ` as TemplateResult<1>;
  }
}

if ((globalThis as any).enAutoRegistry === true && customElements.get(ENSwitch.el) === undefined) {
  customElements.define(ENSwitch.el, ENSwitch);
}

declare global {
  interface HTMLElementTagNameMap {
    'en-switch': ENSwitch;
  }
}
