import {
  RETRY_SERVICE_CONNECTORS_UPGRADE,
  UPGRADE_RADSEC_PROXY,
  UPGRADE_RELAY_NODES,
  UPGRADE_SERVICE_CONNECTORS,
} from "src/constants"
import { postRequest } from "src/services"
import { patchRequest } from "src/services/axios"

export const upgradeAllConnectors = async (): Promise<void> => {
  await postRequest(UPGRADE_SERVICE_CONNECTORS)
}

export const upgradeAllRelayNodes = async (): Promise<void> => {
  await postRequest(UPGRADE_RELAY_NODES)
}

export const retryUpgradeAllConnectors = async (): Promise<void> => {
  await postRequest(RETRY_SERVICE_CONNECTORS_UPGRADE)
}

export const upgradeRadsecProxyVersion = async (version: string): Promise<void> => {
  await patchRequest({ url: UPGRADE_RADSEC_PROXY, data: { version } })
}
